import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from '../Common/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: pre-line;
`;

export const MyPageContainer = styled.div`
  width: 100%;
  padding: ${vw(70)} ${vw(10)} ${vw(10)} ${vw(10)};
  background-color: ${Colors.dark};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const MyPageInnerBox = styled.div`
  width: 100%;
  padding: ${vw(20)} ${vw(10)};
  border-radius: ${vw(8)};
  box-shadow: 0 ${vw(8)} ${vw(16)} 0 rgba(0, 0, 0, 0.35);
  background-color: ${Colors.gray};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const WithdrawTabButton = styled.button`
  width: ${vw(160)};
  height: ${vw(40)};
  border-radius: ${vw(8)};
  border: none;
  background-color: transparent;
  font-family: NotoSansKR;
  font-size: ${vw(12)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  opacity: 0.4;

  :disabled {
    opacity: 1;
    background-color: ${Colors.dark};
  }
`;

export const StyledWithdrawInfo = styled.div`
  width: ${vw(320)};
  padding: ${vw(10)} ${vw(10)} ${vw(10)} ${vw(16)};
  border-radius: ${vw(8)};
  box-shadow: 0 ${vw(8)} ${vw(16)} 0 rgba(0, 0, 0, 0.35);
  border: solid ${vw(1)} #1c1b1a;
  background-color: #242322;
`;

export const MyWalletInnerBox = styled.div`
  width: 100%;
  padding: ${vw(10)};
  border-radius: ${vw(8)};
  background-color: ${Colors.dark};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const NoMembership = styled.div`
  width: ${vw(200)};
  height: ${vw(100)};
  padding: ${vw(20)};
  border-radius: 12px;
  border: solid ${vw(3)} rgba(97, 97, 97, 0.25);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BusinessIncentivePoints = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-row-gap: ${vw(10)};
`;

export const BusinessApproveMembershipBox = styled.div`
  width: 100%;
  padding: ${vw(10)};
  border-radius: ${vw(8)};
  background-color: ${Colors.dark};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const IncentiveBox = styled.div`
  width: 100%;
  padding: ${vw(10)};
  border-radius: ${vw(8)};
  box-shadow: 0 ${vw(8)} ${vw(16)} 0 rgba(0, 0, 0, 0.35);
  border: solid ${vw(1)} ${Colors.dark};
  background-color: ${Colors.gray};
`;
