import styled from 'styled-components';
import { vw } from '../../Utils/Size';

export const Container = styled.div`
  width: 100%;
  height: ${vw(760)};
  padding: ${vw(40)} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  background-color: #1c1b1a;
`;

export const Logo = styled.img`
  width: ${vw(60)};
  height: ${vw(60)};
  object-fit: contain;
`;

export const InnerBox = styled.div`
  width: ${vw(340)};
  height: ${vw(160)};
  padding: ${vw(20)} ${vw(10)} ${vw(10)} ${vw(10)};
  border-radius: ${vw(8)};
  box-shadow: 0 ${vw(4)} ${vw(8)} 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
`;

export const InnerBoxIcon = styled.img<{ width: number; height: number }>`
  width: ${(props) => vw(props.width)};
  height: ${(props) => vw(props.height)};
  object-fit: contain;
  background-color: transparent;
`;

export const SigninContainer = styled.div`
  width: 100%;
  padding: ${vw(20)} ${vw(10)};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
`;

export const TextButton = styled.button`
  font-family: NotoSansKR;
  font-size: ${vw(12)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #e0be7a;
  border: none;
  background: none;
  padding: 0;
  text-decoration: underline;
`;
