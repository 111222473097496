import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';
import * as Styled from './FindAccount.style';

import { OTF_B_10, OTF_B_12, OTF_B_16, OTF_B_24, OTF_R_12, StyledText } from '../Common/Typography';

import {
  ButtonGoldFilled,
  ButtonGoldOutlined,
  FlexColumn,
  FlexRow,
  Input,
  SizedBoxH,
  SizedBoxW,
  Span,
  TabButton,
} from '../Common/CommonStyle';

// images
import Logo from 'img/Common/small_logo.png';

export default withRouter(function FindAccount(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  const [tab, setTab] = useState<'id' | 'password'>('id');

  const [email, setEmail] = useState<string>('');

  const [id, setId] = useState<string>('');
  const [email2, setEmail2] = useState<string>('');

  return (
    <Styled.Container>
      <Styled.Logo src={Logo} />
      <SizedBoxH height={10} />
      <FlexRow>
        <OTF_B_16>MAX4</OTF_B_16>
        <SizedBoxW width={10} />
        <OTF_R_12>Best Trading Education Program</OTF_R_12>
      </FlexRow>
      <SizedBoxH height={40} />

      <FlexRow>
        <TabButton width={180} height={50} disabled={tab == 'id'} onClick={() => setTab('id')}>
          아이디 찾기
        </TabButton>
        <TabButton width={180} height={50} disabled={tab == 'password'} onClick={() => setTab('password')}>
          비밀번호 찾기
        </TabButton>
      </FlexRow>

      <Styled.InnerBox>
        {tab == 'id' ? (
          <FlexColumn alignItems={'stretch'}>
            <OTF_B_12>이메일</OTF_B_12>
            <SizedBoxH height={10} />
            <Input height={30} value={email} onChange={(e) => setEmail(e.target.value)} />

            <SizedBoxH height={40} />

            <ButtonGoldFilled width={340} height={40}>
              아이디 찾기
            </ButtonGoldFilled>
          </FlexColumn>
        ) : tab == 'password' ? (
          <FlexColumn alignItems={'stretch'}>
            <OTF_B_12>아이디</OTF_B_12>
            <SizedBoxH height={10} />
            <Input height={30} value={id} onChange={(e) => setId(e.target.value)} />

            <SizedBoxH height={20} />

            <OTF_B_12>이메일</OTF_B_12>
            <SizedBoxH height={10} />
            <Input height={30} value={email2} onChange={(e) => setEmail2(e.target.value)} />

            <SizedBoxH height={40} />

            <ButtonGoldFilled width={340} height={40}>
              비밀번호 찾기
            </ButtonGoldFilled>
          </FlexColumn>
        ) : (
          <></>
        )}
      </Styled.InnerBox>

      <Span />

      <ButtonGoldOutlined width={180} height={40}>
        이전 화면으로 돌아가기
      </ButtonGoldOutlined>
    </Styled.Container>
  );
});
