import HeaderLogo from 'img/Main/header_logo.png';
import MenuIcon from 'img/Main/menu.png';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Colors from '../Common/Colors';
import {
  ButtonGoldFilled,
  ButtonGoldOutlined,
  Circle,
  FlexColumn,
  FlexRow,
  Input,
  SizedBox,
  SizedBoxH,
  SizedBoxW,
  SizedImage,
  Span,
  ToggleButton,
} from '../Common/CommonStyle';
import { OTF_B_10, OTF_B_12, OTF_B_16, OTF_R_10, OTF_R_12, StyledText } from '../Common/Typography';
import * as Styled from './ProChallenge.style';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Rectangle, Tooltip, XAxis, YAxis } from 'recharts';
import dayjs from 'dayjs';

// images
import IconBitcoin from 'img/ProChallenge/icon_bitcoin.png';
import PointCIcon from 'img/Game/point_c.png';

import LongDot from 'img/ProChallenge/dot_long.png';
import ShortDot from 'img/ProChallenge/dot_short.png';

import IconLineChart from 'img/ProChallenge/icon_chart_line.png';
import IconCandlestickChart from 'img/ProChallenge/icon_chart_candlestick.png';
import IconBarChart from 'img/ProChallenge/icon_chart_bars.png';

import IconLineChartSelected from 'img/ProChallenge/icon_chart_line_gold.png';
import IconCandlestickChartSelected from 'img/ProChallenge/icon_chart_candlestick_gold.png';
import IconBarChartSelected from 'img/ProChallenge/icon_chart_bars_gold.png';

import IconTimeWatch from 'img/ProChallenge/icon_timewatch.png';
import IconTime from 'img/ProChallenge/icon_time.png';
import IconDollar from 'img/ProChallenge/icon_dollar.png';

import IconFlag from 'img/ProChallenge/icon_flag.png';

import { useTranslation } from 'react-i18next';
import {
  betProChallenge,
  getAccount,
  getBusinessAccount,
  getCentralWallet,
  getProChallengePositions,
  getProChallengeRecentTrades,
  getProChallengeReturnRate,
} from '../../api';

import SideMenu from './SideMenu';
import { vw } from '../../Utils/Size';
import { Periods, Ticks } from './Data';
import OutsideClickHandler from 'react-outside-click-handler';
import { Motion, spring } from 'react-motion';

const StreamHost = 'wss://stream.binance.com:9443';
const ApiHost = 'https://api.binance.com/api/v3';

// Time
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

// data settings
const fps = 1;

// graph settings
// sensitivy (이 값 곱해서 줌 들어갈 것임)
const deltaWheel = 0.01;

const startPeriod = 5 * MINUTE;
const minTick = 1 * SECOND;

// Display Constants
const pixel = window.innerWidth / 360;

const RightBound = 140 * pixel;
const yAxisWidth = 50 * pixel;

const tickWidthMin = 5 * pixel;
const tickWidthMax = 40 * pixel;

interface PriceData {
  x: number;
  open: number;
  close: number;
  low: number;
  high: number;
  price: number;
  end: number;
}

interface AggregateData {
  M: boolean;
  T: number;
  a: number;
  f: number;
  l: number;
  m: boolean;
  p: string;
  q: string;
}

type ChartType = 'line' | 'candlestick';
const ChartIcons: { [key in ChartType]: '*.png' } = {
  line: IconLineChart,
  candlestick: IconCandlestickChart,
};
const SelectedChartIcons: { [key in ChartType]: '*.png' } = {
  line: IconLineChartSelected,
  candlestick: IconCandlestickChartSelected,
};

const orderString: string[] = ['최신 순', '오래된 순', '수익 높은 순', '수익 낮은 순'];

export default withRouter(function ProChallenge(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  // 계정
  const [account, setAccount] = useState<any | null>(null);
  const [pointC, setPointC] = useState(0);

  // 게임 설정
  const [bettingPoint, setBettingPoint] = useState<string | number>(100);
  useEffect(() => {
    let num: string | number = bettingPoint || 0;

    if (!isFinite(Number(num))) return;
    num = num.toString();
    if (num !== '0' && !num.includes('.')) {
      num = num.replace(/^0+/, '');
    }

    setBettingPoint(num);
  }, [bettingPoint]);

  // 마우스 이벤트
  const isDragging = useRef(false);
  const currentPosX = useRef(0);
  // const currentDistanceX = useRef(0);
  const startPosX = useRef(0);

  // 일반
  const [loading, setLoading] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [rawData, setRawData] = useState<PriceData[]>([]);
  const isDataLoding = useRef<boolean>(false);

  // 프레임
  const [frame, setFrame] = useState(0);
  const timestamp = useRef(0);

  // UI 데이터
  const [trades, setTrades] = useState<ProChallengeRecentTradeInfo[]>([]);
  const [currentPositions, setCurrentPositions] = useState<
    {
      createdAt: Date;
      id: number;
      long: boolean;
      point: number;
      priceAtBet: number;
    }[]
  >([]);

  // // about graph
  const [domainX, setDomainX] = useState<[number, number]>([0, 0]);
  const [domainY, setDomainY] = useState<[number, number]>([0, 0]);

  // graph menu
  const [chartType, setChartType] = useState<ChartType>('line');
  const [chartMenu, setChartMenu] = useState<'none' | 'chart' | 'period' | 'tick'>('none');
  const [autoTick, setAutoTick] = useState(false);
  const [tick, setTick] = useState<number>(Ticks[0].tick);
  const [gameMenu, setGameMenu] = useState<'none' | 'positions' | 'time'>('none');

  const [endTime, setEndTime] = useState((Math.floor(Date.now() / MINUTE) + 1) * MINUTE);
  const [returnRate, setReturnRate] = useState(0);

  // SideMenu
  const [menuOn, setMenuOn] = useState(false);
  const [tradesMenuOn, setTradesMenuOn] = useState(false);

  // new
  const [transferMenuOn, setTransferMenuOn] = useState(false);
  const [centralWallet, setCentralWallet] = useState<string | null>(null);

  const [showResult, setShowResult] = useState(false);
  const [resultTrades, setResultTrades] = useState<ProChallengeRecentTradeInfo[]>([]);
  const [resultMenu, setResultMenu] = useState<'overview' | 'positions'>('overview');

  const [bottomMenuHeight, setBottomMenuHeight] = useState(150);
  const { chartWidth, chartHeight } = useMemo(() => {
    return {
      chartWidth: window.innerWidth,
      chartHeight: window.innerHeight - (bottomMenuHeight + 60) * pixel,
    };
  }, [window.innerWidth, window.innerHeight, bottomMenuHeight]);

  const periodLabel = useMemo(() => {
    const diff = [
      ...Periods.map(({ period, ...other }) => {
        const diff = Math.abs(period - (domainX[1] - domainX[0]));
        return { diff, ...other };
      }).sort((a, b) => {
        return a.diff - b.diff;
      }),
    ];
    return diff[0].initial;
  }, [domainX]);

  // 계정 관련
  useEffect(() => {
    (async () => {
      let user;
      if (localStorage.getItem('accountType') == 'normal') user = await getAccount();
      else user = await getBusinessAccount();

      if (!user) {
        alert(t('challenge-login-first'));
        props.history.push('/signin');
        return;
      }

      setAccount(user);
      setPointC(user.pointC);
    })();
    (async () => {
      const response = await getProChallengeRecentTrades();
      if (response) {
        console.log(response);
        setTrades(response);
      }
    })();
    (async () => {
      const response = await getProChallengeReturnRate();
      if (response) {
        setReturnRate(response);
      }
    })();
    (async () => {
      const response = await getCentralWallet();
      if (response) {
        setCentralWallet(response);
      }
    })();
  }, []);

  // Start
  useEffect(() => {
    // 웹 소켓 (실시간으로 가격 받아오기)
    const ws = new WebSocket(`${StreamHost}/stream`);

    ws.onopen = () => {
      console.log('connected!');
      ws.send(
        JSON.stringify({
          method: 'SUBSCRIBE',
          params: ['btcusdt@ticker'],
          id: Date.now(),
        }),
      );
    };

    ws.onmessage = (e) => {
      const json = JSON.parse(e.data);
      if (!json.data) return;
      // 현재 가격

      // 현재 ask 와 bid
      const ask = Number(json.data.a);
      const bid = Number(json.data.b);

      const date = json.data.E;
      // const open = Number(json.data.o);
      // const high = Number(json.data.h);
      // const low = Number(json.data.l);
      const close = Number(json.data.c);

      // add new data
      addData([
        {
          x: date,
          price: close,
          close: close,
          open: close,
          low: close,
          high: close,
          end: date,
        },
      ]);

      setCurrentPrice(close);
    };

    ws.onclose = () => {
      console.log('disconnected!');
    };

    (async () => {
      const now = Date.now();
      const startTime = now - startPeriod;
      const endTime = now;

      const tickMin = (startPeriod * tickWidthMin) / (chartWidth - yAxisWidth);
      const ticks = Ticks.map((e) => e.tick).sort((a, b) => Math.abs(a - tickMin) - Math.abs(b - tickMin));
      const tick = ticks[0];
      setTick(tick);

      const boundTime = (startPeriod * RightBound) / (chartWidth - yAxisWidth);

      setDomainX([startTime + boundTime, endTime + boundTime]);
      getData(startTime - startPeriod, endTime, tick);
    })();
  }, []);

  // update frame by delta time
  useEffect(() => {
    setTimeout(() => {
      setFrame((frame) => frame + 1);
    }, SECOND / fps);

    // frame
    if (loading) return;

    // do something
    if (isDragging.current) return;
    const now = Date.now();
    const deltaTime = now - timestamp.current;

    if (timestamp.current > domainX[0] && timestamp.current < domainX[1]) {
      setDomainX((domain) => {
        domain[0] += deltaTime;
        domain[1] += deltaTime;
        return [...domain];
      });
    }

    if (now > endTime) {
      setEndTime((Math.floor(now / MINUTE) + 1) * MINUTE);
    }

    if (now % (60 * SECOND) < 5 * SECOND) {
      (async () => {
        if (trades.length > 0) {
          const after = trades[0].id;
          const response = await getProChallengeRecentTrades(after);
          if (response && response.length > 0) {
            setShowResult(true);
            setResultTrades(response);
            setTrades((cur) => {
              cur = cur.concat(response);
              cur.sort((a, b) => b.id - a.id);
              return [...cur];
            });

            let user;
            if (localStorage.getItem('accountType') == 'normal') user = await getAccount();
            else user = await getBusinessAccount();

            if (!user) {
              alert(t('challenge-login-first'));
              props.history.push('/signin');
              return;
            }

            setAccount(user);
            setPointC(user.pointC);
          }
        }
      })();
    }

    timestamp.current = now;
  }, [frame]);

  // about endtime
  useEffect(() => {
    (async () => {
      const response = await getProChallengePositions(endTime);
      if (response) {
        setCurrentPositions(response);
      }
    })();
  }, [endTime]);

  // rawData to klineData
  const klineData: PriceData[] = useMemo<PriceData[]>(() => {
    const originData = rawData.map(({ x, ...other }) => {
      const x2 = x - (x % tick);
      return { x, x2, ...other };
    });

    type ArrayElem<A> = A extends readonly (infer T)[] ? T : never;
    type T = ArrayElem<typeof originData>;
    const pivots = new Map<number, T[]>();

    for (const item of originData) {
      const arr = pivots.get(item.x2);
      if (arr) arr.push(item);
      else pivots.set(item.x2, [item]);
    }

    const keys = Array.from(pivots.keys()).sort((a, b) => a - b);

    const data = keys.map((key) => {
      const duplicates = pivots.get(key)!;
      const open = duplicates[0].open;
      const close = duplicates[duplicates.length - 1].close;
      const low = Math.min(...duplicates.map((e) => e.low));
      const high = Math.max(...duplicates.map((e) => e.high));
      return {
        open,
        close,
        low,
        high,
        price: close,
        x: key,
        end: key + tick,
      };
    });

    data.map((e, i) => {
      if (i + 1 < data.length) {
        const next = data[i + 1];
        e.close = next.open;
      }
    });

    return data;
  }, [rawData, tick]);

  const aggDatasToKlineDatas = (aggDatas: AggregateData[]): PriceData[] => {
    console.log(aggDatas);
    const tick = minTick;

    type ArrayElem<A> = A extends readonly (infer T)[] ? T : never;
    type T = ArrayElem<typeof aggDatas>;
    const pivots = new Map<number, T[]>();

    for (const item of aggDatas) {
      const key = Math.floor(item.T / tick) * tick;
      const arr = pivots.get(key);

      if (arr) arr.push(item);
      else pivots.set(key, [item]);
    }

    const keys = Array.from(pivots.keys()).sort((a, b) => a - b);

    const data = keys.map((key) => {
      const duplicates = pivots.get(key)!;
      const open = Number(duplicates[0].p);
      const close = Number(duplicates[duplicates.length - 1].p);
      const low = Math.min(...duplicates.map((e) => Number(e.p)));
      const high = Math.max(...duplicates.map((e) => Number(e.p)));
      return {
        open,
        close,
        low,
        high,
        price: close,
        x: key,
        end: key + tick,
      };
    });

    data.map((e, i) => {
      if (i + 1 < data.length) {
        const next = data[i + 1];
        e.close = next.open;
      }
    });

    return data;
  };

  const onPeriodBtn = (period: number) => {
    const tickMin = (period * tickWidthMin) / (chartWidth - yAxisWidth);
    const ticks = Ticks.map((e) => e.tick).sort((a, b) => Math.abs(a - tickMin) - Math.abs(b - tickMin));
    const tick = ticks[0];
    setTick(tick);

    console.log(ticks);
    console.log(tick);
    setDomainX((domain) => {
      const now = Date.now();
      if (now < domain[1]) {
        const ratio = period / (domain[1] - domain[0]);
        domain[1] = now + (domain[1] - now) * ratio;
        domain[0] = now - (now - domain[0]) * ratio;
      } else {
        domain[0] = domain[1] - period;
      }

      getData(domain[0], domain[1], tick);
      return [...domain];
    });
  };

  const onTickBtn = (tick: number) => {
    // tick setting 하면서 period 체크 하기
    setTick(tick);
    getData(domainX[0], domainX[1], tick);

    const periodMax = (tick / tickWidthMin) * (chartWidth - yAxisWidth);

    const periods = Periods.map((e) => e.period).sort((a, b) => Math.abs(a - periodMax) - Math.abs(b - periodMax));
    const period = periods[0];

    setDomainX((domain) => {
      const now = Date.now();
      if (now < domain[1]) {
        const ratio = period / (domain[1] - domain[0]);
        domain[1] = now + (domain[1] - now) * ratio;
        domain[0] = now - (now - domain[0]) * ratio;
      } else {
        domain[0] = domain[1] - period;
      }

      getData(domain[0], domain[1], tick);
      return [...domain];
    });
  };

  const onBetting = async (long: boolean) => {
    const now = Date.now();

    if (now > endTime - 30 * SECOND) {
      alert('베팅 가능한 시간이 아닙니다!');
      return;
    }

    const response = await betProChallenge(endTime, Number(bettingPoint), long);
    if (response) {
      setCurrentPositions(response);
      setPointC((val) => val - Number(bettingPoint));
    }
  };

  const onEndTimeBtn = (endTime: number) => {
    setEndTime(endTime);
  };

  const addData = (newData: PriceData[]) => {
    setRawData((data) => {
      data = data.concat(newData);

      data.sort((a, b) => a.x - b.x);

      if (data.length > 10000) {
        const period = domainX[1] - domainX[0];
        data = data.filter((d) => d.x > domainX[0] - period && d.x < domainX[1] + period);
      }

      return [...data];
    });
  };

  const getData = async (startTime: number, endTime: number, tick: number) => {
    if (isDataLoding.current) return;

    console.log(`get Data!, startTime: ${startTime}, endTime: ${endTime}`);

    isDataLoding.current = true;

    if (endTime > Date.now()) endTime = Date.now();
    startTime = Math.floor(startTime);
    endTime = Math.floor(endTime);
    // get data from binance api
    if (tick < MINUTE) {
      if (endTime - startTime > HOUR) {
        while (endTime > startTime) {
          // 데이터 받고
          const response = await fetch(
            `${ApiHost}/aggTrades?symbol=BTCUSDT&startTime=${Math.max(endTime - HOUR, startTime)}&endTime=${endTime}`,
          );
          if (response.status != 200) {
            console.log(await response.text());
            return;
          }
          const aggDatas: Array<AggregateData> = await response.json();
          console.log(aggDatas);

          addData(aggDatasToKlineDatas(aggDatas));

          endTime -= HOUR;
        }
      } else {
        const response = await fetch(`${ApiHost}/aggTrades?symbol=BTCUSDT&startTime=${startTime}&endTime=${endTime}`);
        if (response.status != 200) {
          console.log(await response.text());
          return;
        }
        const aggDatas: Array<AggregateData> = await response.json();

        addData(aggDatasToKlineDatas(aggDatas));
      }
    } else {
      let interval = '1m';
      if (tick > DAY) {
        interval = '1d';
      } else if (tick > 12 * HOUR) {
        interval = '12h';
      } else if (tick > 8 * HOUR) {
        interval = '8h';
      } else if (tick > 6 * HOUR) {
        interval = '6h';
      } else if (tick > 4 * HOUR) {
        interval = '4h';
      } else if (tick > 2 * HOUR) {
        interval = '2h';
      } else if (tick > HOUR) {
        interval = '1h';
      } else if (tick > 30 * MINUTE) {
        interval = '30m';
      } else if (tick > 15 * MINUTE) {
        interval = '15m';
      } else if (tick > 5 * MINUTE) {
        interval = '5m';
      } else if (tick > 3 * MINUTE) {
        interval = '3m';
      }

      const response = await fetch(`${ApiHost}/klines?symbol=BTCUSDT&interval=${interval}&startTime=${startTime}&endTime=${endTime}`);
      if (response.status != 200) {
        console.log(await response.text());
        return;
      }

      const klineData: Array<Array<any>> = await response.json();
      addData(
        klineData.map((e, i) => {
          const date = e[0];
          const open = Number(e[1]);
          const high = Number(e[2]);
          const low = Number(e[3]);
          const close = Number(e[4]);
          return {
            x: date,
            price: close,
            open,
            close,
            low,
            high,
            end: date + tick,
          };
        }),
      );
    }

    isDataLoding.current = false;
  };

  const getTopPos = (price: number): number => {
    const clampedData = klineData.filter((d) => d.x > domainX[0] - tick && d.x < domainX[1] + tick);
    const min = Math.min(...clampedData.map((e) => e.price)) - 10;
    const max = Math.max(...clampedData.map((e) => e.price)) + 10;

    return ((max - price) / (max - min)) * chartHeight;
  };

  function copyText(text: string) {
    if (!document.queryCommandSupported('copy')) {
      return alert(t('cannot-copy'));
    }

    return alert(t('copy-success'));
  }

  // const onZoom = (distance: number) => {
  //   setDomainX((domain) => {
  //     const periodMax = (tick * (chartWidth - yAxisWidth)) / tickWidthMin;
  //     const periodMin = (tick * (chartWidth - yAxisWidth)) / tickWidthMax;
  //     const ratioMax = periodMax / (domain[1] - domain[0]);
  //     const ratioMin = periodMin / (domain[1] - domain[0]);

  //     const diff = distance - currentDistanceX.current;
  //     currentDistanceX.current = distance;

  //     let ratio = 1 + diff;

  //     if (ratio >= ratioMax) {
  //       // 축소
  //       // autoTick 켜져있으면 tick 을 다음단계로 조절해야할 듯?
  //       if (autoTick) {
  //         const curTickIdx = Ticks.findIndex((e) => e.tick == tick);
  //         if (curTickIdx == Ticks.length - 1) {
  //           // 제일 큼
  //           ratio = ratioMax;
  //         } else {
  //           setTick(Ticks[curTickIdx + 1].tick);
  //         }
  //       } else {
  //         ratio = ratioMax;
  //       }
  //     } else if (ratio <= ratioMin) {
  //       // 확대
  //       if (autoTick) {
  //         const curTickIdx = Ticks.findIndex((e) => e.tick == tick);
  //         if (curTickIdx == 0) {
  //           // 제일 짧음
  //           ratio = ratioMin;
  //         } else {
  //           setTick(Ticks[curTickIdx - 1].tick);
  //         }
  //       } else {
  //         ratio = ratioMin;
  //       }
  //     }

  //     let date = Date.now();

  //     if (date > domain[1]) {
  //       date = domain[0] + (currentPosX.current / (chartWidth - yAxisWidth)) * (domain[1] - domain[0]);
  //     }
  //     domain[1] = date + (domain[1] - date) * ratio;
  //     domain[0] = date - (date - domain[0]) * ratio;

  //     return [...domain];
  //   });
  // };

  const onDrag = (e: number) => {
    console.log(e);
    if (isDragging.current) {
      const deltaX = e - currentPosX.current;
      const deltaTime = ((domainX[1] - domainX[0]) * deltaX) / (chartWidth - yAxisWidth);
      setDomainX((d) => {
        d[0] -= deltaTime;
        d[1] -= deltaTime;

        const bound = ((d[1] - Date.now()) * (chartWidth - yAxisWidth)) / (d[1] - d[0]);
        // console.log(bound);
        if (bound > RightBound) {
          const diff = ((bound - RightBound) * (d[1] - d[0])) / (chartWidth - yAxisWidth);
          d[0] -= diff;
          d[1] -= diff;
        }

        console.log(d);

        return [...d];
      });
    }
    currentPosX.current = e;
  };

  useEffect(() => {
    // 범위랑 캔들 기간 바뀔 때 마다 비어있는 데이터 있는지 확인해야하고
    // 법위가 넓은 상태에서 짧은 상태로 바뀐거면 그걸 비우고 데이터를 채워야함
    // tick 이 줄었는지 넓어졌는지 어케 알 것?????
    if (isDataLoding.current) return;

    const now = Date.now();

    const left = domainX[0];
    const right = Math.min(domainX[1], now);
    // 현재 표시된 데이터들임 (klineData 기준)
    const clampedData = klineData.filter((d) => d.x >= left && d.x <= right);

    const minX = clampedData[0].x;
    const maxX = clampedData[clampedData.length - 1].x;

    if (clampedData.length > 0) {
      const minX = clampedData[0].x;
      const maxX = clampedData[clampedData.length - 1].x;

      if (minX > left + tick) {
        getData(minX - (domainX[1] - domainX[0]), minX, tick);
      }
      if (maxX < right - tick) {
        getData(maxX, maxX + (domainX[1] - domainX[0]), tick);
      }

      if (clampedData.length < (maxX - minX) / tick) {
        getData(minX, maxX, tick);
      }
    } else {
      getData(domainX[0], domainX[1], tick);
    }
  }, [domainX, tick]);

  return (
    <Styled.Container>
      <Styled.Header>
        <SizedBoxW width={20} />
        <Styled.MenuButton onClick={() => setMenuOn(true)}>
          <SizedImage src={MenuIcon} width={20} height={14} />
        </Styled.MenuButton>
        <SizedBoxW width={20} />
        <Link to="/">
          <SizedImage src={HeaderLogo} width={40} height={40} />
        </Link>
        <SizedBoxW width={10} />
        <StyledText fontSize={16} fontWeight="bold" color={Colors.gold}>
          프로 챌린지
        </StyledText>
        <Span />
        <Styled.RecentTrade onClick={() => setTransferMenuOn(true)}>
          <StyledText color={Colors.gold} fontSize={12} textAlign="center">
            예금 하기
          </StyledText>
        </Styled.RecentTrade>
        <SizedBoxW width={10} />
        <Styled.RecentTrade onClick={() => setTradesMenuOn(true)}>
          <StyledText color={Colors.gold} fontSize={12} textAlign="center">
            최근 거래
          </StyledText>
        </Styled.RecentTrade>
        <SizedBoxW width={10} />
      </Styled.Header>
      <Styled.ChartArea
        height={chartHeight}
        onTouchStart={(e) => {
          isDragging.current = true;
          if (e.touches.length == 1) {
            currentPosX.current = e.touches[0].clientX;
          }
          // else if (e.touches.length == 2) {
          //   const touch1 = e.touches[0];
          //   const touch2 = e.touches[1];
          //   currentDistanceX.current = Math.abs(touch1.clientX - touch2.clientX);
          // }
        }}
        onTouchMove={(e) => {
          console.log(e);
          if (e.touches.length == 1) {
            onDrag(e.touches[0].clientX);
          }
          //  else if (e.touches.length == 2) {
          //   const touch1 = e.touches[0];
          //   const touch2 = e.touches[1];
          //   onZoom(Math.abs(touch2.clientX - touch1.clientX));
          // }
        }}
        onTouchEnd={(e) => {
          if (e.touches.length == 0) {
            isDragging.current = false;
          }
        }}
      >
        {chartType == 'line' ? (
          <AreaChart
            width={chartWidth}
            height={chartHeight}
            data={klineData.filter((d) => d.x > domainX[0] - tick && d.x < domainX[1] + tick)}
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
          >
            <defs>
              <linearGradient id="fill" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ffffff" stopOpacity={0.5} />
                <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="x"
              mirror={true}
              tickSize={2}
              opacity="1"
              scale="time"
              domain={domainX}
              type="number"
              allowDataOverflow={true}
              tickFormatter={(t) => dayjs(t).format('HH:mm:ss')}
              fontSize={8 * pixel}
              tickMargin={40 * pixel}
            />
            <YAxis
              dataKey="price"
              type="number"
              mirror={false}
              orientation={'right'}
              allowDecimals={false}
              allowDataOverflow={true}
              width={yAxisWidth}
              domain={[(dataMin: number) => dataMin - 10, (dataMax: number) => dataMax + 10]}
              fontSize={8 * pixel}
            />
            <CartesianGrid opacity="0.15" />
            <Tooltip
              wrapperStyle={{ background: 'none', border: 'none' }}
              contentStyle={{ backgroundColor: '#242322', boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.35)', borderRadius: '4px' }}
              itemStyle={{ color: 'white' }}
              labelStyle={{ color: 'white' }}
              separator={''}
              filterNull={false}
              formatter={function (value: number | null, name: string) {
                if (value == null) return ['', ''];
                else return [`${t('challenge-tooltip-price')}: ${Math.floor(value * 100) / 100}`, ''];
              }}
              labelFormatter={function (value) {
                return `${t('challenge-tooltip-time')}: ${dayjs(value).format('HH:mm:ss')}`;
              }}
            />
            <Area
              type="monotone"
              connectNulls={true}
              dataKey="price"
              stroke="#ffffff"
              fillOpacity={1}
              fill="url(#fill)"
              strokeWidth={1}
              isAnimationActive={false}
            />
          </AreaChart>
        ) : chartType == 'candlestick' ? (
          <BarChart
            width={chartWidth}
            height={chartHeight}
            data={klineData
              .filter((d) => d.x > domainX[0] - tick && d.x < domainX[1] + tick)
              .map(({ open, close, ...other }) => {
                return { ...other, openClose: [open, close] };
              })}
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
          >
            <XAxis
              dataKey="x"
              mirror={true}
              tickSize={2}
              opacity="1"
              scale="time"
              domain={domainX}
              type="number"
              allowDataOverflow={true}
              tickFormatter={(t) => dayjs(t).format('HH:mm:ss')}
              fontSize={8 * pixel}
              tickMargin={40 * pixel}
            />
            <YAxis
              dataKey="price"
              type="number"
              mirror={false}
              orientation={'right'}
              allowDecimals={false}
              allowDataOverflow={true}
              width={yAxisWidth}
              domain={[(dataMin: number) => dataMin - 10, (dataMax: number) => dataMax + 10]}
              fontSize={8 * pixel}
            />
            <CartesianGrid opacity="0.15" />
            <Bar dataKey="openClose" isAnimationActive={false} shape={<OpenCloseBar />} />
            {/* <Bar dataKey="lowhigh" isAnimationActive={false} shape={<LowHighBar />} /> */}
          </BarChart>
        ) : (
          <></>
        )}

        <Styled.ChartTopMenu>
          <FlexRow alignItems="center">
            <SizedImage src={IconBitcoin} width={20} height={20} />
            <SizedBoxW width={5} />
            <StyledText opacity={0.5} fontSize={12}>
              BTC/USD(Binance)
            </StyledText>
            <Span />
            <StyledText fontSize={12} fontWeight="bold" color={Colors.gold}>
              {pointC.toLocaleString()}p
            </StyledText>
            <SizedBoxW width={5} />
            <SizedImage src={PointCIcon} width={20} height={20} />
          </FlexRow>
        </Styled.ChartTopMenu>

        <Styled.ChartMenuButtonArea>
          <FlexColumn>
            <Styled.ChartMenuButton onClick={() => setChartMenu('chart')}>
              <SizedImage src={ChartIcons[chartType]} width={30} height={30} />
            </Styled.ChartMenuButton>
            <SizedBoxH height={10} />
            <Styled.ChartMenuButton onClick={() => setChartMenu('tick')}>
              <StyledText fontSize={12} fontWeight="bold" opacity={0.35}>
                {Ticks.find((e) => e.tick == tick)?.initial}
              </StyledText>
            </Styled.ChartMenuButton>
            <SizedBoxH height={10} />
            <Styled.ChartMenuButton onClick={() => setChartMenu('period')}>
              <StyledText fontSize={12} fontWeight="bold" opacity={0.35}>
                {periodLabel}
              </StyledText>
            </Styled.ChartMenuButton>
          </FlexColumn>
        </Styled.ChartMenuButtonArea>

        <Styled.GameInfoArea>
          <FlexRow alignItems="center">
            <SizedImage width={13} height={15} src={IconTimeWatch} />
            <SizedBoxW width={5} />
            <OTF_B_10>{dayjs(endTime - Date.now()).format('mm:ss')}</OTF_B_10>
          </FlexRow>
          <FlexRow>
            <SizedImage width={10} height={15} src={IconDollar} />
            <SizedBoxW width={5} />
            <OTF_B_10>
              {currentPositions.length != 0
                ? currentPositions
                    .reduce((p, c) => {
                      const success = currentPrice > c.priceAtBet == c.long;
                      const result = success ? c.point * (1 + returnRate) : 0;
                      return p + result;
                    }, 0)
                    .toFixed(0)
                : 0}
              P
            </OTF_B_10>
          </FlexRow>
          <FlexRow>
            <SizedImage width={15} height={15} src={IconTime} />
            <SizedBoxW width={5} />
            <OTF_B_10>{dayjs(Date.now()).format('A HH:mm')}</OTF_B_10>
          </FlexRow>
          <FlexRow>
            <OTF_B_10>수익률</OTF_B_10>
            <SizedBoxW width={5} />
            <StyledText fontWeight="bold" fontSize={10} color={Colors.green}>
              {(returnRate * 100).toFixed(2)}%
            </StyledText>
          </FlexRow>
        </Styled.GameInfoArea>

        <OutsideClickHandler onOutsideClick={() => setChartMenu('none')}>
          {chartMenu == 'chart' && (
            <>
              <Styled.ChartSelectMenu>
                <Styled.ChartSelectButton
                  onClick={() => {
                    setChartMenu('none');
                    setChartType('line');
                  }}
                >
                  <SizedImage width={31} height={30} src={chartType == 'line' ? IconLineChartSelected : IconLineChart} />
                  <SizedBoxW width={20} />
                  <StyledText color={chartType == 'line' ? Colors.gold : 'white'} fontSize={16} fontWeight="bold">
                    라인
                  </StyledText>
                </Styled.ChartSelectButton>
                <Styled.ChartSelectButton
                  onClick={() => {
                    setChartMenu('none');
                    setChartType('candlestick');
                  }}
                >
                  <SizedImage
                    width={31}
                    height={30}
                    src={chartType == 'candlestick' ? IconCandlestickChartSelected : IconCandlestickChart}
                  />
                  <SizedBoxW width={20} />
                  <StyledText color={chartType == 'candlestick' ? Colors.gold : 'white'} fontSize={16} fontWeight="bold">
                    촛대
                  </StyledText>
                </Styled.ChartSelectButton>
              </Styled.ChartSelectMenu>
            </>
          )}
          {chartMenu == 'tick' && (
            <>
              <Styled.TickSelectMenu>
                <OTF_B_16>캔들 기간</OTF_B_16>
                <StyledText color={Colors.gold} fontSize={10}>
                  * 특정 시간 간격의 캔들과 바 모두에 사용되는 가격범위입니다.
                </StyledText>
                <SizedBoxH height={20} />
                <Styled.TickSeletcButtons>
                  {Ticks.map((e, i) => {
                    return (
                      <Styled.TickSelectButton
                        onClick={() => {
                          console.log(e.tick);
                          // setTickIndex(i);
                          onTickBtn(e.tick);
                          setChartMenu('none');
                        }}
                        disabled={tick == e.tick}
                        key={`tick${e.initial}`}
                      >
                        {e.label}
                      </Styled.TickSelectButton>
                    );
                  })}
                </Styled.TickSeletcButtons>
                <SizedBoxH height={10} />
                <FlexRow alignItems="center">
                  <ToggleButton width={20} height={20} isOn={autoTick} onClick={() => setAutoTick(!autoTick)} />
                  <SizedBoxW width={10} />
                  <OTF_B_12>자동 크기조절</OTF_B_12>
                </FlexRow>
              </Styled.TickSelectMenu>
            </>
          )}
          {chartMenu == 'period' && (
            <>
              <Styled.PeriodSelectMenu>
                <OTF_B_16>기간</OTF_B_16>
                <StyledText color={Colors.gold} fontSize={10}>
                  * 차트가 표시되는 시간 기간입니다.
                </StyledText>
                <SizedBoxH height={20} />
                <Styled.PeriodSelectButtons>
                  {Periods.map((e, i) => {
                    return (
                      <Styled.PeriodSelectButton
                        onClick={() => {
                          console.log(e.period);
                          // setPeriodIndex(i);
                          onPeriodBtn(e.period);
                          setChartMenu('none');
                        }}
                        // disabled={false}
                        key={`period${e.initial}`}
                      >
                        {e.label}
                      </Styled.PeriodSelectButton>
                    );
                  })}
                </Styled.PeriodSelectButtons>
              </Styled.PeriodSelectMenu>
            </>
          )}
        </OutsideClickHandler>

        {endTime < domainX[1] && endTime > domainX[0] && (
          <Styled.Flag left={((endTime - domainX[0]) * (chartWidth - yAxisWidth)) / (domainX[1] - domainX[0]) - 16 * pixel}>
            <Styled.FlagLine height={chartHeight - 102 * pixel} />
            <SizedImage src={IconFlag} width={32} height={32} />
          </Styled.Flag>
        )}

        {currentPositions.map((e, i) => {
          const time = e.createdAt.getTime();
          // if (time < domainX[0] || time > domainX[1]) return <></>;

          const left = ((time - domainX[0]) * (chartWidth - yAxisWidth)) / (domainX[1] - domainX[0]);
          let top = getTopPos(e.priceAtBet);
          if (top < 0) top = 0;
          if (top > chartHeight) top = chartHeight;

          const color = e.long ? Colors.green : Colors.red;

          return (
            <>
              <div
                style={{
                  width: `${chartWidth}px`,
                  height: `${16 * pixel}`,
                  position: 'absolute',
                  left: '0',
                  top: `${top - 8 * pixel}px`,
                  display: 'flex',
                  alignItems: 'center',
                  pointerEvents: 'none',
                }}
                key={e.id}
              >
                <div style={{ width: `${chartWidth - yAxisWidth}px`, border: `1px solid ${color}`, opacity: 0.75 }}></div>

                <Styled.PriceTag width={yAxisWidth} height={16 * pixel} color={color}>
                  <StyledText color={'black'} fontSize={10} fontWeight="bold">
                    {Math.floor(e.priceAtBet * 100) / 100}
                  </StyledText>
                </Styled.PriceTag>
              </div>
              {time > domainX[0] && time < domainX[1] && (
                <>
                  <img
                    style={{
                      position: 'absolute',
                      top: top - 25 * pixel,
                      left: left - 25 * pixel,
                      width: `${50 * pixel}px`,
                      height: `${50 * pixel}px`,
                      padding: 0,
                      border: 'none',
                      zIndex: 2,
                    }}
                    src={e.long ? LongDot : ShortDot}
                  />
                </>
              )}
            </>
          );
        })}

        <div
          style={{
            width: `${chartWidth}px`,
            height: `${16 * pixel}`,
            position: 'absolute',
            left: '0',
            top: `${getTopPos(currentPrice) - 8 * pixel}px`,
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'none',
            zIndex: 2,
          }}
        >
          <div style={{ width: `${chartWidth - yAxisWidth}px`, border: 'solid 1px #ffffff' }} />

          <Styled.PriceTag width={yAxisWidth} height={16 * pixel} color={'white'}>
            <StyledText color={Colors.dark} fontSize={10} fontWeight="bold">
              {Math.floor(currentPrice * 100) / 100}
            </StyledText>
          </Styled.PriceTag>
        </div>
      </Styled.ChartArea>
      <Styled.MenuArea height={bottomMenuHeight}>
        {gameMenu == 'none' && (
          <>
            <FlexColumn alignItems="center" justifyContent="space-between">
              <FlexRow alignItems="center">
                <ButtonGoldOutlined
                  width={155}
                  height={40}
                  onClick={() => {
                    setBottomMenuHeight(270);
                    setGameMenu('positions');
                  }}
                >
                  <OTF_B_12>현재 포지션 보기</OTF_B_12>
                </ButtonGoldOutlined>
                <SizedBoxW width={10} />
                <ButtonGoldOutlined
                  width={155}
                  height={40}
                  onClick={() => {
                    setBottomMenuHeight(280);
                    setGameMenu('time');
                  }}
                >
                  <FlexRow alignItems="center">
                    <OTF_B_12>시간</OTF_B_12>
                    <SizedBoxW width={5} />
                    <StyledText fontSize={12} fontWeight="bold" color={Colors.gold}>
                      {dayjs(endTime).format('HH:mm')}
                    </StyledText>
                  </FlexRow>
                </ButtonGoldOutlined>
              </FlexRow>
              <SizedBoxH height={10} />
              <FlexRow>
                <Styled.ButtonGreenFilled
                  width={155}
                  height={40}
                  onClick={() => onBetting(true)}
                  disabled={Date.now() > endTime - 30 * SECOND}
                >
                  <OTF_B_16>BUY</OTF_B_16>
                </Styled.ButtonGreenFilled>
                <SizedBoxW width={10} />
                <Styled.ButtonRedFilled
                  width={155}
                  height={40}
                  onClick={() => onBetting(false)}
                  disabled={Date.now() > endTime - 30 * SECOND}
                >
                  <OTF_B_16>SELL</OTF_B_16>
                </Styled.ButtonRedFilled>
              </FlexRow>
              <SizedBoxH height={10} />
              <FlexRow alignItems="center" justifyContent="center">
                <OTF_B_12>투자</OTF_B_12>
                <SizedBoxW width={10} />
                <Styled.CircleButton onClick={() => setBettingPoint((e) => Number(e) - 1)}>
                  <OTF_B_16>-</OTF_B_16>
                </Styled.CircleButton>
                <SizedBoxW width={10} />
                <SizedImage src={PointCIcon} width={20} height={20} />
                <SizedBoxW width={10} />
                <Input
                  width={80}
                  height={20}
                  value={bettingPoint}
                  onChange={(e) => {
                    setBettingPoint(Number(e.target.value));
                  }}
                  type="number"
                  textAlign="right"
                />
                <SizedBoxW width={10} />
                <Styled.CircleButton onClick={() => setBettingPoint((e) => Number(e) + 1)}>
                  <OTF_B_16>+</OTF_B_16>
                </Styled.CircleButton>
              </FlexRow>
              <SizedBoxH height={10} />
            </FlexColumn>
          </>
        )}
        {gameMenu == 'time' && (
          <>
            <FlexRow justifyContent="space-between">
              <FlexColumn width={110} alignItems="stretch">
                <OTF_B_12 textAlign="center">1분</OTF_B_12>
                <SizedBoxH height={10} />
                {Array.from({ length: 5 }, (v, i) => (Math.floor(Date.now() / MINUTE) + i + 1) * MINUTE)
                  .filter((e) => e % (5 * MINUTE) != 0)
                  .map((e) => {
                    return (
                      <>
                        <ButtonGoldOutlined
                          height={40}
                          onClick={() => {
                            onEndTimeBtn(e);
                            setGameMenu('none');
                            setBottomMenuHeight(150);
                          }}
                        >
                          <OTF_B_12>{dayjs(e).format('HH:mm')}</OTF_B_12>
                        </ButtonGoldOutlined>
                        <SizedBoxH height={5} />
                      </>
                    );
                  })}

                <SizedBoxH height={15} />
                <Styled.TimeMenuCancelButton
                  onClick={() => {
                    setGameMenu('none');
                    setBottomMenuHeight(150);
                  }}
                >
                  <OTF_B_12>취소</OTF_B_12>
                </Styled.TimeMenuCancelButton>
              </FlexColumn>
              <FlexColumn width={110} alignItems="stretch">
                <OTF_B_12 textAlign="center">5분</OTF_B_12>
                <SizedBoxH height={10} />

                {Array.from({ length: 3 }, (v, i) => (Math.floor(Date.now() / (5 * MINUTE)) + i + 1) * 5 * MINUTE)
                  .filter((e) => e % (15 * MINUTE) != 0)
                  .map((e) => {
                    return (
                      <>
                        <ButtonGoldOutlined height={40} onClick={() => onEndTimeBtn(e)}>
                          <OTF_B_12>{dayjs(e).format('HH:mm')}</OTF_B_12>
                        </ButtonGoldOutlined>
                        <SizedBoxH height={5} />
                      </>
                    );
                  })}
              </FlexColumn>
              <FlexColumn width={110} alignItems="stretch">
                <OTF_B_12 textAlign="center">15분</OTF_B_12>
                <SizedBoxH height={10} />
                {Array.from({ length: 1 }, (v, i) => Math.floor(Date.now() / (15 * MINUTE) + i + 1) * 15 * MINUTE).map((e) => {
                  return (
                    <>
                      <ButtonGoldOutlined height={40} onClick={() => onEndTimeBtn(e)}>
                        <OTF_B_12>{dayjs(e).format('HH:mm')}</OTF_B_12>
                      </ButtonGoldOutlined>
                      <SizedBoxH height={5} />
                    </>
                  );
                })}
                <SizedBoxH height={5} />
              </FlexColumn>
            </FlexRow>
          </>
        )}
        {gameMenu == 'positions' && (
          <>
            <FlexColumn alignItems="center" justifyContent="space-between">
              <Styled.MenuPositionsBox>
                <FlexColumn alignItems="stretch">
                  <FlexRow alignItems="center" height={15}>
                    <StyledText width={70} height={15} fontSize={12} fontWeight="bold" textAlign="center">
                      완료시간
                    </StyledText>
                    <SizedBoxW width={20} />
                    <StyledText width={70} height={15} fontSize={12} fontWeight="bold" textAlign="center">
                      가격
                    </StyledText>
                    <SizedBoxW width={20} />
                    <StyledText width={70} height={15} fontSize={12} fontWeight="bold" textAlign="center">
                      투자금액
                    </StyledText>
                    <SizedBoxW width={20} />
                    <StyledText width={70} height={15} fontSize={12} fontWeight="bold" textAlign="center">
                      완료 P/L
                    </StyledText>
                  </FlexRow>
                  <SizedBoxH height={5} />
                  {currentPositions.map((e, i) => {
                    const success = currentPrice > e.priceAtBet == e.long;
                    const result = success ? (1 + returnRate) * e.point : 0;

                    return (
                      <>
                        <SizedBoxH height={5} />
                        <FlexRow alignItems="center" height={15}>
                          <StyledText width={70} height={15} fontSize={12} fontWeight="bold" textAlign="center">
                            {dayjs(e.createdAt).format('HH:mm')}
                          </StyledText>
                          <SizedBoxW width={20} />
                          <StyledText width={70} height={15} fontSize={12} fontWeight="bold" textAlign="center">
                            {e.priceAtBet.toLocaleString()}
                          </StyledText>
                          <SizedBoxW width={20} />
                          <StyledText
                            width={70}
                            height={15}
                            fontSize={12}
                            fontWeight="bold"
                            textAlign="center"
                            color={e.long ? Colors.green : Colors.red}
                          >
                            {e.point}p
                          </StyledText>
                          <SizedBoxW width={20} />
                          <StyledText
                            width={70}
                            height={15}
                            fontSize={12}
                            fontWeight="bold"
                            textAlign="center"
                            color={result > e.point ? Colors.green : Colors.red}
                          >
                            {result.toFixed(0)}p
                          </StyledText>
                        </FlexRow>
                      </>
                    );
                  })}
                </FlexColumn>
              </Styled.MenuPositionsBox>
              <SizedBoxH height={10} />
              <FlexRow alignItems="center" justifyContent="center">
                <OTF_B_12>투자</OTF_B_12>
                <SizedBoxW width={10} />
                <Styled.CircleButton onClick={() => setBettingPoint((e) => Number(e) - 1)}>
                  <OTF_B_16>-</OTF_B_16>
                </Styled.CircleButton>
                <SizedBoxW width={10} />
                <SizedImage src={PointCIcon} width={20} height={20} />
                <SizedBoxW width={10} />
                <Input
                  width={80}
                  height={20}
                  value={bettingPoint}
                  onChange={(e) => {
                    setBettingPoint(Number(e.target.value));
                  }}
                  type="number"
                  textAlign="right"
                />
                <SizedBoxW width={10} />
                <Styled.CircleButton onClick={() => setBettingPoint((e) => Number(e) + 1)}>
                  <OTF_B_16>+</OTF_B_16>
                </Styled.CircleButton>
              </FlexRow>
              <SizedBoxH height={10} />

              <Styled.PositionMenuCancelButton
                onClick={() => {
                  setGameMenu('none');
                  setBottomMenuHeight(150);
                }}
              >
                <OTF_B_12>돌아가기</OTF_B_12>
              </Styled.PositionMenuCancelButton>
            </FlexColumn>
          </>
        )}
      </Styled.MenuArea>
      {menuOn && <SideMenu onClose={() => setMenuOn(false)} />}
      {tradesMenuOn && <RecentTrades trades={trades} onClose={() => setTradesMenuOn(false)} />}
      {showResult && (
        <Styled.Result>
          <Styled.ResultBox>
            <SizedBoxH height={10} />
            <FlexRow>
              <SizedBoxW width={10} />
              <OTF_B_16>BTC/USDT</OTF_B_16>
            </FlexRow>
            <SizedBoxH height={10} />
            <FlexRow>
              <SizedBoxW width={10} />
              <StyledText width={50} color={Colors.gold} fontSize={12} fontWeight="bold">
                종료 금액
              </StyledText>
              <SizedBoxW width={10} />
              <StyledText width={80} fontSize={12} fontWeight="bold">
                {resultTrades.length > 0 ? resultTrades[0].priceAtClose.toFixed(2) : 0}
              </StyledText>
              <StyledText width={50} color={Colors.gold} fontSize={12} fontWeight="bold">
                종료 시간
              </StyledText>
              <SizedBoxW width={10} />
              <StyledText width={80} fontSize={12} fontWeight="bold">
                22:20
              </StyledText>
            </FlexRow>
            <SizedBoxH height={20} />
            {resultMenu == 'overview' && (
              <>
                <FlexRow>
                  <SizedBoxW width={10} />
                  <StyledText width={140} fontSize={12} fontWeight="bold">
                    전체 투자금액
                  </StyledText>
                  <StyledText width={140} fontSize={12} fontWeight="bold">
                    전체 수익
                  </StyledText>
                </FlexRow>
                <SizedBoxH height={5} />
                <FlexRow height={30} alignItems="center">
                  <SizedBoxW width={10} />
                  <FlexRow width={140} height={30}>
                    <SizedImage src={PointCIcon} width={30} height={30} />
                    <SizedBoxW width={10} />
                    <StyledText fontSize={16} fontWeight="bold" color={Colors.gold}>
                      {resultTrades.reduce((p, c) => p + c.point, 0).toFixed(0)}P
                    </StyledText>
                  </FlexRow>
                  <FlexRow width={140} height={30}>
                    <SizedImage src={PointCIcon} width={30} height={30} />
                    <SizedBoxW width={10} />
                    <StyledText fontSize={16} fontWeight="bold" color={Colors.gold}>
                      {(resultTrades.reduce((p, c) => p + c.resultPoint, 0) - resultTrades.reduce((p, c) => p + c.point, 0)).toFixed(0)}P
                    </StyledText>
                  </FlexRow>
                  <SizedBoxW width={10} />
                </FlexRow>
                <SizedBoxH height={20} />
                <FlexRow>
                  <ButtonGoldFilled fontSize={12} width={145} height={40} onClick={() => setResultMenu('positions')}>
                    세부사항
                  </ButtonGoldFilled>
                  <SizedBoxW width={10} />
                  <ButtonGoldOutlined width={145} height={40} onClick={() => setShowResult(false)}>
                    <OTF_B_12>닫기</OTF_B_12>
                  </ButtonGoldOutlined>
                </FlexRow>
              </>
            )}
            {resultMenu == 'positions' && (
              <>
                <Styled.ResultBoxPositions>
                  <FlexRow alignItems="center">
                    <SizedBoxW width={20} />
                    <StyledText color={Colors.gold} width={60} fontSize={10} fontWeight="bold" textAlign="center">
                      베팅시 금액
                    </StyledText>
                    <SizedBoxW width={10} />
                    <StyledText color={Colors.gold} width={60} fontSize={10} fontWeight="bold" textAlign="center">
                      베팅 시간
                    </StyledText>
                    <SizedBoxW width={10} />
                    <StyledText color={Colors.gold} width={50} fontSize={10} fontWeight="bold" textAlign="center">
                      베팅 금액
                    </StyledText>
                    <SizedBoxW width={10} />
                    <StyledText color={Colors.gold} width={50} fontSize={10} fontWeight="bold" textAlign="center">
                      수익 금액
                    </StyledText>
                  </FlexRow>
                  <SizedBoxH height={10} />
                  {resultTrades.map((e, i) => {
                    return (
                      <>
                        <FlexRow alignItems="center">
                          {e.long ? <Styled.ArrowLong /> : <Styled.ArrowShort />}
                          <SizedBoxW width={10} />
                          <StyledText width={60} fontSize={12} fontWeight="bold" textAlign="center">
                            {e.priceAtBet.toFixed(2)}
                          </StyledText>
                          <SizedBoxW width={10} />
                          <StyledText width={60} fontSize={12} fontWeight="bold" textAlign="center">
                            {dayjs(e.createdAt).format('HH:mm:ss')}
                          </StyledText>
                          <SizedBoxW width={10} />
                          <StyledText width={50} fontSize={12} fontWeight="bold" textAlign="center">
                            L.P {e.point}
                          </StyledText>
                          <SizedBoxW width={10} />
                          <StyledText width={50} fontSize={12} fontWeight="bold" textAlign="center">
                            L.P {e.resultPoint}
                          </StyledText>
                        </FlexRow>
                        <SizedBoxH height={10} />
                      </>
                    );
                  })}
                </Styled.ResultBoxPositions>
                <SizedBoxH height={20} />
                <FlexRow>
                  <ButtonGoldFilled fontSize={12} width={145} height={40} onClick={() => setResultMenu('overview')}>
                    이전
                  </ButtonGoldFilled>
                  <SizedBoxW width={10} />
                  <ButtonGoldOutlined width={145} height={40} onClick={() => setShowResult(false)}>
                    <OTF_B_12>닫기</OTF_B_12>
                  </ButtonGoldOutlined>
                </FlexRow>
              </>
            )}
          </Styled.ResultBox>
        </Styled.Result>
      )}
      {transferMenuOn && (
        <Styled.TransferMenuContainer>
          <Styled.TransferMenuBox>
            <FlexColumn alignItems="stretch">
              <SizedBoxH height={10} />
              <FlexRow>
                <SizedBoxW width={10} />
                <OTF_B_16>예금하기</OTF_B_16>
              </FlexRow>
              <SizedBoxH height={10} />
              <FlexRow>
                <SizedBoxW width={10} />
                <OTF_B_12>토너먼트 포인트 충전용 이더리움 입금주소</OTF_B_12>
              </FlexRow>
              <SizedBoxH height={10} />
              <Styled.TransferMenuInnerBox>
                <FlexRow>
                  <StyledText fontSize={12} fontWeight="bold" color={Colors.gold}>
                    지갑 주소
                  </StyledText>
                  <Span />
                  <StyledText width={220} height={30} fontSize={12} wordBreak={'break-all'}>
                    {centralWallet ? centralWallet : ''}
                  </StyledText>
                </FlexRow>
                <SizedBoxH height={10} />
                <FlexRow>
                  <ButtonGoldOutlined width={100} height={30} fontSize={14} onClick={() => (centralWallet ? copyText(centralWallet) : {})}>
                    복사하기
                  </ButtonGoldOutlined>
                  <Span />
                  <StyledText width={166} height={30} fontSize={10} color={Colors.red} wordBreak={'break-all'}>
                    * 입금 주소를 잘못 입력하여 전송한 경우 되찾을 수 없습니다!
                  </StyledText>
                </FlexRow>
                <SizedBoxH height={20} />
                <FlexRow>
                  <StyledText color={Colors.gold} fontSize={12} fontWeight="bold">
                    교환비
                  </StyledText>
                  <SizedBoxW width={25} />
                  <OTF_B_12>10$ ⇢ 10p</OTF_B_12>
                </FlexRow>
                <SizedBoxH height={10} />
                <OTF_R_10>
                  *이더리움 가격은 거래소 기준으로 실시간으로 반영되며 포인트 교환은 전송 된 시점의 이더리움 가격으로 결정됩니다.
                </OTF_R_10>
              </Styled.TransferMenuInnerBox>
              <SizedBoxH height={10} />
              <ButtonGoldOutlined height={40} fontSize={12} color={'white'} onClick={() => setTransferMenuOn(false)}>
                닫기
              </ButtonGoldOutlined>
            </FlexColumn>
          </Styled.TransferMenuBox>
        </Styled.TransferMenuContainer>
      )}
    </Styled.Container>
  );
});

const OpenCloseBar = (props: any) => {
  const {
    openClose: [open, close],
    low,
    high,
    x,
    y,
    width,
    height,
  } = props;
  const isGrowing = open < close;
  const color = isGrowing ? 'green' : 'red';
  const ratio = open === close ? 0 : Math.abs(height / (open - close));

  return (
    <g stroke={color} fill={'none'} strokeWidth="2">
      <path
        fill={color}
        d={`
          M ${x},${y}
          L ${x},${y + height}
          L ${x + width},${y + height}
          L ${x + width},${y}
          L ${x},${y}
        `}
      />
      {/* bottom line */}
      {isGrowing ? (
        <path
          d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - low) * ratio}
          `}
        />
      ) : (
        <path
          d={`
            M ${x + width / 2}, ${y}
            v ${(close - low) * ratio}
          `}
        />
      )}
      {/* top line */}
      {isGrowing ? (
        <path
          d={`
            M ${x + width / 2}, ${y}
            v ${(close - high) * ratio}
          `}
        />
      ) : (
        <path
          d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - high) * ratio}
          `}
        />
      )}
    </g>
  );
};

const RecentTrades = (props: { trades: ProChallengeRecentTradeInfo[]; onClose: () => void }) => {
  const tradeGroups: { day: number; trades: ProChallengeRecentTradeInfo[] }[] = [];
  props.trades.forEach((trade) => {
    const day = Math.floor(trade.createdAt.getTime() / DAY) * DAY;
    const group = tradeGroups.find((g) => g.day == day);
    if (group) {
      group.trades.push(trade);
    } else {
      tradeGroups.push({
        day: day,
        trades: [trade],
      });
    }
  });
  return (
    <Styled.RecentTradeMenu>
      <Motion defaultStyle={{ x: 0 }} style={{ x: spring(270) }}>
        {(value) => (
          <OutsideClickHandler onOutsideClick={props.onClose}>
            <Styled.RecentTradeMenuContainer width={270} right={value.x}>
              <SizedBoxH height={20} />
              <StyledText fontSize={16} height={20} fontWeight="bold" color={Colors.gold} textAlign="center">
                최근 거래
              </StyledText>
              <SizedBoxH height={20} />

              <Styled.RecentTradeList>
                {tradeGroups.map((e, i) => {
                  return (
                    <>
                      <Styled.RecentTradeDay key={e.day}>
                        <FlexRow alignItems="center" height={20}>
                          <OTF_R_10>{dayjs(e.day).format('YYYY-MM-DD')}</OTF_R_10>
                          <Span />
                          <OTF_R_10>{e.trades.length} 거래</OTF_R_10>
                        </FlexRow>
                      </Styled.RecentTradeDay>
                      {e.trades.map((t, i) => {
                        const earn = t.resultPoint - t.point;
                        return (
                          <Styled.RecentTradeInfo key={t.id}>
                            <FlexRow alignItems="center" height={20}>
                              <StyledText height={20} fontSize={15} fontWeight="bold">
                                {dayjs(t.createdAt).format('HH:mm')}
                              </StyledText>
                              <Span />
                              <StyledText
                                textAlign="right"
                                fontWeight="bold"
                                fontSize={15}
                                height={20}
                                color={earn > 0 ? Colors.gold : 'white'}
                                opacity={earn > 0 ? 1 : 0.55}
                              >
                                {earn > 0 ? '+' : ''}
                                {earn.toLocaleString()}p
                              </StyledText>
                            </FlexRow>
                            <FlexRow height={20} alignItems="center">
                              {t.long ? <Styled.ArrowLong /> : <Styled.ArrowShort />}
                              <OTF_R_12>BTC/USDT</OTF_R_12>
                              <Span />
                              <OTF_R_12 textAlign="right">{t.point.toLocaleString()}p</OTF_R_12>
                            </FlexRow>
                          </Styled.RecentTradeInfo>
                        );
                      })}
                    </>
                  );
                })}
              </Styled.RecentTradeList>
            </Styled.RecentTradeMenuContainer>
          </OutsideClickHandler>
        )}
      </Motion>
    </Styled.RecentTradeMenu>
  );
};
