export default {
  'ko-KR': {
    translation: {
      'main-menu-challenge-info': '챌린지 소개',
      'main-menu-challenge-participate': '챌린지 참여',
      'main-menu-customercare': '고객센터',
      'main-menu-notice': '공지사항',
      'main-menu-signup': '회원가입',
      'main-menu-mypage': '마이페이지',
      'main-menu-signin': '로그인',
      'main-menu-signout': '로그아웃',
      'verify-email-label': '인증하는 중입니다. 잠시만 기다려주세요.',
      'verify-email-alert-failed': '만료되었습니다. 새로운 인증 메일을 받으시려면 로그인해주세요.',
      'verify-email-alert-success': '인증되었습니다.',
      'verify-phone-input-placeholder': '코드',
      'verify-phone-resend-text': '메세지 발송',
      'verify-phone-verify-text': '인증',
      'verify-phone-alert-invalid-code': '올바른 코드가 아닙니다.',
      'verify-phone-alert-resent': '발송되었습니다.',
      'verify-phone-alert-success': '인증되었습니다.',
      // 챌린지
      'challenge-login-first': '로그인 후 이용해주세요!',
      'challenge-round-lose': '라운드 패배...',
      'challenge-round-win': '라운드 승리!',
      'challenge-already-end': '이미 끝난 챌린지입니다!',
      'challenge-continuous-description': '60분 내로 몇단계 까지 연달아\n승수를 올리는가에 따라 시상금을 지급합니다.',
      'challenge-fixed-description': '60분 내에 패와 상관 없이\n승수를 합산하여 합산된 승의 단계에 따라\n시상금을 지급합니다.',
      'challenge-settings': '챌린지 설정',
      'challenge-mode': '챌린지 모드',
      'challenge-uses-point': '사용 포인트',
      'challenge-previous': '이전',
      'challenge-next': '다음',
      'challenge-remain-time': '제한시간',
      'challenge-tooltip-price': '가격',
      'challenge-tooltip-time': '시간',
      'challenge-round': '라운드',
      'challenge-remaining-time-to-betting': '베팅까지 남은 시간',
      'challenge-remaining-time-to-round': '라운드 남은 시간',
      'challenge-win': '승',
      'challenge-lose': '패',
      'challenge-straight-win': '연승',
      'challenge-toast-bet-long': '롱에 베팅했습니다!',
      'challenge-toast-bet-short': '숏에 베팅했습니다!',
      'challenge-alert-not-enough-point': '베팅에 실패했습니다! 포인트가 충분한지 확인해주세요!',
      'challenge-stop-challenge': 'STOP / 보상 받기',
      'challenge-rewards': '승리 보상',
      'challenge-total-rewards-label': '시상금',
      'challenge-end-normal': '챌린지 종료',
      'challenge-end-expired': '시간 초과',
      'challenge-end-lose': '패배',
      'challenge-result-stage': '단계',
      'challenge-result-your-records': '당신의 기록은...',
      'challenge-result-total-points': '얻은 포인트',
      'challenge-point': '포인트',
      'challenge-result-confirm': '완료',
      // 고객 센터
      'service-faq': '자주 묻는 질문',
      'service-author': '작성자',
      'service-date': '게시일',
      'service-center': '고객 센터',
      'service-send-enquiry': '문의하기',
      'service-category': '카테고리',
      'service-title': '제목',
      'service-previous': '이전 글',
      'service-next': '다음 글',
      // Footer
      'footer-support': '문의',
      // 소개 페이지
      'introduction-introduction': 'MAX4 챌린지 소개',
      'introduction-how-to-play': '챌린지 이용방법',
      'introduction-introduction-p1':
        '수익률을 기준으로 평가 받는 전통적이고 보수적인 모의 대회나 라이브 대회 방식이 아닌 승률을 높여서 안정적인 투자수익을 추구할 수 있도록 개발된 디지털 트레이딩 교육 플랫폼 MAX4는 전문 트레이더 및 트레이딩 강사를 육성하기 위해 개발되었습니다.',
      'introduction-introduction-t1': '초 단타 매매(스캘핑) 실력 향상',
      'introduction-introduction-t2': '트레이딩 실력 평가',
      'introduction-introduction-t3': '실력에 따라 트레이딩 포인트 지급',
      'introduction-introduction-d1':
        '변동성이 강한 하이로우 방식의 옵션 트레이딩을 통해 BTC 시세를 예측하고 트레이딩함으로써 트레이더는 챌린지에 참가 할수록 트레이딩에 필요한 정확한 진입 포인트를 예측할 수 있어서 초 단타매매(스켈핑) 실력이 향상됩니다.',
      'introduction-introduction-d2':
        '시대가 발전함에따라 투자처의 다변화로 투자하기 전 자신의 트레이딩 실력을 객관적으로 평가 받아 무분별한 투자의 위험 에서 손실을 방지할 수 있습니다.',
      'introduction-introduction-d3':
        '돈이 없어도 실력만 있으면 챌린지에 참가하여 트레이딩 레벨에 따라 트레이딩 포인트를 지급 받아 트레이딩을할 수 있습니다.',
      'introduction-how-to-trade': '입/출금 방법',
      'introduction-how-to-play-p1':
        '트레이딩 실력을 객관적으로 평가 받을 수 있도록 개발된 MAX4 플랫폼은 데모 챌린지, 토너먼트 챌린지, 라이브챌린지가 있으며 챌린지 방식에는 연승 모드와 다승 모드가 있습니다. \n\nMAX4 챌린지는 1시간의 제한 시간 동안 트레이딩을 하게 되고 1회는 1분 동안 진행되어지는데 처음 30초 동안은 Long, Short 둘 중에 하나를 선택하여(시간초과시 선택 불가능) 남은 30초동안 자신이 선택한 BTC시세의 시점에 비하여 Long(가격이 높을 경우), Short(가격이 낮을 경우)을 예측하는 디지털 트레이딩 교육 프로그램입니다.',
      'introduction-how-to-play-p2':
        '무료 또는 회원 가입 시 지급받은 $10의 데모 포인트로 트레이딩 할 수 있으며 토너먼트 챌린지에서 사용할 수 있는 토너먼트 포인트를 획득하기 하기 위한 챌린지입니다.',
      'introduction-how-to-play-p3':
        '참가 방법 : 회원 가입이나 회원 소개 시 $10에 해당하는 데모챌린지에 사용할 수 있는 데모 포인트를 받습니다.',
      'introduction-how-to-play-p4':
        '$10의 토너먼트 포인트로 트레이딩 할 수 있으며 라이브 챌린지에 사용할 수 있는 라이브 포인트를 획득하기 위한 챌린지입니다.',
      'introduction-how-to-play-p5':
        '참가 방법 : $10의 토너먼트 포인트를 지불하거나 이더리움으로 토너먼트 포인트를 충전하여 참여가 가능합니다.',
      'introduction-how-to-play-p6': '라이브 트레이딩은 토너먼트 챌린지를 참여하여 얻은 라이브 포인트로만 참여할 수 있습니다.',
      'introduction-how-to-play-p7':
        '트레이딩 방법 : 라이브 포인트 10P 사용하여 트레이딩 진행할 수 있으며, 해당 챌린지에서 얻은 보상은 이더리움 포인트로 지급되며 이더리움 포인트는 USD $ 와 1:1 대응하여 회원님의 외부 이더리움 지갑으로 출금이 가능합니다. 출금 시 {0} 수수료 공제 후 출금 가능(100p단위로 출금 가능) 합니다.',
      'introduction-challenge-time': '챌린지 시간',
      'introduction-challenge-menual': '챌린지 방법',
      'introduction-challenge-one-hour': '1시간',
      'introduction-how-to-play-p8': '정확한 진입 시점을 연습하고 싶다면 연승 모드에 도전하시길 바랍니다.',
      'introduction-how-to-play-p9': '정해진 시간 동안 손실 없이 연속적으로\n몇 번의 수익을 달성하는지를 평가 받는 챌린지',
      'introduction-how-to-play-p10': 'BTC 시세 예측을 최대 20라운드까지 참가할 수 있습니다.',
      'introduction-how-to-play-p11':
        '20라운드 이내에 다음 라운드 진행을 포기하면 해당 라운드에 정해져있는 보상을 받을 수 있는 모드입니다.',
      'introduction-how-to-play-p12':
        '(*예측 실패시 전에 있던 모든 라운드에서 받을 수 있는 보상은 사라집니다.)\n(*10$ 참가비로 1회 참여 가능합니다.)',
      'introduction-how-to-play-p13': '승률을 높이는 연습을 하고 싶다면 다승 모드에 도전하시길 바랍니다.',
      'introduction-how-to-play-p14': '정해진 시간 동안 몇 번의 수익을\n달성하는지 평가받는 챌린지',
      'introduction-how-to-play-p15':
        'BTC 시세 예측을 최대 20라운드까지 참가할 수 있으며, 중간에 예측이 틀려도 계속 참가할 수 있는 모드입니다.',
      'introduction-how-to-play-p16': '20라운드 중에 몇번이나 예측을 성공했는지에 따라서 보상이 주어집니다.',
      'introduction-how-to-play-p17': '(*10$ 참가비로 20회 참여 가능합니다.)',
      'introduction-how-to-play-p18': '자신이 선택한 BTC 시세보다 라운드 종료시점에 시세가 높을것 같으면 선택',
      'introduction-how-to-play-p19': '자신이 선택한 BTC 시세보다 라운드 종료시점에 시세가 낮을 것 같으면 선택',
      'introduction-how-to-play-rewards-title': '보상 포인트 지급 제도',
      'introduction-how-to-trade-title': '[MAX4 교육시스템 입/출금 방법]',
      'introduction-go-to-challenge': '챌린지 참여하기',
      'introduction-deposit': '입금',
      'introduction-withdrawal': '출금',
      'introduction-how-to-trade-p1':
        'Max4 교육시스템 연승 및 다승게임에서 2단계 챌린지모드에서 사용 가능한 포인트는 이더리움으로 충전이 가능합니다.\n다른 단계에서는 포인트 충전을 할 수 없으며, 오직 2단계 챌린지모드에서만 사용이 가능한 포인트로써 충전이 가능합니다.',
      'introduction-how-to-trade-p2':
        'Max4 교육시스템에서 받은 보상금은 오직 3단계 라이브모드 참여를 통해 얻은 보상만이 외부로 출금이 가능하며, 보상을 이더리움으로 변환하여 회원지갑으로 출금이 가능합니다.\n\n출금시 회원: {0} Max4: {1}의 비율로 수수료를 제외하고 출금이 됩니다.',
      // Main
      'main-banner-p1': '나의 트레이딩 실력은?',
      'main-banner-p2': '트레이딩을 하기 전 실력을 평가받자',
      'main-banner-p3': '디지털 트레이딩 교육 플랫폼',
      'main-participate-p1': '챌린지 참여',
      'main-participate-p2': '비트코인 투자를 연습해보세요.\n게임처럼 쉽고 재미있게 배우는 트레이딩 기법',
      'main-introduction-p1': '교육 시스템 설명',
      'main-introduction-p2': '누구나 처음하는 비트코인은 어렵기 마련',
      'main-introduction-p3': '챌린지 소개',
      // 마이페이지
      'mypage-check-password': '비밀번호를 올바르게 입력했는지 확인해주세요.',
      'mypage-enter-title': '제목을 입력하세요!',
      'mypage-enter-content': '내용을 입력하세요!',
      'mypage-enquiry-etc': '기타문의',
      'mypage-change-password': '비밀번호 변경',
      'mypage-input-password': '비밀번호를 입력하세요.',
      'mypage-password': '비밀번호',
      'mypage-new-password': '새 비밀번호',
      'mypage-input-new-password': '새로운 비밀번호를 입력하세요.',
      'mypage-confirm-password': '비밀번호 확인',
      'mypage-confirm-change': '변경하기',
      'mypage-title': '마이페이지',
      'mypage-menu-info': '회원정보',
      'mypage-menu-wallet': '지갑관리',
      'mypage-menu-contact': '문의하기',
      'mypage-info-username-label': '아이디',
      'mypage-info-email-label': '이메일',
      'mypage-info-phone-label': '휴대폰 번호',
      'mypage-info-recommender-label': '추천인',
      'mypage-info-recommendees-label': '내가 추천한 유저',
      'mypage-info-no-recommender-label': '없음',
      'mypage-info-recommendees-unit': '명',
      'mypage-recommend-link': '내 추천링크',
      'mypage-alert-phone-verification-required': '휴대폰 인증을 위해 해당 페이지로 이동합니다.',
      'mypage-wallet-title': '내 지갑 관리',
      'mypage-wallet-my-point': '내 포인트',
      'mypage-wallet-p1': '*이더리움으로 충전 가능',
      'mypage-wallet-p2': '*이더리움으로 환전하여 출금 가능',
      'mypage-withdraw': '출금하기',
      'mypage-wallet-p3': '출금 가능 이더리움 포인트',
      'mypage-wallet-p4': '*최소 100$부터 환전 가능합니다.',
      'mypage-wallet-p5': '*환전 시 {0}의 수수료가 발생합니다.',
      'mypage-fees': '수수료',
      'mypage-wallet-p6': '총 출금 수량 (수수료 포함)',
      'mypage-wallet-p7': '* 출금시 참고사항',
      'mypage-wallet-p8':
        '- 이더리움 포인트 출금시 USD 달러와 1:1 대응되어 수수료를 제외한 금액만큼 관리자의 승인 후 이더리움으로 환전되어 내 지갑 주소로 입금됩니다.',
      'mypage-request-withdrawal': '출금 신청',
      'mypage-wallet-p9': '내 입출금 지갑 관리',
      'mypage-wallet-label': '지갑주소',
      'mypage-change-wallet': '지갑주소 변경',
      'mypage-wallet-p10': '누적 총 출금 금액',
      'mypage-wallet-p11': '토너먼트 포인트 충전용 이더리움 입금주소',
      'mypage-wallet-p12': '* 입금 주소를 잘못 입력하여 전송한 경우 되찾을 수 없습니다!',
      'mypage-trade-ratio': '교환비',
      'mypage-wallet-p13':
        '*이더리움 가격은 거래소 기준으로 실시간으로 반영되며\n포인트 교환은 전송 된 시점의 이더리움 가격으로 결정됩니다.',
      'mypage-title-label': '제목',
      'mypage-content-label': '내용',
      'mypage-input-title': '제목을 입력해주세요.',
      'mypage-input-content': '내용을 입력해주세요.',
      'mypage-wallet-p14': '입출금 이더리움지갑주소 변경',
      'mypage-wallet-p15': '변경할 이더리움 지갑주소',
      'mypage-wallet-p16': '입출금에 사용할 이더리움 지갑주소를 입력해주세요.',
      'mypage-wallet-p17': '* 지갑주소 입력시 주의사항',
      'mypage-wallet-p18':
        '- 변경된 이더리움 주소는 MAX4 프로그램의 토너먼트 포인트 충전과 보상에 대한 출금을 위해 사용하실 고유주소로써 사용됩니다.',
      'mypage-wallet-p19': '- 회원님이 사용하실 입출금 이더리움 지갑 주소가 맞는지 반드시 확인해주시기 바랍니다. ',
      // 로그인
      'signin-back': '이전 화면으로 돌아가기',
      'signin-input-id': '아이디를 입력하세요.',
      'signin-not-user': '회원이 아니신가요?',
      'signin-title': '회원 서비스 이용을 위해 로그인이 필요합니다.',
      'signin-email-placeholder': '이메일',
      'signin-pw-placeholder': '비밀번호',
      'signin-button-text': '로그인',
      'signin-signin-signup-label': '회원이 아니신가요?',
      'signin-signin-signup-text': '회원가입',
      'signin-signin-lostandfound-label': '아이디 혹은 비밀번호를 잊으셨나요?',
      'signin-signin-lostandfound-text': '아이디 / 비밀번호 찾기',
      'signin-alert-email-pw-required': '이메일과 비밀번호가 필요합니다.',
      'signin-alert-failed': '아이디 또는 비밀번호가 틀립니다.',
      'signin-alert-email-verification-required': '이메일 인증이 아직 완료되지 않았습니다. 새로 발송된 인증 메일을 확인해주세요.',
      'signin-alert-phone-verification-required': '휴대폰 인증을 위해 해당 페이지로 이동합니다.',
      'signin-email-conflict': '이미 사용중인 이메일 주소입니다.',
      'signin-edit-email-success': '이메일 주소 변경 완료!',
      'signin-verify-email': '이메일을 인증해주세요.',
      'signin-verify-email-description': '회원가입에 입력하신 이메일 주소로 인증 메일이 발송되었습니다. 인증 메일 확인 후 로그인 해주세요.',
      'signin-input-new-email': '새로운 이메일 주소',
      'signin-change-email': '이메일 변경',
      'signin-resend-email-title': '이메일이 오지 않으셨나요?',
      'signin-resend-email-success': '이메일을 재전송했습니다!',
      'signin-resend-label': '재전송',
      'signin-change-email-label': '다른 이메일로 시도',
      // 회원가입
      'signup-title': '회원가입',
      'signup-id': '아이디',
      'signup-email': '이메일',
      'signup-pw': '비밀번호',
      'signup-pw-confirm': '비밀번호 확인',
      'signup-phone': '전화번호',
      'signup-recommender': '추천인',
      'signup-wallet': '회원 전용 이더리움 지갑 주소',
      'signup-button-text': '회원가입',
      'signup-input-wallet': '입금에 사용할 이더리움 지갑주소를 입력해주세요.',
      'signup-input-recommender': '추천인(아이디를 입력하세요.)',
      'signup-alert-id-required': '아이디를 입력해주세요.',
      'signup-alert-id-confirm-required': '아이디 중복확인을 해주세요.',
      'signup-alert-email-required': '이메일을 입력해주세요.',
      'signup-alert-pw-required': '비밀번호를 입력해주세요.',
      'signup-alert-pw-confirm-required': '비밀번호 확인을 입력해주세요.',
      'signup-alert-phone-required': '휴대폰 번호를 입력해주세요.',
      'signup-alert-invalid-email': '올바른 이메일이 아닙니다.',
      'signup-alert-pw-too-short': '비밀번호는 최소 6자 이상이어야 합니다.',
      'signup-alert-pw-too-long': '비밀번호는 72자를 넘을 수 없습니다.',
      'signup-alert-pw-not-match': '비밀번호와 비밀번호 확인이 일치하지 않습니다.',
      'signup-alert-invalid-phone': '올바른 휴대폰 번호가 아닙니다. 휴대폰 번호는 국가 코드를 포함해야합니다.',
      'signup-alert-conflict': '이미 사용중인 닉네임, 이메일 또는 전화번호입니다.',
      'signup-alert-bad-recommendor': '잘못된 추천인 아이디입니다.',
      'signup-alert-successful': '가입해주셔서 감사합니다. 로그인 전에 발송된 메일을 확인해주세요.',
      'signup-alert-recommender-not-exists': '잘못된 추천인 아이디입니다.',
      'signup-alert-wallet-address-required': '지갑주소를 입력해주세요.',
      'signup-id-available': '사용 가능한 아이디입니다.',
      'signup-id-not-available': '이미 사용중인 아이디입니다.',
      'signup-email-available': '사용 가능한 이메일 주소입니다.',
      'signup-email-not-available': '이미 사용중인 이메일 주소입니다.',
      'signup-phone-available': '사용 가능한 핸드폰 번호입니다.',
      'signup-phone-not-available': '이미 사용중인 핸드폰 번호입니다.',
      'signup-asterisk-required': '* 표시된 곳은 필수로 입력되어야 합니다.',
      'signup-check-duplicates': '중복확인',
      'signup-password-conditions': '영문 및 숫자를 포함한 8자 이상의 조합이어야 합니다.',
      'signup-confirm-password-placeholder': '확인을 위해 비밀번호를 한번 더 입력해주세요.',
      'signup-wallet-p1': '* 지갑 주소 입력시 주의사항',
      'signup-wallet-p2':
        '- 회원가입시 입력된 회원님의 이더리움 주소는 회원 탈퇴시까지 고정되어 변경이 불가능한 주소로, 회원님이 MAX4 프로그램의 토너먼트 포인트 충전을 위해 사용하실 고유 주소로써 사용됩니다.',
      'signup-wallet-p3': '- 회원님이 사용하실 입금주소가 맞는지 반드시 확인해주시고 입력하여 회원가입을 진행해주시기 바랍니다.',
      // 기타
      'cannot-copy': '복사하기가 지원되지 않는 브라우저입니다.',
      'copy-success': '복사 성공!',
      'copy-label': '복사하기',
      'cancel-label': '취소',
      'complete-label': '완료',
      'confirm-label': '확인',
      // 용어
      'demo-point': '데모 포인트',
      'tournament-point': '토너먼트 포인트',
      'live-point': '라이브 포인트',
      'eth-point': '이더리움 포인트',
      'demo-challenge': '데모 챌린지',
      'tournament-challenge': '토너먼트 챌린지',
      'live-challenge': '라이브 챌린지',
      'continuous-mode': '연승 모드',
      'fixed-mode': '다승 모드',
      // 추가
      'mypage-check-new-wallet': '올바르지 않은 지갑 주소입니다.',
      'mypage-edit-wallet-success': '지갑 주소 변경 성공!',
      'send-enquiry-title': '1대1 문의 작성',
      'send-enquiry-is-private-label': '비밀 글',
      'send-enquiry-send-label': '업로드',
      // 추가 2
      'challenges-continuous-rewards': '연승 상금',
      // 추가 2021-08-11
      'Introduction-description-demo-point':
        '회원가입 혹은 회원을 추천하였을때 \n주어지는 포인트 입니다. \n해당 포인트로 데모챌린지를 참여할 수 있습니다.',
      'Introduction-description-tournament-point':
        '데모챌린지에서 승리하였을때 주어지는 보상이며, \n이더리움으로 충전이 가능하며, \n해당 포인트로 토너먼트 챌린지 참여가 가능합니다.',
      'Introduction-description-live-point':
        '토너먼트 챌린지에서 승리하였을때 주어지는 보상이며, \n해당 포인트로 라이브 챌린지 참여가 가능합니다.',
      'Introduction-description-eth-point':
        '라이브 챌린지에서 승리하였을때 주어지는 보상이며\n해당 포인트는 달러와 1:1 대응되어\n이더리움으로 출금이 가능한 포인트 입니다.',
      'Introduction-description-eth-point2': '(* 출금 수수료 50% 적용)',
      'mypage-alert-insufficient-expoint': '100 포인트 이상부터 출금 가능합니다!',
      'mypage-alert-insufficient-pointd': '가지고있는 이더리움 포인트가 부족합니다!',
      'id-find-email-sent': '아이디가 이메일로 전송되었습니다!',
      'pw-find-email-sent': '비밀번호 재설정 링크가 이메일로 전송되었습니다!',
      'Challenge-my-points': '보유 포인트',
    },
  },
  'en-US': {
    translation: {
      'main-menu-challenge-info': 'Challenge Introduce',
      'main-menu-challenge-participate': 'Join Challenge',
      'main-menu-customercare': 'Customer Center',
      'main-menu-notice': 'Announcement',
      'main-menu-signup': 'Register',
      'main-menu-mypage': 'My page',
      'main-menu-signin': 'Sign in',
      'main-menu-signout': 'Sign out',
      'verify-email-label': 'Authenticating. Please wait.',
      'verify-email-alert-failed': 'Expired. Please sign in to receive a new confirmation email.',
      'verify-email-alert-success': 'Certified.',
      'verify-phone-input-placeholder': 'Code',
      'verify-phone-resend-text': 'Send message',
      'verify-phone-verify-text': 'Certification',
      'verify-phone-alert-invalid-code': 'This is not correct code.',
      'verify-phone-alert-resent': 'Has been sent.',
      'verify-phone-alert-success': 'Certified.',
      // 챌린지
      'challenge-login-first': 'Please try again after sign in!',
      'challenge-round-lose': 'Round Lose...',
      'challenge-round-win': 'Round Win!',
      'challenge-already-end': 'This challenge is already over!',
      'challenge-continuous-description':
        'Reward you a prize based on how much you stacking up the winning streak, without losing. Goes for an hour',
      'challenge-fixed-description': 'Reward you a prize based on how much wins you made no matter your lose. Goes for an hour',
      'challenge-settings': 'Challenge Settings',
      'challenge-mode': 'Challenge mode',
      'challenge-uses-point': 'Used point',
      'challenge-previous': 'Back',
      'challenge-next': 'Next',
      'challenge-remain-time': 'Estimate Time',
      'challenge-tooltip-price': 'Price',
      'challenge-tooltip-time': 'Time',
      'challenge-round': 'Round',
      'challenge-remaining-time-to-betting': 'Time left to bet',
      'challenge-remaining-time-to-round': 'Round time remaining',
      'challenge-win': 'Win',
      'challenge-lose': 'Lose',
      'challenge-straight-win': 'Consecutive wins',
      'challenge-toast-bet-long': 'Betting on long!',
      'challenge-toast-bet-short': 'Bettin on short!',
      'challenge-alert-not-enough-point': 'Betting failed! Please check if there are enough points!',
      'challenge-stop-challenge': 'STOP / Get Prize',
      'challenge-rewards': 'Reward for victory',
      'challenge-total-rewards-label': 'Prize',
      'challenge-end-normal': 'Challenge End',
      'challenge-end-expired': 'Timeout',
      'challenge-end-lose': 'Lose',
      'challenge-result-stage': 'Level',
      'challenge-result-your-records': 'Your Record…',
      'challenge-result-total-points': 'Point Acquired',
      'challenge-point': 'Point',
      'challenge-result-confirm': 'Done',
      // 고객 센터
      'service-faq': 'Frequently Asked',
      'service-author': 'Author',
      'service-date': 'Post date',
      'service-center': 'Announcement',
      'service-send-enquiry': '1:1 Inquiry',
      'service-category': 'Category',
      'service-title': 'Title',
      'service-previous': 'Previos Post',
      'service-next': 'Next Post',
      // Footer
      'footer-support': 'Inquiry',
      // 소개 페이지
      'introduction-introduction': 'Introducing MAX4 Challenge',
      'introduction-how-to-play': 'How to use challenge',
      'introduction-introduction-p1':
        'Unlike the conventional mock or live contest evaluating based on yield, MAX4 a digital trading education platform developed for training professional trader and trading lecturer aims for increasing the winning rate to get stable investment return.',
      'introduction-introduction-t1': 'Improving high frequency trading skill(Scalping)',
      'introduction-introduction-t2': 'Evaluate trading skill',
      'introduction-introduction-t3': 'Reward Future Trading Point based on skill',
      'introduction-introduction-d1':
        'With expecting market condition of BTC and trading them by high-low option trading with strong volatility, Trader gets the strategy of expecting accurate trading enter point and improve the skill of high frequency trading(Scalping)',
      'introduction-introduction-d2':
        'Investment’s changes by the epoch, you can get your trading skill get evaluated with objective perspective, preventing the loss from indiscriminate investment.',
      'introduction-introduction-d3':
        'Even without any money, if you have enough skills you can trade with the rewarded future trading point by participating challenge.',
      'introduction-how-to-trade': 'How to do a transaction',
      'introduction-how-to-play-p1':
        'MAX4, a platform to get the one’s trading skill be evaluated in objective perspective, has Demo Challenge, Tournament Challenge, Live Challenge, and there are two Challenging method of Consecutive wins mode and Multi wins mode. \n\nThere’s an hour of trading time in MAX4 challenge and one single stage goes for a minute, at first 30 seconds you can select Long(Price increases) or Short(Price decreases) with expecting how would a market condition of BTC will be change to.',
      'introduction-how-to-play-p2':
        '10$ of Demo Point is given free with registering and can be used on Demo Challenge. It used to earn the Tournament Point which can be used on Tournament Challenge.',
      'introduction-how-to-play-p3':
        'How to join: Receive 10$ of Demo Point which can be used on Demo Challenge, via Registering or member invitation.',
      'introduction-how-to-play-p4':
        'You can trade with 10$ of Tournament Point. It used to earn the Live Point which can be used on Live Challenege.',
      'introduction-how-to-play-p5': 'How to join: Pay 10$ of Tournament Point or charge Tournament Point with Etherium.',
      'introduction-how-to-play-p6': 'You can participate Live Trading with Live Point you’ve earned via Tournament Challenge.',
      'introduction-how-to-play-p7':
        'How to Trade: You can trade with 10P of Live Point, The reward on the challenge is given with Etherium Point. Etherium Point is correspond to USD $ and can be withdrawn to your external Etherium Wallet. On withdrawal there’s {0} of fee charged. (You can withdraw starting from 100p)',
      'introduction-challenge-time': 'Challenge Time',
      'introduction-challenge-menual': 'How to Challenge',
      'introduction-challenge-one-hour': '1 Hour',
      'introduction-how-to-play-p8': 'Challenge to Consecutive wins mode if you want to practice the accurate approach point.',
      'introduction-how-to-play-p9': 'A challenge evaluating how many winning streaks are made without making any loss in given time',
      'introduction-how-to-play-p10': 'You can do a BTC market price expectation maximum 20 rounds.',
      'introduction-how-to-play-p11': 'If you give up continue to next round in 20 rounds you can receive the current round’s reward.',
      'introduction-how-to-play-p12':
        '(* If you failed to expect your all reward got from past rounds become invalid.) \n(* You can participate once with 10$ of joining fee)',
      'introduction-how-to-play-p13': 'Challenge to Multi wins mode if you want to make your winning rate higher.',
      'introduction-how-to-play-p14': 'A challenge evaluating how many wins are made.',
      'introduction-how-to-play-p15':
        'You can do a BTC market price expectation maximum 20 rounds, you can still keep joining if you made wrong expect during the challenge',
      'introduction-how-to-play-p16': 'Rewards are given how many successful expectation was made in 20 rounds.',
      'introduction-how-to-play-p17': '(*You can participate 20 times with 10$ of joining fee)',
      'introduction-how-to-play-p18': 'Select if the BTC price is expected to increase.',
      'introduction-how-to-play-p19': 'Select if the BTC price is expected to decrease.',
      'introduction-how-to-play-rewards-title': 'Rewarding Rule of Challenge',
      'introduction-how-to-trade-title': '[MAX4 Education System How to do a transaction]',
      'introduction-go-to-challenge': 'Join Challenge',
      'introduction-deposit': 'Deposit',
      'introduction-withdrawal': 'Withdraw',
      'introduction-how-to-trade-p1':
        'On MAX4 Education System’s Challenges, A point to use on Level 2 Challenge Mode can be charged with Etherium.\nYou can’t charge on other Levels, You can only charge a point to use, on Level 2 Challenge Mode.',
      'introduction-how-to-trade-p2':
        'A reward from MAX4 Education System only can be withdrawn by participating Level 3 Live Mode. You can get your reward as Etherium, in your account’s wallet. \n\nOn withdrawal, there’s a withdrawal fee which is ratio of 50%.',
      // Main
      'main-banner-p1': 'How am I good at trading?',
      'main-banner-p2': 'Get evaluated before trading.',
      'main-banner-p3': 'Digital trading education platform',
      'main-participate-p1': 'Join Challenge',
      'main-participate-p2': 'Practice the invest on Bitcoin. \nLearn Trading Skill easy & enjoyable like a game.',
      'main-introduction-p1': 'Education system explanation',
      'main-introduction-p2': 'First try of Bitcoin can’t be easy for anyone',
      'main-introduction-p3': 'Challenge Introduce',
      // 마이페이지
      'mypage-check-password': 'Check if the password is entered correctly.',
      'mypage-enter-title': 'Enter a title!',
      'mypage-enter-content': 'Enter the contents!',
      'mypage-enquiry-etc': 'Other inquiries',
      'mypage-change-password': 'Change Password',
      'mypage-input-password': 'Put your Password.',
      'mypage-password': 'Password',
      'mypage-new-password': 'New password',
      'mypage-input-new-password': 'Put your new password.',
      'mypage-confirm-password': 'Check Password.',
      'mypage-confirm-change': 'Change',
      'mypage-title': 'My page',
      'mypage-menu-info': 'Member info',
      'mypage-menu-wallet': 'Manage Wallet',
      'mypage-menu-contact': 'Make Inquiry',
      'mypage-info-username-label': 'ID',
      'mypage-info-email-label': 'E-mail',
      'mypage-info-phone-label': 'Phone Number',
      'mypage-info-recommender-label': 'Invitee',
      'mypage-info-recommendees-label': 'Invited by Me',
      'mypage-info-no-recommender-label': 'None',
      'mypage-info-recommendees-unit': 'People',
      'mypage-recommend-link': 'My Invitation Link',
      'mypage-alert-phone-verification-required': 'Go to the appropriate page for mobile phone authentication.',
      'mypage-wallet-title': 'Manage My Wallet',
      'mypage-wallet-my-point': 'My Point',
      'mypage-wallet-p1': '*Can be charged with Etherium',
      'mypage-wallet-p2': '*Can be exchanged to Etherium and withdrawable',
      'mypage-withdraw': 'Withdraw',
      'mypage-wallet-p3': 'Withdrawable Etherium Point',
      'mypage-wallet-p4': '*Exchange can be done starting from minimum 100$.',
      'mypage-wallet-p5': '*50% of fee will be charged on exchange.',
      'mypage-fees': 'Fee',
      'mypage-wallet-p6': 'Total withdraw (including fee)',
      'mypage-wallet-p7': '* Check when withdrawing',
      'mypage-wallet-p8':
        '- If you withdraw Etherium Point it’s corresponding with USD dollar. After supervisors accept, you’ll get the Etherium excluding fee, and will be deposited on your wallet address',
      'mypage-request-withdrawal': 'Withdrawal',
      'mypage-wallet-p9': 'Manage my transaction wallet',
      'mypage-wallet-label': 'Wallet address',
      'mypage-change-wallet': 'Change Wallet Address',
      'mypage-wallet-p10': 'Total withdrawal.',
      'mypage-wallet-p11': 'Etherium Deposit Address for Tournament Point charge',
      'mypage-wallet-p12': '* You can’t get your withdrawal return if you put the wrong address!',
      'mypage-trade-ratio': 'Exchange rate',
      'mypage-wallet-p13':
        '*The Etherium price is affected by exchange center. Point exchanging is done with a real-time market price of Etherium.',
      'mypage-title-label': 'Title',
      'mypage-content-label': 'Contents',
      'mypage-input-title': 'Please enter a title.',
      'mypage-input-content': 'Please enter the contents.',
      'mypage-wallet-p14': 'Edit Transaction Etherium wallet address.',
      'mypage-wallet-p15': 'New Etherium Address',
      'mypage-wallet-p16': 'Put the Etherium Address for transaction',
      'mypage-wallet-p17': '* Caution on wallet address',
      'mypage-wallet-p18':
        '- New Etherium address is used for MAX4 Program’s Tournament Point charge and withdrawal for obtaining your challenge reward.',
      'mypage-wallet-p19': '- Make sure the wallet address is correct. ',
      // 로그인
      'signin-back': 'Go Back',
      'signin-input-id': 'Put your ID.',
      'signin-not-user': 'Still not a member?',
      'signin-title': 'You need to sign in to use the member service.',
      'signin-email-placeholder': 'E-mail',
      'signin-pw-placeholder': 'Password',
      'signin-button-text': 'Sign In',
      'signin-signin-signup-label': 'Still not a member?',
      'signin-signin-signup-text': 'egister',
      'signin-signin-lostandfound-label': 'Lost your ID / PW?',
      'signin-signin-lostandfound-text': 'Find my ID / PW',
      'signin-alert-email-pw-required': 'Need ID / PW',
      'signin-alert-failed': 'ID or PW is incorrect.',
      'signin-alert-email-verification-required':
        'Email authentication is not completed yet. Please check the newly sent authentication mail.',
      'signin-alert-phone-verification-required': 'Go to the appropriate page for mobile phone authentication.',
      'signin-email-conflict': 'This email address is already in use.',
      'signin-edit-email-success': 'E-mail change complete!',
      'signin-verify-email': 'Confirm your E-mail.',
      'signin-verify-email-description':
        'A verification mail has been sent to the email address you entered for registration. Please sign in after checking the authentication mail..',
      'signin-input-new-email': 'New E-mail',
      'signin-change-email': 'Change E-mail',
      'signin-resend-email-title': "Didn't you get an email??",
      'signin-resend-email-success': 'Your email has been resend!',
      'signin-resend-label': 'Re-send',
      'signin-change-email-label': 'Try with another address.',
      // 회원가입
      'signup-title': 'Register',
      'signup-id': 'ID',
      'signup-email': 'E-mail',
      'signup-pw': 'Password',
      'signup-pw-confirm': 'Check Password',
      'signup-phone': 'Phone Number',
      'signup-recommender': 'invitee',
      'signup-wallet': 'Members only ETH Wallet address',
      'signup-button-text': 'Register',
      'signup-input-wallet': 'Put the ETH Wallet address will be used on transaction.',
      'signup-input-recommender': 'invitee(Put the ID.)',
      'signup-alert-id-required': 'Put your ID',
      'signup-alert-id-confirm-required': 'Confirm your ID.',
      'signup-alert-email-required': 'Put your E-mail.',
      'signup-alert-pw-required': 'Put your password.',
      'signup-alert-pw-confirm-required': 'Put your password verification.',
      'signup-alert-phone-required': 'Put your phone number.',
      'signup-alert-invalid-email': 'Not valid email.',
      'signup-alert-pw-too-short': 'Password must be at least 6 characters long.',
      'signup-alert-pw-too-long': 'Password cannot exceed 72 characters.',
      'signup-alert-pw-not-match': 'Password and password verification do not match.',
      'signup-alert-invalid-phone': 'Not valid phone number. phone number must contain country code.',
      'signup-alert-conflict': 'Already registered nickname, email, or phone number.',
      'signup-alert-bad-recommendor': 'Invalid invitee ID',
      'signup-alert-successful': 'Thank you for signing up. Please check the mail sent before sign in.',
      'signup-alert-recommender-not-exists': 'Invalid invitee ID',
      'signup-alert-wallet-address-required': 'Put the ETH Wallet address.',
      'signup-id-available': 'Available ID.',
      'signup-id-not-available': 'Already registered ID.',
      'signup-email-available': 'Available E-mail.',
      'signup-email-not-available': 'Already registered E-mail.',
      'signup-phone-available': 'Available phone number.',
      'signup-phone-not-available': 'Already registered phone number.',
      'signup-asterisk-required': 'The fields marked with “*” should be filled.',
      'signup-check-duplicates': 'Verify',
      'signup-password-conditions': 'Password should be longer than 8 characters, including alphabets and numbers',
      'signup-confirm-password-placeholder': 'Put the password again to confirm it.',
      'signup-wallet-p1': '* Cautions on handling your wallet',
      'signup-wallet-p2':
        '- Your ETH wallet will be used on reloading tournament point. After register, your ETH wallet address will be permanently fixed to account until you resign.',
      'signup-wallet-p3': '- Make sure the address you put is correct.',
      // 기타
      'cannot-copy': 'This browser is not supported for copying.',
      'copy-success': 'Copy succeeded!',
      'copy-label': 'Copying',
      'cancel-label': 'cancel',
      'complete-label': 'Done',
      'confirm-label': 'OK',
      // 용어
      'demo-point': 'Demo Point',
      'tournament-point': 'Tournament Point',
      'live-point': 'Live Point',
      'eth-point': 'Etherium Point',
      'demo-challenge': 'Demo Challenge',
      'tournament-challenge': 'Tournament Challenge',
      'live-challenge': 'Live Challenge',
      'continuous-mode': 'Consecutive wins mode',
      'fixed-mode': 'Multi wins mode',
      // 추가
      'mypage-check-new-wallet': 'Invalid wallet address!',
      'mypage-edit-wallet-success': 'Wallet address change successful!',
      'send-enquiry-title': 'Post 1:1 Inquiry',
      'send-enquiry-is-private-label': 'Secret Post',
      'send-enquiry-send-label': 'Upload',
    },
  },
  vi: {
    translation: {
      'main-menu-challenge-info': 'Giới thiệu thử thách',
      'main-menu-challenge-participate': 'Tham gia thử thách',
      'main-menu-customercare': 'Trung tâm khách hàng',
      'main-menu-notice': 'Thông báo',
      'main-menu-signup': 'Đăng ký',
      'main-menu-mypage': 'trang của tôi',
      'main-menu-signin': 'Đăng nhập',
      'main-menu-signout': 'Đăng xuất',
      'verify-email-label': 'Đang xác thực. Xin vui lòng chờ đợi.',
      'verify-email-alert-failed': 'Đã hết hạn. Vui lòng đăng nhập để nhận email xác minh mới.',
      'verify-email-alert-success': 'Đã được chứng nhận.',
      'verify-phone-input-placeholder': 'mã',
      'verify-phone-resend-text': 'gửi tin nhắn',
      'verify-phone-verify-text': 'chứng nhận',
      'verify-phone-alert-invalid-code': 'Đây không phải là mã chính xác.',
      'verify-phone-alert-resent': 'đã được gửi đi.',
      'verify-phone-alert-success': 'Đã được chứng nhận.',
      // 챌린지
      'challenge-login-first': 'vui lòng thử lại sau khi đăng nhập!',
      'challenge-round-lose': 'thua vòng...',
      'challenge-round-win': 'vòng chiến thắng!',
      'challenge-already-end': 'Thử thách đã kết thúc!',
      'challenge-continuous-description':
        'Thưởng cho bạn phần thưởng dựa trên số tiền bạn cộng dồn chuỗi chiến thắng mà không bị thua, trong thời gian một giờ.',
      'challenge-fixed-description':
        'Thưởng cho bạn một giải thưởng dựa trên số trận thắng bạn đã thực hiện bất kể bạn bị thua cuộc, trong thời gian một giờ.',
      'challenge-settings': 'Cài đặt thử thách',
      'challenge-mode': 'Chế độ thử thách',
      'challenge-uses-point': 'điểm đã sử dụng',
      'challenge-previous': 'trước',
      'challenge-next': 'kế tiếp',
      'challenge-remain-time': 'thời gian giới hạn',
      'challenge-tooltip-price': 'giá bán',
      'challenge-tooltip-time': 'thời gian',
      'challenge-round': 'Vòng',
      'challenge-remaining-time-to-betting': 'Thời gian còn lại',
      'challenge-remaining-time-to-round': 'thời gian còn lại để làm tròn',
      'challenge-win': 'Thắng',
      'challenge-lose': 'Thua',
      'challenge-straight-win': 'Thắng tiếp',
      'challenge-toast-bet-long': 'đặt cược vào dài!',
      'challenge-toast-bet-short': 'đặt cược vào ngắn hạn!',
      'challenge-alert-not-enough-point': 'Đặt cược không thành công! Hãy chắc chắn rằng bạn có đủ điểm!',
      'challenge-stop-challenge': 'STOP / Nhận giải thưởng',
      'challenge-rewards': 'Phần thưởng chiến thắng',
      'challenge-total-rewards-label': 'Giải thưởng',
      'challenge-end-normal': 'Thử thách kết thúc',
      'challenge-end-expired': 'hết giờ',
      'challenge-end-lose': 'Thua',
      'challenge-result-stage': 'Cấp độ',
      'challenge-result-your-records': 'Bản ghi của bạn…',
      'challenge-result-total-points': 'Điểm nhận được',
      'challenge-point': 'Điểm',
      'challenge-result-confirm': 'Hoàn thành',
      // 고객 센터
      'service-faq': 'Câu hỏi thường gặp',
      'service-author': 'Tác giả',
      'service-date': 'Ngày gửi',
      'service-center': 'Trung tâm khách hàng',
      'service-send-enquiry': 'Yêu cầu 1:1',
      'service-category': 'Danh mục',
      'service-title': 'Tiêu đề',
      'service-previous': 'Bài trước',
      'service-next': 'Bài tiếp theo',
      // Footer
      'footer-support': 'Yêu cầu',
      // 소개 페이지
      'introduction-introduction': 'Giới thiệu Thử thách MAX4',
      'introduction-how-to-play': 'Cách chơi thử thách',
      'introduction-introduction-p1':
        'Không giống như cuộc thi mô phỏng hoặc cuộc thi trực tiếp thông thường đánh giá dựa trên lợi nhuận, MAX4 một nền tảng giáo dục giao dịch kỹ thuật số được phát triển để đào tạo nhà giao dịch chuyên nghiệp và giảng viên giao dịch nhằm mục đích tăng tỷ lệ chiến thắng để có được lợi nhuận đầu tư ổn định. ',
      'introduction-introduction-t1': 'Cải thiện kỹ năng giao dịch tần suất cao (Scalping)',
      'introduction-introduction-t2': 'Đánh giá kỹ năng giao dịch',
      'introduction-introduction-t3': 'Điểm giao dịch dựa trên kỹ năng',
      'introduction-introduction-d1':
        'Với việc mong đợi điều kiện thị trường của BTC và giao dịch chúng bằng giao dịch quyền chọn cao-thấp với sự biến động mạnh, Nhà giao dịch có được chiến lược mong đợi điểm nhập giao dịch chính xác và cải thiện kỹ năng giao dịch tần suất cao (Scalping).',
      'introduction-introduction-d2':
        'Kỷ nguyên của Đầu tư thay đổi & phát triển, bạn có thể ngăn ngừa tổn thất do rủi ro đầu tư bằng cách đa dạng hóa các mục điểm đầu tư và bằng cách đánh giá khách quan các kỹ năng giao dịch của mình trước khi đầu tư.',
      'introduction-introduction-d3':
        'Ngay cả khi không có tiền, nếu bạn có đủ kỹ năng, bạn có thể giao dịch với điểm giao dịch được thưởng trong tương lai bằng cách tham gia thử thách.',
      'introduction-how-to-trade': '입/출금 방법',
      'introduction-how-to-play-p1':
        'MAX4, một nền tảng để đánh giá kỹ năng giao dịch của một người ở góc độ khách quan, có Thử thách Demo, Thử thách Giải đấu, Thử thách Trực tiếp và có hai phương pháp Thử thách là Chế độ Chuỗi chiến thắng và Chế độ đếm chiến thắng. \n\nCó một giờ thời gian giao dịch trong thử thách MAX4 và một giai đoạn duy nhất diễn ra trong một phút, ở 30 giây đầu tiên, bạn có thể chọn Long(Giá tăng) hoặc Shot(Giá giảm) với mong đợi điều kiện thị trường của BTC sẽ thay đổi như thế nào.',
      'introduction-how-to-play-p2':
        '10$ của Demo Point được tặng miễn phí khi đăng ký và có thể được sử dụng trong Thử thách Demo. Nó được sử dụng để kiếm Điểm giải đấu, điểm này có thể được sử dụng trong Thử thách giải đấu.',
      'introduction-how-to-play-p3':
        'Cách tham gia: Nhận 10 $ Demo Point có thể được sử dụng trong Thử thách Demo, thông qua Đăng ký hoặc lời mời từ thành viên.',
      'introduction-how-to-play-p4':
        'Bạn có thể giao dịch với 10$ của Điểm giải đấu. Nó được sử dụng để kiếm Điểm trực tiếp có thể được sử dụng trên Thử thách trực tiếp.',
      'introduction-how-to-play-p5': 'Cách tham gia: Thanh toán 10 đô la Điểm Giải đấu hoặc tính Điểm Giải đấu bằng Ethereum.',
      'introduction-how-to-play-p6':
        'Bạn có thể tham gia Giao dịch trực tiếp với Điểm trực tiếp mà bạn đã kiếm được thông qua Thử thách giải đấu',
      'introduction-how-to-play-p7':
        'Cách thức giao dịch: Bạn có thể giao dịch với 10P Live Point, Phần thưởng khi thử thách được trao bằng Etherium Point. Etherium Point tương ứng với USD $ tỷ lệ 1:1 và có thể được rút vào Ví Etherium bên ngoài của bạn. Khi rút tiền, bạn sẽ phải trả {0} phí. (Bạn có thể rút tiền bắt đầu từ 100p)',
      'introduction-challenge-time': 'Thời gian thử thách',
      'introduction-challenge-menual': 'Cách thức với Thử thách',
      'introduction-challenge-one-hour': '1 giờ',
      'introduction-how-to-play-p8':
        'thử thách với Chế độ Chuỗi chiến thắng(Winning Streak Mode) nếu bạn muốn thực hành điểm tiếp cận chính xác.',
      'introduction-how-to-play-p9':
        'Một thử thách đánh giá có bao nhiêu chuỗi chiến thắng được thực hiện mà không bị thua trong thời gian nhất định',
      'introduction-how-to-play-p10': 'Bạn có thể thực hiện kỳ vọng giá thị trường BTC tối đa 20 vòng',
      'introduction-how-to-play-p11':
        'Nếu bạn bỏ cuộc, ngừng không tiếp tục ở vòng tiếp theo trong 20 vòng hiện tại, bạn có thể nhận được phần thưởng được thiết lập cho vòng đã thắng trước đó.',
      'introduction-how-to-play-p12':
        '(* Nếu bạn dự đoán sai, thì tất cả phần thưởng của bạn nhận được từ các vòng trước đều trở nên không hợp lệ.) \n(* Bạn có thể tham gia một lần với 10 $ phí tham gia)',
      'introduction-how-to-play-p13': 'Thử thách với Chế độ đếm chiến thắng nếu bạn muốn tăng tỷ lệ chiến thắng của mình.',
      'introduction-how-to-play-p14': 'Một thử thách đánh giá có bao nhiêu chiến thắng được thực hiện.',
      'introduction-how-to-play-p15':
        'Bạn có thể thực hiện đoán giá thị trường BTC tối đa 20 vòng, bạn vẫn có thể tiếp tục tham gia nếu bạn thực hiện đoán sai trong thử thách',
      'introduction-how-to-play-p16': 'Phần thưởng được trao cho bao nhiêu giao dịch thành công được thực hiện trong 20 vòng',
      'introduction-how-to-play-p17': '(bạn có thể tham gia 20 lần với 10 $ phí tham gia)',
      'introduction-how-to-play-p18': 'Chọn nếu giá BTC dự kiến sẽ tăng.',
      'introduction-how-to-play-p19': 'Chọn nếu giá BTC dự kiến sẽ giảm.',
      'introduction-how-to-play-rewards-title': 'Quy tắc khen thưởng',
      'introduction-how-to-trade-title': '[Hệ thống đào tạo MAX4 Cách thực hiện giao dịch]',
      'introduction-go-to-challenge': 'Tham gia thử thách',
      'introduction-deposit': 'Nạp tiền',
      'introduction-withdrawal': 'Rút tiền',
      'introduction-how-to-trade-p1':
        'Đối với màn Thử thách của Hệ thống Đào tạo MAX4, điểm có thể được sử dụng trong chế độ thử thách cấp độ 2 và có thể được nạp lại bằng ETH. \nĐiểm không thể được chi trả trong các màn đấu khác và chỉ có thể được sử dụng trong chế độ thử thách cấp độ 2..',
      'introduction-how-to-trade-p2':
        'Phần thưởng nhận được từ hệ thống giáo dục Max4 chỉ có thể được rút từ việc tham gia chế độ trực tiếp giai đoạn 3 và phần thưởng có thể được chuyển đổi sang Etherium và rút vào ví của thành viên. \n\nViệc rút tiền sẽ được thực hiện với tỷ lệ Thành viên: 50%/ Max4: 50%, không bao gồm phí.',
      // Main
      'main-banner-p1': 'Làm thế nào để tôi giỏi trong giao dịch?',
      'main-banner-p2': 'Được đánh giá trước khi giao dịch',
      'main-banner-p3': 'Nền tảng đào tạo giao dịch kỹ thuật số',
      'main-participate-p1': 'Tham gia thử thách',
      'main-participate-p2': 'Thực hành đầu tư vào Bitcoin. \nHọc Kỹ năng Giao dịch dễ dàng và thú vị như một trò chơi.',
      'main-introduction-p1': 'Giải thích hệ thống đào tạo',
      'main-introduction-p2': 'Lần thử Bitcoin đầu tiên không thể dễ dàng đối với bất kỳ ai',
      'main-introduction-p3': 'Giới thiệu thử thách',
      // 마이페이지
      'mypage-check-password': 'Hãy chắc chắn rằng bạn đã nhập mật khẩu của mình một cách chính xác.',
      'mypage-enter-title': 'Đặt tiêu đề ở đây!',
      'mypage-enter-content': 'Nhập nội dung của bạn!',
      'mypage-enquiry-etc': 'Các yêu cầu khác',
      'mypage-change-password': 'Đổi mật khẩu',
      'mypage-input-password': 'Vui lòng nhập mật khẩu.',
      'mypage-password': 'Mật khẩu',
      'mypage-new-password': 'Mật khẩu mới',
      'mypage-input-new-password': 'Vui lòng nhập mật khẩu mới.',
      'mypage-confirm-password': 'Kiểm tra mật khẩu',
      'mypage-confirm-change': 'thay đổi',
      'mypage-title': 'trang của tôi',
      'mypage-menu-info': 'Thông tin thành viên',
      'mypage-menu-wallet': 'Quản lý ví',
      'mypage-menu-contact': 'Thực hiện yêu cầu',
      'mypage-info-username-label': 'ID',
      'mypage-info-email-label': 'E-mail',
      'mypage-info-phone-label': 'số điện thoại',
      'mypage-info-recommender-label': 'Người được mời',
      'mypage-info-recommendees-label': 'Do tôi mời',
      'mypage-info-no-recommender-label': 'không có gì',
      'mypage-info-recommendees-unit': 'người',
      'mypage-recommend-link': 'Liên kết lời mời của tôi',
      'mypage-alert-phone-verification-required': 'Truy cập trang đó để xác minh điện thoại di động của bạn.',
      'mypage-wallet-title': 'Quản lý Ví của tôi',
      'mypage-wallet-my-point': 'Điểm của tôi',
      'mypage-wallet-p1': '* Có thể được tính phí bằng Ethereum',
      'mypage-wallet-p2': '* Có thể đổi sang Etherium và có thể rút được',
      'mypage-withdraw': 'Rút tiền',
      'mypage-wallet-p3': 'Điểm Etherium có thể rút tiền',
      'mypage-wallet-p4': '* Giao dịch có thể được thực hiện bắt đầu từ tối thiểu 100 $.',
      'mypage-wallet-p5': '* 50% phí sẽ được tính khi giao dịch được thực hiện.',
      'mypage-fees': 'Phí',
      'mypage-wallet-p6': 'Tổng số tiền rút (bao gồm phí)',
      'mypage-wallet-p7': '* Kiểm tra khi rút tiền',
      'mypage-wallet-p8':
        '- Nếu bạn rút Etherium Điểm tương ứng với đô la Mỹ. Sau khi người giám sát chấp nhận, bạn sẽ nhận được Etherium, sau loại trừ số phí và sẽ được gửi vào địa chỉ ví của bạn',
      'mypage-request-withdrawal': 'Yêu cầu rút tiền',
      'mypage-wallet-p9': 'Quản lý ví giao dịch của tôi',
      'mypage-wallet-label': 'Địa chỉ ví',
      'mypage-change-wallet': 'Thay đổi địa chỉ ví',
      'mypage-wallet-p10': 'Tổng số tiền rút',
      'mypage-wallet-p11': 'Địa chỉ gửi tiền Etherium để tính phí Điểm giải đấu',
      'mypage-wallet-p12': '* Bạn không thể nhận lại tiền rút nếu đặt sai địa chỉ!',
      'mypage-trade-ratio': 'Tỷ giá',
      'mypage-wallet-p13':
        '* Giá Etherium bị ảnh hưởng bởi trung tâm trao đổi. Trao đổi điểm được thực hiện với giá thị trường theo thời gian thực của Etherium',
      'mypage-title-label': 'Tiêu đề',
      'mypage-content-label': 'Bối cảnh',
      'mypage-input-title': 'Nhập tiêu đề vào đây.',
      'mypage-input-content': 'Nhập nội dung vào đây',
      'mypage-wallet-p14': 'Chỉnh sửa địa chỉ ví Ethereum giao dịch',
      'mypage-wallet-p15': 'Địa chỉ Ethereum mới',
      'mypage-wallet-p16': 'Đặt vào địa chỉ Ví ETH sẽ được sử dụng trong giao dịch',
      'mypage-wallet-p17': '* Thận trọng về địa chỉ ví',
      'mypage-wallet-p18':
        '- Địa chỉ Ethereum mới, được sử dụng để tính phí và rút tiền cho MAX4 Chương trình Điểm Giải đấu để nhận phần thưởng thử thách của bạn.',
      'mypage-wallet-p19': '- Đảm bảo địa chỉ ví là chính xác. ',
      // 로그인
      'signin-back': 'Quay lại',
      'signin-input-id': 'Nhập ID của bạn',
      'signin-not-user': 'Vẫn chưa là thành viên?',
      'signin-title': 'Đăng nhập là cần thiết để sử dụng dịch vụ thành viên.',
      'signin-email-placeholder': 'E-mail',
      'signin-pw-placeholder': 'Mật khẩu',
      'signin-button-text': 'Đăng nhập',
      'signin-signin-signup-label': 'Vẫn chưa là thành viên?',
      'signin-signin-signup-text': 'Đăng ký',
      'signin-signin-lostandfound-label': 'Bạn bị mất ID / PW?',
      'signin-signin-lostandfound-text': 'Tìm ID / PW của tôi',
      'signin-alert-email-pw-required': 'ID và mật khẩu là bắt buộc.',
      'signin-alert-failed': 'ID và mật khẩu của bạn không chính xác.',
      'signin-alert-email-verification-required': 'Email của bạn chưa được xác nhận! Vui lòng xác nhận email.',
      'signin-alert-phone-verification-required': 'Truy cập trang đó để xác minh điện thoại di động của bạn.',
      'signin-email-conflict': 'Địa chỉ email này đã được sử dụng.',
      'signin-edit-email-success': 'Thay đổi hoàn toàn địa chỉ email!',
      'signin-verify-email': '이메일을 인증해주세요.',
      'signin-verify-email-description':
        'Một email xác minh đã được gửi đến địa chỉ email bạn đã nhập trong quá trình đăng ký. Vui lòng đăng nhập sau khi kiểm tra email xác minh.',
      'signin-input-new-email': 'địa chỉ thư điện tử mới',
      'signin-change-email': 'thay đổi e-mail',
      'signin-resend-email-title': 'Bạn đã không nhận được một email?',
      'signin-resend-email-success': 'Email đã được gửi lại!',
      'signin-resend-label': 'Gửi lại',
      'signin-change-email-label': 'Hãy thử với một địa chỉ khác.',
      // 회원가입
      'signup-title': 'Đăng ký',
      'signup-id': 'ID',
      'signup-email': 'E-mail',
      'signup-pw': 'Mật khẩu',
      'signup-pw-confirm': 'Kiểm tra mật khẩu',
      'signup-phone': 'Số điện thoại',
      'signup-recommender': 'người mời',
      'signup-wallet': 'Địa chỉ ví ETH chỉ dành cho thành viên',
      'signup-button-text': 'Đăng ký',
      'signup-input-wallet': 'Đặt vào địa chỉ Ví ETH sẽ được sử dụng trong giao dịch',
      'signup-input-recommender': 'Nhập ID(người mời) của bạn',
      'signup-alert-id-required': 'Nhập ID của bạn',
      'signup-alert-id-confirm-required': 'Vui lòng xác minh tên người dùng của bạn trùng lặp.',
      'signup-alert-email-required': 'Nhập E-mail của bạn',
      'signup-alert-pw-required': 'Nhập PW của bạn',
      'signup-alert-pw-confirm-required': 'Vui lòng nhập mật khẩu xác nhận.',
      'signup-alert-phone-required': 'Vui lòng nhập số điện thoại di động của bạn.',
      'signup-alert-invalid-email': 'Đây không phải là một email hợp lệ.',
      'signup-alert-pw-too-short': 'Mật khẩu phải dài ít nhất 6 ký tự.',
      'signup-alert-pw-too-long': 'Mật khẩu không được vượt quá 72 ký tự.',
      'signup-alert-pw-not-match': 'Mật khẩu và Xác nhận Mật khẩu không khớp.',
      'signup-alert-invalid-phone': 'Đây không phải là số điện thoại di động hợp lệ. Số điện thoại di động phải bao gồm mã quốc gia.',
      'signup-alert-conflict': 'Biệt hiệu, email hoặc số điện thoại đã được sử dụng.',
      'signup-alert-bad-recommendor': 'ID người giới thiệu không hợp lệ.',
      'signup-alert-successful': 'Cảm ơn bạn đã đăng ký. Vui lòng kiểm tra email được gửi trước khi đăng nhập.',
      'signup-alert-recommender-not-exists': 'ID người giới thiệu không hợp lệ.',
      'signup-alert-wallet-address-required': 'Vui lòng nhập địa chỉ ví của bạn.',
      'signup-id-available': 'Các ID có sẵn.',
      'signup-id-not-available': 'ID này đã được sử dụng.',
      'signup-email-available': 'Email có sẵn.',
      'signup-email-not-available': 'Email này đã được sử dụng.',
      'signup-phone-available': 'Số điện thoại di động có sẵn.',
      'signup-phone-not-available': 'Số điện thoại này đã được sử dụng.',
      'signup-asterisk-required': 'Các trường được đánh dấu bằng “*” phải được điền.',
      'signup-check-duplicates': 'Kiểm chứng',
      'signup-password-conditions': 'Mật khẩu phải dài hơn 8 ký tự, bao gồm cả các chữ cái và số.',
      'signup-confirm-password-placeholder': 'Đặt lại mật khẩu để xác nhận.',
      'signup-wallet-p1': '* Thận trọng khi xử lý ví của bạn',
      'signup-wallet-p2':
        '- Địa chỉ ETH của thành viên đã nhập tại thời điểm đăng ký thành viên là cố định và không thể thay đổi cho đến khi ngừng thành viên và được sử dụng làm địa chỉ duy nhất mà thành viên sẽ sử dụng để nạp điểm thi đấu trong chương trình MAX4.',
      'signup-wallet-p3': '- Đảm bảo rằng địa chỉ bạn đặt vào là chính xác.',
      // 기타
      'cannot-copy': 'Trình duyệt của bạn không được hỗ trợ để sao chép.',
      'copy-success': 'Sao chép thành công!!',
      'copy-label': 'Sao chép nó',
      'cancel-label': 'Hủy bỏ',
      'complete-label': 'hoàn thành',
      'confirm-label': 'Xác nhận',
      // 용어
      'demo-point': 'Điểm Demo',
      'tournament-point': 'Điểm giải đấu',
      'live-point': 'Điểm trực tiếp',
      'eth-point': 'Điểm Ethereum',
      'demo-challenge': 'Thử thách Demo',
      'tournament-challenge': 'Thử thách giải đấu',
      'live-challenge': 'Thử thách trực tiếp',
      'continuous-mode': 'Chế độ Chuỗi chiến thắng',
      'fixed-mode': 'Chế độ đếm chiến thắng',
      // 추가
      'mypage-check-new-wallet': 'Địa chỉ ví không hợp lệ!',
      'mypage-edit-wallet-success': 'Thay đổi địa chỉ ví thành công!',
      'send-enquiry-title': 'Đăng câu hỏi 1: 1',
      'send-enquiry-is-private-label': 'Bài bí mật',
      'send-enquiry-send-label': 'Tải lên',
    },
  },
};
