import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from '../Common/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  white-space: pre-line;
`;

export const Header = styled.div`
  height: ${vw(60)};
  box-shadow: inset 0 ${vw(-1)} 0 0 #c2a76c;
  background-color: ${Colors.dark};
  display: flex;
  align-items: center;
  z-index: 8;
`;

export const MenuButton = styled.button`
  width: ${vw(20)};
  height: ${vw(14)};

  background: none;
  border: none;
  padding: 0;
`;

export const RecentTrade = styled.button`
  width: ${vw(60)};
  border: none;
  background: none;
`;

export const ChartArea = styled.div<{ height: number }>`
  width: 100%;
  height: ${(props) => `${props.height}px`};
  background-color: ${Colors.dark};
  position: relative;
`;

export const Flag = styled.div<{ left: number }>`
  position: absolute;
  left: ${(props) => props.left}px;
  bottom: ${vw(70)};
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 4;
`;

export const FlagLine = styled.div<{ height: number }>`
  width: ${vw(1)};
  height: ${(props) => `${props.height}px`};
  background-color: ${Colors.gold};
  border: solid ${vw(1)} #e0be7a;
`;

export const MenuArea = styled.div<{ height: number }>`
  width: 100%;
  height: ${(props) => vw(props.height)};
  padding: ${vw(10)};
  box-shadow: inset 0 ${vw(1)} 0 0 ${Colors.gold};
  background-color: ${Colors.gray};
  z-index: 1;
`;

export const CircleButton = styled.button`
  width: ${vw(30)};
  height: ${vw(30)};
  padding: 0;
  border: solid ${vw(1)} #373737;
  border-radius: ${vw(15)};
  background-color: #1c1b1a;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuPositionsBox = styled.div`
  width: ${vw(340)};
  height: ${vw(160)};
  padding: ${vw(10)} 0;
  border-radius: ${vw(4)};
  background-color: #1c1b1a;
`;

export const PositionMenuCancelButton = styled.button`
  width: 100%;
  height: ${vw(40)};
  padding: 0;
  border-radius: ${vw(4)};
  border: none;
  background-color: #1c1b1a;
`;

export const ButtonGreenFilled = styled.button<{ width?: number; height?: number; fontSize?: number }>`
  width: ${(props) => (props.width ? vw(props.width) : '100%')};
  height: ${(props) => (props.height ? vw(props.height) : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  border-radius: ${vw(4)};
  box-shadow: 0 ${vw(4)} ${vw(8)} 0 rgba(0, 0, 0, 0.35);
  background-image: linear-gradient(to bottom, #91bc6a, #728e51);

  :disabled {
    opacity: 0.3;
  }
`;

export const ButtonRedFilled = styled.button<{ width?: number; height?: number; fontSize?: number }>`
  width: ${(props) => (props.width ? vw(props.width) : '100%')};
  height: ${(props) => (props.height ? vw(props.height) : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  border-radius: ${vw(4)};
  box-shadow: 0 ${vw(4)} ${vw(8)} 0 rgba(0, 0, 0, 0.35);
  background-image: linear-gradient(to bottom, #c96161, #823535);

  :disabled {
    opacity: 0.3;
  }
`;

export const ChartMenuButtonArea = styled.div`
  position: absolute;
  width: ${vw(40)};
  left: ${vw(10)};
  bottom: ${vw(50)};
  z-index: 5;
`;

export const ChartMenuButton = styled.button`
  width: ${vw(40)};
  height: ${vw(40)};
  padding: 0;
  border-radius: ${vw(4)};
  -webkit-backdrop-filter: blur(${vw(2)});
  backdrop-filter: blur(${vw(2)});
  box-shadow: 0 ${vw(4)} ${vw(16)} 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GameInfoArea = styled.div`
  position: absolute;
  width: 100%;
  height: ${vw(20)};
  padding: 0 ${vw(20)};
  bottom: ${vw(10)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TimeMenuCancelButton = styled.button`
  width: ${vw(110)};
  height: ${vw(40)};
  padding: 0;
  border-radius: ${vw(4)};
  border: none;
  background-color: #1c1b1a;
`;

export const ChartSelectMenu = styled.div`
  position: absolute;
  left: ${vw(60)};
  bottom: ${vw(90)};
  display: flex;
  flex-direction: column;
  width: ${vw(290)};
  height: ${vw(100)};
  border-radius: ${vw(4)};
  background-color: #242322;
  z-index: 7;
`;

export const ChartSelectButton = styled.button`
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: ${vw(50)};
  padding: 0;
  border-radius: ${vw(4)};
  background: none;
  border: none;
  padding: ${vw(10)};
`;

export const ChartTopMenu = styled.div`
  position: absolute;
  top: ${vw(10)};
  width: 100%;
  padding: 0 ${vw(10)};
`;

export const TickSelectMenu = styled.div`
  position: absolute;
  left: ${vw(60)};
  bottom: ${vw(50)};
  display: flex;
  flex-direction: column;
  width: ${vw(290)};
  border-radius: ${vw(4)};
  background-color: #242322;
  padding: ${vw(10)};
  z-index: 7;
`;

export const TickSeletcButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
  column-gap: ${vw(10)};
  row-gap: 0px;
  grid-auto-flow: column;
`;

export const TickSelectButton = styled.button`
  height: ${vw(37)};
  padding: ${vw(5)} ${vw(10)};
  box-shadow: inset 0 ${vw(-1)} 0 0 rgba(255, 255, 255, 0.15);
  background-color: #242322;
  border: none;
  font-family: NotoSansKR;
  font-size: ${vw(13)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;

  :disabled {
    box-shadow: inset 0 ${vw(-1)} 0 0 #e0be7a;
    background-color: rgba(255, 255, 255, 0.05);
    color: #e0be7a;
  }
`;

export const PeriodSelectMenu = styled.div`
  position: absolute;
  left: ${vw(60)};
  bottom: ${vw(50)};
  display: flex;
  flex-direction: column;
  width: ${vw(270)};
  border-radius: ${vw(4)};
  background-color: #242322;
  padding: ${vw(10)};
  z-index: 7;
`;

export const PeriodSelectButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: ${vw(10)};
  row-gap: 0px;
  grid-auto-flow: column;
`;

export const PeriodSelectButton = styled.button`
  height: ${vw(37)};
  padding: ${vw(5)} ${vw(10)};
  box-shadow: inset 0 ${vw(-1)} 0 0 rgba(255, 255, 255, 0.15);
  background-color: #242322;
  border: none;
  font-family: NotoSansKR;
  font-size: ${vw(13)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;

  :disabled {
    box-shadow: inset 0 ${vw(-1)} 0 0 #e0be7a;
    background-color: rgba(255, 255, 255, 0.05);
    color: #e0be7a;
  }
`;

export const PriceTag = styled.div<{ width: number; height: number; color: string }>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  background: ${(props) => props.color};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;

  :after {
    position: absolute;
    left: ${(props) => `-${props.height / 2}px`};
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-top: ${(props) => `${props.height / 2}px`} solid transparent;
    border-bottom: ${(props) => `${props.height / 2}px`} solid transparent;
    border-right: ${(props) => `${props.height / 2}px`} solid ${(props) => props.color};
  }
`;

export const RecentTradeMenu = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
`;

export const RecentTradeMenuContainer = styled.div<{ right: number; width: number }>`
  position: absolute;
  height: 100%;
  right: ${(props) => vw(-props.width + props.right)};
  width: ${(props) => vw(props.width)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 0 0 ${vw(1)} ${Colors.gold};
  background-color: ${Colors.dark};
  z-index: 1;
`;

export const RecentTradeList = styled.div`
  height: calc(100vh - ${vw(60)});
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: scroll;
`;

export const RecentTradeDay = styled.div`
  height: ${vw(20)};
  padding: 0 ${vw(10)};
  box-shadow: inset 0 ${vw(1)} 0 0 rgba(255, 255, 255, 0.15);
  background-color: #242322;
`;

export const RecentTradeInfo = styled.div`
  height: ${vw(60)};
  padding: ${vw(10)};
  box-shadow: inset 0 ${vw(-1)} 0 0 rgba(255, 255, 255, 0.08);
`;

export const ArrowLong = styled.div`
  width: 0px;
  height: 0px;
  border-left: ${vw(5)} solid transparent;
  border-bottom: ${vw(8)} solid #91bc6a;
  border-right: ${vw(5)} solid transparent;
`;

export const ArrowShort = styled.div`
  width: 0px;
  height: 0px;
  border-left: ${vw(5)} solid transparent;
  border-top: ${vw(8)} solid #c96161;
  border-right: ${vw(5)} solid transparent;
`;

export const Result = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResultBox = styled.div`
  width: ${vw(320)};
  padding: ${vw(10)};
  border-radius: ${vw(10)};
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ResultBoxPositions = styled.div`
  width: ${vw(300)};
  height: ${vw(220)};
  padding: ${vw(20)} ${vw(10)};
  border-radius: ${vw(4)};
  background-color: #1c1b1a;
`;

export const TransferMenuContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TransferMenuBox = styled.div`
  width: ${vw(320)};
  height: ${vw(310)};
  padding: ${vw(10)};
  border-radius: ${vw(10)};
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
`;

export const TransferMenuInnerBox = styled.div`
  width: ${vw(300)};
  height: ${vw(170)};
  padding: ${vw(10)};
  border-radius: ${vw(4)};
  background-color: #1c1b1a;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
