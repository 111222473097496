import React, { ReactElement, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import CustomerCare from './components/CustomerCare/CustomerCare';
import FindAccount from './components/FindAccount/FindAccount';
import Incentives from './components/Incentives/Incentives';
import Introduce from './components/Intoduce/Introduce';
import Main from './components/Main/Main';
import MyPage from './components/MyPage/MyPage';
import Notice from './components/Notice/Notice';
import ProChallenge from './components/ProChallenge/ProChallenge';
import PurchaseMembership from './components/PurchaseMembership/PurchaseMembership';
import SendInquiry from './components/SendInquiry/SendInquiry';
import Signin from './components/Signin/Signin';
import Signup from './components/Signup/Signup';

import AuthContext from './token-context';

export default function App(): ReactElement {
  const [token, setToken] = useState(localStorage.getItem('session'));

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      <Router>
        <Switch>
          <Route render={(props) => <Main {...props} />} path="/" exact />
          <Route render={(props) => <Introduce {...props} />} path="/introduce" exact />
          <Route render={(props) => <Signin {...props} />} path="/signin" exact />
          <Route render={(props) => <Signup {...props} />} path="/signup" exact />
          <Route render={(props) => <FindAccount {...props} />} path="/find-account" exact />
          <Route render={(props) => <PurchaseMembership {...props} />} path="/purchase-membership" exact />
          <Route render={(props) => <MyPage {...props} />} path="/mypage" exact />
          <Route render={(props) => <Incentives {...props} />} path="/incentives" exact />
          <Route render={(props) => <Notice {...props} />} path="/notice" exact />
          <Route render={(props) => <CustomerCare {...props} />} path="/customercare" exact />
          <Route render={(props) => <SendInquiry {...props} />} path="/send-inquiry" exact />
          <Route render={(props) => <ProChallenge {...props} />} path="/pro-challenge" exact />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}
