import styled from 'styled-components';
import { vw } from '../../Utils/Size';

export const OTF_B_10 = styled.text<{ textAlign?: string }>`
  font-family: NotoSansKR;
  font-size: ${vw(10)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: #fff;
`;

export const OTF_B_12 = styled.text<{ textAlign?: string }>`
  font-family: NotoSansKR;
  font-size: ${vw(12)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: #fff;
`;

export const OTF_B_16 = styled.text<{ textAlign?: string }>`
  font-family: NotoSansKR;
  font-size: ${vw(16)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: #fff;
`;

export const OTF_B_24 = styled.text<{ textAlign?: string }>`
  font-family: NotoSansKR;
  font-size: ${vw(24)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: #fff;
`;

export const OTF_R_10 = styled.text<{ textAlign?: string }>`
  font-family: NotoSansKR;
  font-size: ${vw(10)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: #fff;
`;

export const OTF_R_12 = styled.text<{ textAlign?: string }>`
  font-family: NotoSansKR;
  font-size: ${vw(12)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: #fff;
`;

export const StyledText = styled.text<{
  fontSize?: number;
  opacity?: number;
  color?: string;
  fontWeight?: 'bold' | 'normal';
  textAlign?: 'left' | 'right' | 'center';
  lineHeight?: number;
  width?: number;
  height?: number;
  wordBreak?: string;
}>`
  ${(props) => props.width && `width: ${vw(props.width)}`};
  ${(props) => props.height && `height: ${vw(props.height)}`};
  font-family: NotoSansKR;
  font-size: ${(props) => (props.fontSize ? vw(props.fontSize) : vw(12))};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'normal')};
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: ${(props) => (props.color ? props.color : '#fff')};
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};

  ${(props) => props.wordBreak && `word-break: ${props.wordBreak}`}
`;
