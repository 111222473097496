import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';
import * as Styled from './Notice.style';

import { OTF_B_12, OTF_B_16, OTF_R_10, OTF_R_12, StyledText } from '../Common/Typography';
import { FlexColumn, FlexRow, SizedBoxH, SizedBoxW, SizedImage, Span, TabButton } from '../Common/CommonStyle';

// images
import HeaderLogo from 'img/Main/header_logo.png';
import Colors from '../Common/Colors';
import ContinuousGameIcon from 'img/Game/game_continuous_gold.png';
import FixedGameIcon from 'img/Game/game_fixed_gold.png';
import LongIcon from 'img/Game/long_icon.png';
import ShortIcon from 'img/Game/short_icon.png';
import PointAIcon from 'img/Game/point_a.png';
import PointBIcon from 'img/Game/point_b.png';
import PointCIcon from 'img/Game/point_c.png';
import PointDIcon from 'img/Game/point_d.png';
import MenuIcon from 'img/Main/menu.png';

import { vw } from '../../Utils/Size';
import RightSideMenu from '../RightSideMenu/RightSideMenu';
import { getNotice, listNotices } from '../../api';
import Header from '../Header/Header';
import Pages from '../Common/Pages';
import dayjs from 'dayjs';

export default withRouter(function Notice(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [notices, setNotices] = useState<BoardInfoDto[]>([]);
  const [content, setContent] = useState<NoticeContentDto | null>(null);
  const [prev, setPrev] = useState<BoardInfoDto | null>(null);
  const [next, setNext] = useState<BoardInfoDto | null>(null);

  useEffect(() => {
    (async () => {
      const result = await listNotices(currentPage);

      if (result === null) return;

      setNotices(result.notices);
      setMaxPage(result.maxPage);
    })();
  }, [currentPage]);

  async function handleClickNotice(id: string) {
    const notice = await getNotice(id);

    console.log(notice);

    if (!notice) {
      setContent(null);
      setPrev(null);
      setNext(null);
      return;
    }

    setContent(notice.notice);

    if (!notice.prev) setPrev(null);
    else {
      notice.prev.category = '이전 글';
      setPrev(notice.prev);
    }

    if (!notice.next) setNext(null);
    else {
      notice.next.category = '다음 글';
      setNext(notice.next);
    }
  }

  return (
    <Styled.Container>
      <Header />

      <Styled.NoticeContainer>
        {content && (
          <>
            <Styled.TitleContainer>
              <OTF_R_10>공지사항 - {content.category}</OTF_R_10>
              <SizedBoxH height={5} />
              <StyledText fontSize={16} fontWeight="bold" color={Colors.gold}>
                {content.title}
              </StyledText>
              <FlexRow>
                <StyledText fontSize={10} opacity={0.6}>
                  {content.author}
                </StyledText>
                <Span />
                <StyledText fontSize={10} opacity={0.6}>
                  {dayjs(content.writtenAt).format('YYYY.MM.DD')}
                </StyledText>
              </FlexRow>
            </Styled.TitleContainer>

            <Styled.NoticeContent dangerouslySetInnerHTML={{ __html: content.content }} />
            <SizedBoxH height={10} />

            {(prev || next) && (
              <>
                <Styled.NoticeInnerBox>
                  {prev && <NoticeButton info={prev} onClick={() => handleClickNotice(prev.id)} />}
                  {next && <NoticeButton info={next} onClick={() => handleClickNotice(next.id)} />}
                </Styled.NoticeInnerBox>
                <SizedBoxH height={10} />
              </>
            )}
          </>
        )}

        {content === null && (
          <Styled.TitleContainer>
            <StyledText fontSize={24} fontWeight="bold" color={Colors.gold}>
              공지사항
            </StyledText>
          </Styled.TitleContainer>
        )}

        <Styled.NoticeInnerBox>
          {notices.map((e) => (
            <NoticeButton info={e} onClick={() => handleClickNotice(e.id)} />
          ))}
          <SizedBoxH height={20} />
          <Pages curPage={currentPage} pageCount={maxPage} onPage={(page) => setCurrentPage(page)} />
          <SizedBoxH height={20} />
        </Styled.NoticeInnerBox>
      </Styled.NoticeContainer>
    </Styled.Container>
  );
});

const NoticeButton = (props: { info: BoardInfoDto; onClick: () => void }): ReactElement => {
  return (
    <Styled.NoticeButton key={props.info.id} onClick={props.onClick}>
      <FlexColumn>
        <FlexRow>
          <StyledText fontSize={8} fontWeight="bold" color={Colors.gold}>
            [{props.info.category}]
          </StyledText>
          <SizedBoxW width={10} />
          <StyledText fontSize={8} opacity={0.6}>
            {props.info.author}
          </StyledText>
          <Span />
          <StyledText fontSize={8} opacity={0.6} textAlign="right">
            {dayjs(props.info.writtenAt).format('YYYY.MM.DD')}
          </StyledText>
        </FlexRow>
        <SizedBoxH height={5} />
        <OTF_B_12>{props.info.title}</OTF_B_12>
      </FlexColumn>
    </Styled.NoticeButton>
  );
};
