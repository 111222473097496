import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';
import * as Styled from './PurchaseMembership.style';

import { OTF_B_10, OTF_B_12, OTF_B_16, OTF_B_24, OTF_R_10, OTF_R_12, StyledText } from '../Common/Typography';

import {
  ButtonGoldFilled,
  ButtonGoldOutlined,
  FlexColumn,
  FlexRow,
  Input,
  SizedBoxH,
  SizedBoxW,
  Span,
  ToggleButton,
} from '../Common/CommonStyle';

// images
import NormalAccount from 'img/Signup/signup_normal.png';
import BusinessAccount from 'img/Signup/signup_business.png';
import Colors from '../Common/Colors';
import { MembershipIcons, SmallLogo } from '../Common/Imgaes';

const Memberships: { membership: MembershipType; price: number }[] = [
  { membership: 'MAX1', price: 100 },
  { membership: 'MAX2', price: 500 },
  { membership: 'MAX3', price: 1000 },
  { membership: 'MAX4', price: 5000 },
  { membership: 'MAX5', price: 10000 },
];

export default withRouter(function Signup(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  const [membership, setMembership] = useState<MembershipType | 'none'>('none');
  const [confirm, setConfirm] = useState<boolean>(false);

  return (
    <>
      {!confirm && (
        <Styled.Container>
          <StyledText color={Colors.gold} fontSize={16} fontWeight={'bold'} textAlign={'center'}>
            회원권 등급을 선택해주세요.
          </StyledText>

          <SizedBoxH height={40} />

          <FlexColumn>
            {Memberships.map((e, i) => {
              return (
                <>
                  <Styled.MembershipButton onClick={() => setMembership(e.membership)} disabled={membership == e.membership}>
                    <FlexRow alignItems="center">
                      <Styled.MembershipIcon src={MembershipIcons[e.membership]} />
                      <SizedBoxW width={15} />
                      <FlexColumn justifyContent="center" alignItems="stretch">
                        <StyledText
                          fontSize={16}
                          fontWeight={'bold'}
                          color={membership == e.membership ? Colors.dark : Colors.gold}
                          textAlign="left"
                        >
                          {e.membership} 회원권
                        </StyledText>
                        <StyledText fontSize={12} color={membership == e.membership ? Colors.dark : Colors.white}>
                          가입비: {e.price.toLocaleString()}$
                        </StyledText>
                      </FlexColumn>
                    </FlexRow>
                  </Styled.MembershipButton>
                  <SizedBoxH height={10} />
                </>
              );
            })}
          </FlexColumn>

          <SizedBoxH height={10} />
          <ButtonGoldFilled height={40} disabled={membership == 'none'} onClick={() => setConfirm(membership != 'none')}>
            {membership == 'none' ? '회원권을 선택해주세요' : '완료'}
          </ButtonGoldFilled>

          <Span />

          <ButtonGoldOutlined
            width={180}
            height={40}
            onClick={() => {
              props.history.goBack();
            }}
          >
            이전 화면으로 돌아가기
          </ButtonGoldOutlined>
        </Styled.Container>
      )}
      {confirm && (
        <Styled.ConfirmContainer>
          <FlexRow justifyContent="center">
            <Styled.Logo src={SmallLogo} />
          </FlexRow>
          <SizedBoxH height={10} />
          <FlexRow justifyContent="center">
            <OTF_B_16>MAX4</OTF_B_16>
            <SizedBoxW width={10} />
            <OTF_R_12>Best Trading Education Program</OTF_R_12>
          </FlexRow>

          <SizedBoxH height={40} />

          <Styled.ConfirmInnerContainer>
            <StyledText fontSize={16} fontWeight={'bold'} color={Colors.gold}>
              {membership} 회원권
            </StyledText>
            <SizedBoxH height={5} />

            <FlexRow>
              <OTF_B_10>가입비 {Memberships.find((e) => e.membership == membership)?.price.toLocaleString()}$</OTF_B_10>
            </FlexRow>

            <SizedBoxH height={20} />
            <OTF_B_12>회원권 구매를 위한 이더리움 입금</OTF_B_12>
            <SizedBoxH height={10} />

            <Styled.ConfirmInnerContainerInnerBox>
              <StyledText fontSize={12} color={Colors.gold} fontWeight="bold">
                이더리움 입금 지갑 주소
              </StyledText>
              <OTF_R_12>0xAb5801a7D398351b8bE11C439e05C5B3259aeC9B</OTF_R_12>
              <SizedBoxH height={10} />
              <ButtonGoldOutlined width={100} height={30}>
                복사하기
              </ButtonGoldOutlined>
              <SizedBoxH height={20} />
              <StyledText fontSize={12} fontWeight="bold" color={Colors.red}>
                * 거래소에서(업비트, 빗썸 등) 전송 금지
              </StyledText>
              <StyledText fontSize={10} color={Colors.red} opacity={0.8}>
                반드시 등록된 개인지갑(트러스트월렛, 메타마스크 등) 에서 전송해 주시기 바랍니다.
              </StyledText>
              <SizedBoxH height={10} />
              <OTF_R_10 opacity={0.8}>
                * 회원가입시 입력한 지갑주소와 동일한 지갑에서 출금이 되어 해당 이더리움 지갑 주소로 입금을 진행해야 정상적인 회원권 구매
                확인이 가능합니다.
              </OTF_R_10>
              <SizedBoxH height={10} />
              <OTF_R_10 opacity={0.8}>
                * 회원권 비용 계산 기준은 회원님이 이더리움을 전송한 당시의 컨트렉트를 확인하여 해당하는 회원권의 비용인지 아닌지를 확인
                하므로, 전송시에 정확한 회원권의 비용만큼 이더리움을 입금해주시기 바랍니다. 만약 부족하게되면 관리자가 별도로 회원님께
                이메일로 연락을 드립니다.
              </OTF_R_10>
              <SizedBoxH height={10} />
              <StyledText fontSize={10} color={Colors.red} opacity={0.8}>
                * 입금 주소를 잘못 입력하여 전송한 경우 되찾을 수 없습니다!
              </StyledText>
              <SizedBoxH height={20} />
              <FlexRow alignItems="center">
                <ToggleButton isOn={false} />
                <SizedBoxW width={10} />
                <StyledText color={Colors.gold} fontSize={10}>
                  {`* 회원권 구매 입금 이더리움 지갑주소로\n${membership} 회원권(0.02665 ETH)의 이더리움을 입금하였습니다.`}
                </StyledText>
              </FlexRow>
            </Styled.ConfirmInnerContainerInnerBox>
            <SizedBoxH height={20} />
            <ButtonGoldFilled height={40}>입금 완료</ButtonGoldFilled>
          </Styled.ConfirmInnerContainer>
          <SizedBoxH height={40} />
          <ButtonGoldOutlined width={180} height={40}>
            이전 화면으로 돌아가기
          </ButtonGoldOutlined>
        </Styled.ConfirmContainer>
      )}
    </>
  );
});
