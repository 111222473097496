import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from './Colors';

export const SizedBoxW = styled.div<{ width: number }>`
  width: ${(props) => vw(props.width)};
`;

export const SizedBoxH = styled.div<{ height: number }>`
  height: ${(props) => vw(props.height)};
`;

export const SizedBox = styled.div<{ width: number; height: number }>`
  width: ${(props) => vw(props.width)};
  height: ${(props) => vw(props.height)};
`;

export const FlexRow = styled.div<{ width?: number; height?: number; alignItems?: string; justifyContent?: string }>`
  display: flex;
  flex-direction: row;

  ${(props) => props.width && `width: ${vw(props.width)}`};
  ${(props) => props.height && `height: ${vw(props.height)}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.justifyContent && `justify-content:${props.justifyContent}`};
`;

export const FlexColumn = styled.div<{ width?: number; height?: number; alignItems?: string; justifyContent?: string }>`
  display: flex;
  flex-direction: column;

  ${(props) => props.width && `width: ${vw(props.width)}`};
  ${(props) => props.height && `height: ${vw(props.height)}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.justifyContent && `justify-content:${props.justifyContent}`};
`;

export const Span = styled.span`
  margin: auto;
`;

export const Input = styled.input<{ width?: number; height?: number; textAlign?: string }>`
  width: ${(props) => (props.width ? vw(props.width) : '100%')};
  height: ${(props) => (props.height ? vw(props.height) : '100%')};
  padding: ${vw(6)} ${vw(10)};
  border-radius: ${vw(8)};
  border: solid ${vw(1)} rgba(255, 255, 255, 0.4);
  background-color: transparent;

  font-family: NotoSansKR;
  font-size: ${vw(12)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: #fff;

  :focus {
    border: solid ${vw(1)} #e0be7a;
  }
`;

export const ButtonGoldFilled = styled.button<{ width?: number; height?: number; fontSize?: number }>`
  width: ${(props) => (props.width ? vw(props.width) : '100%')};
  height: ${(props) => (props.height ? vw(props.height) : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: ${vw(8)};
  border: none;
  background-color: #e0be7a;

  font-family: NotoSansKR;
  font-size: ${(props) => (props.fontSize ? vw(props.fontSize) : vw(14))};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;

  :disabled {
    opacity: 0.4;
  }
`;

export const ButtonGoldOutlined = styled.button<{ width?: number; height?: number; fontSize?: number }>`
  width: ${(props) => (props.width ? vw(props.width) : '100%')};
  height: ${(props) => (props.height ? vw(props.height) : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: ${vw(8)};
  border: solid ${vw(1)} #e0be7a;
  background-color: transparent;

  font-family: NotoSansKR;
  font-size: ${(props) => (props.fontSize ? vw(props.fontSize) : vw(14))};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
`;

export const ButtonRedOutlined = styled.button<{ width?: number; height?: number; fontSize?: number }>`
  width: ${(props) => (props.width ? vw(props.width) : '100%')};
  height: ${(props) => (props.height ? vw(props.height) : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: ${vw(8)};
  border: solid ${vw(1)} ${Colors.red};
  background-color: transparent;

  font-family: NotoSansKR;
  font-size: ${(props) => (props.fontSize ? vw(props.fontSize) : vw(14))};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${Colors.red};
`;

export const TabButton = styled.button<{ width?: number; height?: number; color?: string }>`
  width: ${(props) => (props.width ? vw(props.width) : '100%')};
  height: ${(props) => (props.height ? vw(props.height) : '100%')};

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  background-color: transparent;
  border: none;

  font-family: NotoSansKR;
  font-size: ${vw(16)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  :disabled {
    background-color: ${(props) => (props.color ? props.color : Colors.gray)};
    box-shadow: inset 0 ${vw(3)} 0 0 #c2a76c;
  }
`;

export const ToggleButton = styled.button<{ isOn: boolean; width?: number; height?: number }>`
  width: ${(props) => (props.width ? vw(props.width) : vw(20))};
  height: ${(props) => (props.height ? vw(props.height) : vw(20))};

  border-radius: ${vw(4)};
  border: solid ${vw(1)} #e0be7a;
  background-color: ${(props) => (props.isOn ? '#e0be7a' : 'transparent')};
`;

export const Circle = styled.div<{ size: number; color: string }>`
  width: ${(props) => vw(props.size)};
  height: ${(props) => vw(props.size)};
  border-radius: ${(props) => vw(props.size / 2)};
  background-color: ${(props) => props.color};
`;

export const SizedImage = styled.img<{ width: number; height: number }>`
  width: ${(props) => vw(props.width)};
  height: ${(props) => vw(props.height)};
`;

export const UnderlineTextButton = styled.button<{
  fontSize?: number;
  color?: string;
  weight?: string;
  opacity?: number;
  textAlign?: string;
}>`
  font-family: NotoSansKR;
  font-size: ${(props) => (props.fontSize ? `${vw(props.fontSize)}` : `${vw(10)}`)};
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: ${(props) => (props.color ? props.color : '#fff')};
  ${(props) => props.opacity && `opacity: ${props.opacity}`};

  text-decoration: underline;
  padding: 0;
  background: none;
  border: none;
`;
