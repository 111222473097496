import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';

import { OTF_B_10, OTF_B_16, OTF_B_24, OTF_R_12, StyledText } from '../Common/Typography';

import MenuIcon from 'img/Main/menu.png';

import { ButtonGoldFilled, SizedBoxH, SizedBoxW, SizedImage, Span } from '../Common/CommonStyle';
import RightSideMenu from '../RightSideMenu/RightSideMenu';
import { signout } from '../../api';

import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import { SmallLogo } from '../Common/Imgaes';
import Colors from '../Common/Colors';

const StyledHeader = styled.div<{ color?: string }>`
  height: ${vw(60)};
  box-shadow: inset 0 ${vw(-1)} 0 0 #c2a76c;
  background-color: ${(props) => (props.color ? props.color : Colors.dark)};
  display: flex;
  align-items: center;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const MenuButton = styled.button`
  width: ${vw(20)};
  height: ${vw(14)};

  background: none;
  border: none;
  padding: 0;
`;

export default function Header(props: { color?: string }): ReactElement {
  const [menuOn, setMenuOn] = useState(false);

  return (
    <>
      <StyledHeader color={props.color}>
        <SizedBoxW width={10} />
        <Link to="/">
          <SizedImage width={40} height={40} src={SmallLogo} />
        </Link>
        <Span />
        <MenuButton onClick={() => setMenuOn(true)}>
          <SizedImage src={MenuIcon} width={20} height={14} />
        </MenuButton>
        <SizedBoxW width={20} />
      </StyledHeader>
      {menuOn && <RightSideMenu onClose={() => setMenuOn(false)} />}
    </>
  );
}
