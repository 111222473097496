import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';
import * as Styled from './Main.style';

import { OTF_B_10, OTF_B_16, OTF_B_24, OTF_R_12, StyledText } from '../Common/Typography';

import Background from 'img/Main/background.png';
import HeaderLogo from 'img/Main/header_logo.png';
import MainLogo from 'img/Main/main_logo.png';

import Screenshot1 from 'img/Main/main_screenshot_1.png';
import Screenshot2 from 'img/Main/main_screenshot_2.png';
import Screenshot3 from 'img/Main/main_screenshot_3.png';
import MenuIcon from 'img/Main/menu.png';
import Deco from 'img/Main/main_deco.png';

import { ButtonGoldFilled, SizedBoxH, SizedBoxW, SizedImage, Span } from '../Common/CommonStyle';
import RightSideMenu from '../RightSideMenu/RightSideMenu';
import { signout } from '../../api';
import Header from '../Header/Header';

export default withRouter(function Main(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  const [menuOn, setMenuOn] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Styled.Container>
      <Styled.Background>
        <Styled.BackgroundImg src={Background} />
        <Header color={'black'} />
        <Styled.MainContainer>
          <SizedBoxH height={160} />
          <Styled.MainLogo src={MainLogo} />
          <SizedBoxH height={30} />
          <OTF_B_24 textAlign="center">Digital Trading Education Platform</OTF_B_24>
          <SizedBoxH height={10} />
          <OTF_B_10 textAlign="center">디지털 트레이딩 교육 플랫폼</OTF_B_10>
        </Styled.MainContainer>
      </Styled.Background>

      <Styled.IntroduceContainer1>
        <Styled.IntroduceCircle />
        <SizedBoxH height={10} />
        <OTF_B_16 textAlign="center">Try with $10 demo points</OTF_B_16>
        <SizedBoxH height={10} />
        <OTF_R_12 textAlign="center">MAX4 Developed to train professional traders and trading instructors</OTF_R_12>

        <SizedBoxH height={30} />

        <Styled.IntroduceCircle />
        <SizedBoxH height={10} />
        <OTF_B_16 textAlign="center">Reset your trading style with MAX4</OTF_B_16>
        <SizedBoxH height={10} />
        <OTF_R_12 textAlign="center">
          MAX4 is a digital trading education platform developed to increase the winning rate to pursue stable investment returns.
        </OTF_R_12>

        <SizedBoxH height={30} />

        <Styled.IntroduceCircle />
        <SizedBoxH height={10} />
        <OTF_B_16 textAlign="center">Winning habit will be developed</OTF_B_16>
        <SizedBoxH height={10} />
        <OTF_R_12 textAlign="center">
          MAX4 is not a traditional Demo contest or live competition method that is evaluated based on returns.
        </OTF_R_12>
      </Styled.IntroduceContainer1>

      <Styled.IntroduceContainer2>
        <SizedImage src={HeaderLogo} width={40} height={40} />
        <SizedBoxH height={10} />
        <OTF_B_24>About MAX4</OTF_B_24>
        <SizedBoxH height={10} />
        <OTF_R_12 textAlign="center">
          수익률을 기준으로 평가 받는 전통적이고 보수적인 모의 대회나 라이브 대회 방식이 아닌 승률을 높여서 안정적인 투자수익을 추구할 수
          있도록 개발된 디지털 트레이딩 교육 플랫폼 MAX4는 전문 트레이더 및 트레이딩 강사를 육성하기 위해 개발되었습니다.
        </OTF_R_12>

        <SizedBoxH height={60} />

        <Styled.IntroduceContainer2InnerBox>
          <Styled.IntroduceCircle />
          <SizedBoxH height={20} />
          <StyledText fontSize={16} color={'#e0be7a'}>
            초 단타 매매(스캘핑) 실력 향상
          </StyledText>
          <SizedBoxH height={10} />
          <OTF_R_12>
            변동성이 강한 하이로우 방식의 옵션 트레이딩을 통해 BTC 시세를 예측하고 트레이딩함으로써 트레이더는 챌린지에 참가 할수록
            트레이딩에 필요한 정확한 진입 포인트를 예측할 수 있어 초 단타매매(스켈핑) 실력이 향상됩니다.
          </OTF_R_12>
        </Styled.IntroduceContainer2InnerBox>

        <SizedBoxH height={10} />

        <Styled.IntroduceContainer2InnerBox>
          <Styled.IntroduceCircle />
          <SizedBoxH height={20} />
          <StyledText fontSize={16} color={'#e0be7a'}>
            트레이딩 실력 평가
          </StyledText>
          <SizedBoxH height={10} />
          <OTF_R_12>
            시대가 발전함에따라 투자처의 다변화로 투자하기 전 자신의 트레이딩 실력을 객관적으로 평가 받아 무분별한 투자의 위험 에서 손실을
            방지할 수 있습니다.
          </OTF_R_12>
        </Styled.IntroduceContainer2InnerBox>

        <SizedBoxH height={10} />

        <Styled.IntroduceContainer2InnerBox>
          <Styled.IntroduceCircle />
          <SizedBoxH height={20} />
          <StyledText fontSize={16} color={'#e0be7a'}>
            실력에 따라 트레이딩 포인트 지급
          </StyledText>
          <SizedBoxH height={10} />
          <OTF_R_12>
            돈이 없어도 실력만 있으면 챌린지에 참가하여 트레이딩 레벨에 따라 트레이딩 포인트를 지급 받아 트레이딩을할 수 있습니다.{' '}
          </OTF_R_12>
        </Styled.IntroduceContainer2InnerBox>
      </Styled.IntroduceContainer2>

      <Styled.IntroduceContainer3>
        <OTF_B_24>MAX4 챌린지</OTF_B_24>
        <SizedBoxH height={10} />
        <OTF_R_12 textAlign="center">
          수익률을 기준으로 평가 받는 전통적이고 보수적인 모의 대회 라이브 대회 방식이 아닌 승률을 높여서 안정적인 투자수익을 추구할 수
          있도록 개발된 디지털 트레이딩
        </OTF_R_12>
        <SizedBoxH height={20} />
        <Styled.IntroduceContainer3Screenshot src={Screenshot1} />

        <SizedBoxH height={40} />

        <OTF_B_24>퓨처 트레이닝</OTF_B_24>
        <SizedBoxH height={10} />
        <OTF_R_12 textAlign="center">
          수익률을 기준으로 평가 받는 전통적이고 보수적인 모의 대회 라이브 대회 방식이 아닌 승률을 높여서 안정적인 투자수익을 추구할 수
          있도록 개발된 디지털 트레이딩
        </OTF_R_12>
        <SizedBoxH height={20} />
        <Styled.IntroduceContainer3Screenshot src={Screenshot2} />

        <SizedBoxH height={40} />

        <OTF_B_24>Coming Soon</OTF_B_24>
        <SizedBoxH height={20} />
        <Styled.IntroduceContainer3Screenshot src={Screenshot3} />
      </Styled.IntroduceContainer3>

      <Styled.IntroduceContainer4>
        <OTF_B_24>지금바로 시작하세요</OTF_B_24>
        <SizedBoxH height={20} />
        <Link to="/pro-challenge">
          <ButtonGoldFilled width={150} height={40}>
            MAX4 챌린지 참여
          </ButtonGoldFilled>
        </Link>
      </Styled.IntroduceContainer4>

      <Styled.Footer>
        <SizedImage src={HeaderLogo} width={40} height={40} />
        <SizedBoxH height={20} />
        <StyledText fontSize={12} opacity={0.4} textAlign={'left'}>
          ⓒ MAX SIGNAL 2021 All rights reserved.
        </StyledText>
        <SizedBoxH height={20} />
        <StyledText fontSize={12} opacity={0.75} textAlign={'left'} fontWeight={'bold'}>
          사업자 등록번호
        </StyledText>
        <StyledText fontSize={12} opacity={0.4} textAlign={'left'}>
          01-234-567890
        </StyledText>
        <SizedBoxH height={20} />
        <StyledText fontSize={12} opacity={0.75} textAlign={'left'} fontWeight={'bold'}>
          문의
        </StyledText>
        <StyledText fontSize={12} opacity={0.4} textAlign={'left'}>
          {'support@max4.io\n02) 1234-5678'}
        </StyledText>
        <SizedBoxH height={20} />
        <StyledText fontSize={12} opacity={0.75} textAlign={'left'} fontWeight={'bold'}>
          주소
        </StyledText>
        <StyledText fontSize={12} opacity={0.4} textAlign={'left'}>
          서울특별시 신사동 압구정로12길 46
        </StyledText>
      </Styled.Footer>
      {menuOn && <RightSideMenu onClose={() => setMenuOn(false)} />}
    </Styled.Container>
  );
});
