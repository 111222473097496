import dayjs from 'dayjs';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getFAQ, getInquiry, listFAQs, listInquires } from '../../api';
import Colors from '../Common/Colors';
import { ButtonGoldFilled, FlexColumn, FlexRow, SizedBoxH, SizedBoxW, SizedImage, Span, TabButton } from '../Common/CommonStyle';
import Pages from '../Common/Pages';
import { OTF_B_12, OTF_R_10, StyledText } from '../Common/Typography';
import Header from '../Header/Header';
import * as Styled from './CustomerCare.style';

export default withRouter(function CustomerCare(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const [tab, setTab] = useState<'inquiry' | 'ask'>('ask');

  // Inquiry
  const [Inquiry_currentPage, Inquiry_setCurrentPage] = useState(1);
  const [Inquiry_maxPage, Inquiry_setMaxPage] = useState(1);
  const [Inquiry_inquiries, Inquiry_setInquiries] = useState<InquiryContentDto[]>([]);
  const [Inquiry_content, Inquiry_setContent] = useState<InquiryContentDto | null>(null);
  const [Inquiry_prev, Inquiry_setPrev] = useState<InquiryContentDto | null>(null);
  const [Inquiry_next, Inquiry_setNext] = useState<InquiryContentDto | null>(null);

  // FAQ
  const [FAQ_currentPage, FAQ_setCurrentPage] = useState(1);
  const [FAQ_maxPage, FAQ_setMaxPage] = useState(1);
  const [FAQ_faqs, FAQ_setFaqs] = useState<BoardInfoDto[]>([]);
  const [FAQ_content, FAQ_setContent] = useState<FAQContentDto | null>(null);
  const [FAQ_prev, FAQ_setPrev] = useState<BoardInfoDto | null>(null);
  const [FAQ_next, FAQ_setNext] = useState<BoardInfoDto | null>(null);

  useEffect(() => {
    (async () => {
      const result = await listInquires(Inquiry_currentPage);

      if (result === null) return;

      Inquiry_setInquiries(result.inquiries);
      Inquiry_setMaxPage(result.maxPage);
    })();
  }, [Inquiry_currentPage]);

  useEffect(() => {
    (async () => {
      const result = await listFAQs(FAQ_currentPage);

      if (result === null) return;

      FAQ_setFaqs(result.faqs);
      FAQ_setMaxPage(result.maxPage);
    })();
  }, [FAQ_currentPage]);

  async function handleClickInquiry(id: string) {
    const inquiry = await getInquiry(id);

    if (!inquiry) {
      Inquiry_setContent(null);
      Inquiry_setPrev(null);
      Inquiry_setNext(null);
      return;
    }

    Inquiry_setContent(inquiry.inquiry);

    if (!inquiry.prev) Inquiry_setPrev(null);
    else Inquiry_setPrev(inquiry.prev);

    if (!inquiry.next) Inquiry_setNext(null);
    else Inquiry_setNext(inquiry.next);
  }
  async function handleClickFAQ(id: string) {
    const faq = await getFAQ(id);

    if (!faq) {
      FAQ_setContent(null);
      FAQ_setPrev(null);
      FAQ_setNext(null);
      return;
    }

    FAQ_setContent(faq.faq);

    if (!faq.prev) FAQ_setPrev(null);
    else FAQ_setPrev(faq.prev);

    if (!faq.next) FAQ_setNext(null);
    else FAQ_setNext(faq.next);
  }

  return (
    <Styled.Container>
      <Header />
      <Styled.InnerContainer>
        {FAQ_content === null && Inquiry_content === null && (
          <>
            <Styled.TitleContainer>
              <FlexRow>
                <StyledText fontSize={24} fontWeight="bold" color={Colors.gold}>
                  고객 센터
                </StyledText>
                <Span />
                <ButtonGoldFilled width={90} height={30} fontSize={12}>
                  문의 작성
                </ButtonGoldFilled>
              </FlexRow>
            </Styled.TitleContainer>
            <FlexRow>
              <TabButton width={180} height={50} disabled={tab == 'ask'} color={Colors.gray} onClick={() => setTab('ask')}>
                자주하는 질문
              </TabButton>
              <TabButton width={180} height={50} disabled={tab == 'inquiry'} color={Colors.gray} onClick={() => setTab('inquiry')}>
                1대1 문의하기
              </TabButton>
            </FlexRow>
          </>
        )}
        {FAQ_content && (
          <>
            <Styled.TitleContainer>
              <OTF_R_10>고객센터 - 자주하는 질문</OTF_R_10>
              <SizedBoxH height={5} />
              <StyledText fontSize={16} fontWeight="bold" color={Colors.gold}>
                {FAQ_content.title}
              </StyledText>
              <FlexRow>
                <StyledText fontSize={10} opacity={0.6}>
                  {FAQ_content.author}
                </StyledText>
                <Span />
                <StyledText fontSize={10} opacity={0.6}>
                  {dayjs(FAQ_content.writtenAt).format('YYYY.MM.DD')}
                </StyledText>
              </FlexRow>
            </Styled.TitleContainer>

            <Styled.Content dangerouslySetInnerHTML={{ __html: FAQ_content.content }} />
            <SizedBoxH height={10} />
          </>
        )}
        {Inquiry_content && (
          <>
            <Styled.TitleContainer>
              <OTF_R_10>고객센터 - 1대1 문의하기</OTF_R_10>
              <SizedBoxH height={5} />
              <StyledText fontSize={16} fontWeight="bold" color={Colors.gold}>
                Q. {Inquiry_content.title}
              </StyledText>
              <FlexRow>
                <StyledText fontSize={10} opacity={0.6}>
                  {Inquiry_content.author}
                </StyledText>
                <Span />
                <StyledText fontSize={10} opacity={0.6}>
                  {dayjs(Inquiry_content.writtenAt).format('YYYY.MM.DD')}
                </StyledText>
              </FlexRow>
            </Styled.TitleContainer>
            <Styled.Content dangerouslySetInnerHTML={{ __html: Inquiry_content.content }} />

            {Inquiry_content.response && (
              <>
                <SizedBoxH height={15} />
                <FlexRow>
                  <SizedBoxW width={10} />
                  <StyledText fontSize={16} fontWeight="bold" color={Colors.green}>
                    A. {Inquiry_content.response}
                  </StyledText>
                </FlexRow>
                <FlexRow>
                  <StyledText fontSize={10} opacity={0.6}>
                    {Inquiry_content.response.author}
                  </StyledText>
                  <Span />
                  <StyledText fontSize={10} opacity={0.6}>
                    {dayjs(Inquiry_content.response.writtenAt).format('YYYY.MM.DD')}
                  </StyledText>
                </FlexRow>
                <SizedBoxH height={20} />
                <Styled.Content dangerouslySetInnerHTML={{ __html: Inquiry_content.response.content }} />
              </>
            )}

            <SizedBoxH height={10} />
          </>
        )}

        {tab == 'ask' && (
          <Styled.InnerBox>
            {FAQ_faqs.map((e, i) => (
              <FAQInfoButton info={e} onClick={() => handleClickFAQ(e.id)} />
            ))}
            <SizedBoxH height={20} />
            <Pages curPage={FAQ_currentPage} pageCount={FAQ_maxPage} onPage={(page) => FAQ_setCurrentPage(page)} />
            <SizedBoxH height={20} />
          </Styled.InnerBox>
        )}

        {tab == 'inquiry' && (
          <Styled.InnerBox>
            {Inquiry_inquiries.map((e, i) => (
              <InquiryInfoButton info={e} onClick={() => handleClickFAQ(e.id)} />
            ))}
            <SizedBoxH height={20} />
            <Pages curPage={Inquiry_currentPage} pageCount={Inquiry_maxPage} onPage={(page) => Inquiry_setCurrentPage(page)} />
            <SizedBoxH height={20} />
          </Styled.InnerBox>
        )}
      </Styled.InnerContainer>
    </Styled.Container>
  );
});

const FAQInfoButton = (props: { info: BoardInfoDto; onClick: () => void }): ReactElement => {
  return (
    <Styled.InfoButton key={props.info.id} onClick={props.onClick}>
      <FlexColumn>
        <FlexRow>
          <StyledText fontSize={8} fontWeight="bold" color={Colors.gold}>
            [{props.info.category}]
          </StyledText>
          <SizedBoxW width={10} />
          <StyledText fontSize={8} opacity={0.6}>
            {props.info.author}
          </StyledText>
          <Span />
          <StyledText fontSize={8} opacity={0.6} textAlign="right">
            {dayjs(props.info.writtenAt).format('YYYY.MM.DD')}
          </StyledText>
        </FlexRow>
        <SizedBoxH height={5} />
        <OTF_B_12>{props.info.title}</OTF_B_12>
      </FlexColumn>
    </Styled.InfoButton>
  );
};

import ReplyIcon from 'img/Common/icon_reply.png';
import PrivateIcon from 'img/Common/icon_private.png';

const InquiryInfoButton = (props: { info: InquiryContentDto; onClick: () => void }): ReactElement => {
  return (
    <>
      <Styled.InfoButton key={props.info.id} onClick={props.onClick}>
        <FlexColumn>
          <FlexRow>
            <StyledText fontSize={8} fontWeight="bold" color={Colors.gold}>
              [{props.info.category}]
            </StyledText>
            <SizedBoxW width={10} />
            <StyledText fontSize={8} opacity={0.6}>
              {props.info.author}
            </StyledText>
            <Span />
            <StyledText fontSize={8} opacity={0.6} textAlign="right">
              {dayjs(props.info.writtenAt).format('YYYY.MM.DD')}
            </StyledText>
          </FlexRow>
          <SizedBoxH height={5} />
          <OTF_B_12>{props.info.title}</OTF_B_12>
        </FlexColumn>
      </Styled.InfoButton>
      {props.info.response && (
        <Styled.InfoButton>
          <FlexRow>
            <SizedImage src={ReplyIcon} width={20} height={20} />
            <SizedBoxW width={15} />
            <FlexColumn>
              <FlexRow>
                <StyledText fontSize={8} opacity={0.6}>
                  {props.info.response.author}
                </StyledText>
                <Span />
                <StyledText fontSize={8} opacity={0.6} textAlign="right">
                  {dayjs(props.info.response.writtenAt).format('YYYY.MM.DD')}
                </StyledText>
              </FlexRow>
              <SizedBoxH height={5} />
              <FlexRow>
                {props.info.response.private && (
                  <>
                    <SizedImage src={PrivateIcon} width={13} height={15} />
                    <SizedBoxW width={7} />
                  </>
                )}
                <OTF_B_12>{props.info.response.title}</OTF_B_12>
              </FlexRow>
            </FlexColumn>
          </FlexRow>
        </Styled.InfoButton>
      )}
    </>
  );
};
