import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from '../Common/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: pre-line;
  background-color: #161514;
  padding-top: ${vw(60)};
`;

export const GameIntroduceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${vw(10)};
  align-items: stretch;
  background-color: ${Colors.dark};
`;

export const GameIntroduceContainerInnerConainer = styled.div`
  width: 100%;
  padding: ${vw(20)} ${vw(10)};
  border-radius: ${vw(8)};
  background-color: ${Colors.gray};
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

export const GameIntroduceContainerInnerConainerInnerBox = styled.div`
  width: 100%;
  background-color: ${Colors.dark};
  padding: ${vw(10)};
  display: grid;
  grid-template-columns: ${vw(70)} ${vw(230)};
  grid-template-rows: auto;
  gap: ${vw(10)} ${vw(10)};
`;

export const PointInfoContainer = styled.div`
  padding: ${vw(10)};
  border-radius: ${vw(8)};
  background-color: ${Colors.gray};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const GameRewardsContainer = styled.div`
  width: ${vw(340)};
  height: ${vw(350)};
  padding: ${vw(30)} 0 0;
  border-radius: ${vw(8)};
  border: solid ${vw(2)} #e0be7a;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: ${vw(20)} ${vw(60)} ${vw(20)} ${vw(60)} ${vw(20)} ${vw(60)} ${vw(20)} ${vw(60)};
`;

export const GameRewardsCell = styled.div`
  border: solid ${vw(1)} #353535;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopButton = styled.div`
  width: ${vw(50)};
  height: ${vw(50)};
  padding: ${vw(10)};
  border-radius: ${vw(10)};
  box-shadow: 0 ${vw(4)} ${vw(8)} 0 #161514;
  background-color: #242322;
`;

export const WithdrawMenualContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - ${vw(200)});
  display: flex;
  flex-direction: column;
  padding: ${vw(20)} ${vw(10)};
  align-items: stretch;
  background-color: ${Colors.dark};
`;

export const WithdrawMenualContainerInnerBox = styled.div`
  padding: ${vw(10)};
  border-radius: ${vw(10)};
  background-color: ${Colors.gray};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
