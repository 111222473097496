import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from '../Common/Colors';

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
`;

export const SideMenu = styled.div<{ left: number; width: number }>`
  position: absolute;
  height: 100%;
  left: ${(props) => vw(-props.width + props.left)};
  width: ${(props) => vw(props.width)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 0 0 ${vw(1)} ${Colors.gold};
  background-color: ${Colors.gray};
  z-index: 1;
`;

export const SideMenuTop = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: ${vw(20)};
`;

export const SideMenuLink = styled.div`
  width: 100%;
  height: ${vw(50)};
  padding: ${vw(10)} ${vw(20)};
  box-shadow: inset 0 ${vw(-1)} 0 0 rgba(255, 255, 255, 0.08);
`;

export const SideMenuBottom = styled.div`
  width: 100%;
  height: ${vw(50)};
  padding: ${vw(10)};
  box-shadow: inset 0 ${vw(-1)} 0 0 rgba(255, 255, 255, 0.08);

  display: flex;
  align-items: center;
`;

export const LogoutButton = styled.button`
  width: ${vw(60)};
  height: ${vw(30)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
`;
