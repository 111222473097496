import _MAX1 from 'img/Common/membership_max1.png';
import _MAX2 from 'img/Common/membership_max2.png';
import _MAX3 from 'img/Common/membership_max3.png';
import _MAX4 from 'img/Common/membership_max4.png';
import _MAX5 from 'img/Common/membership_max5.png';

import _MAX1Small from 'img/Common/membership_max1_small.png';
import _MAX2Small from 'img/Common/membership_max2_small.png';
import _MAX3Small from 'img/Common/membership_max3_small.png';
import _MAX4Small from 'img/Common/membership_max4_small.png';
import _MAX5Small from 'img/Common/membership_max5_small.png';

import _SmallLogo from 'img/Common/small_logo.png';
import _MenuIcon from 'img/Main/menu.png';

export const MembershipIcons: { [key in MembershipType]: '*.png' } = {
  MAX1: _MAX1,
  MAX2: _MAX2,
  MAX3: _MAX3,
  MAX4: _MAX4,
  MAX5: _MAX5,
};

export const MembershipSmallIcons: { [key in MembershipType]: '*.png' } = {
  MAX1: _MAX1Small,
  MAX2: _MAX2Small,
  MAX3: _MAX3Small,
  MAX4: _MAX4Small,
  MAX5: _MAX5Small,
};

export const SmallLogo = _SmallLogo;
export const MenuIcon = _MenuIcon;
