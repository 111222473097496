import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';
import * as Styled from './Incentives.style';

import { OTF_B_10, OTF_B_12, OTF_B_16, OTF_B_24, OTF_R_10, OTF_R_12, StyledText } from '../Common/Typography';
import { getAccount, getBusinessAccount, getBusinessRewards, getBusinessTotalReward } from '../../api';
import { ButtonGoldOutlined, FlexColumn, FlexRow, SizedBoxH, SizedBoxW, SizedImage, Span } from '../Common/CommonStyle';
import { MenuIcon, SmallLogo } from '../Common/Imgaes';
import Colors from '../Common/Colors';
import dayjs from 'dayjs';
import Pages from '../Common/Pages';
import RightSideMenu from '../RightSideMenu/RightSideMenu';
import Header from '../Header/Header';

export default withRouter(function MyPage(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const [user, setUser] = useState<BusinessUserDto | null>();
  const [page, setPage] = useState<number>(0);

  const [totalReward, setTotalReward] = useState<{
    pointA: number;
    pointB: number;
    pointC: number;
    pointD: number;
  } | null>(null);
  const [rewards, setRewards] = useState<IncentiveDto[]>([]);

  const [menuOn, setMenuOn] = useState(false);

  useEffect(() => {
    (async () => {
      const accountType = localStorage.getItem('accountType');
      if (accountType) {
        if (accountType == 'normal') {
          alert('비즈니스 회원 전용 페이지입니다.');
          return;
        }
        if (accountType == 'business') {
          const user = await getBusinessAccount();
          if (!user) return;
          setUser(user);
        }
      } else {
        // 인증 정보가 없음
        alert(t('challenge-login-first'));
        props.history.push('/signin');
        return;
      }
    })();
    (async () => {
      const reward = await getBusinessTotalReward();
      if (reward) setTotalReward(reward);
    })();
    (async () => {
      const rewards = await getBusinessRewards();
      if (rewards) setRewards(rewards);
    })();
  }, []);

  return (
    <Styled.Container>
      <Header />

      <Styled.IncentivesContainer>
        <SizedBoxH height={30} />
        <FlexRow>
          <SizedBoxW width={10} />
          <StyledText fontSize={24} fontWeight="bold" color={Colors.gold}>
            인센티브 정산
          </StyledText>
        </FlexRow>
        <SizedBoxH height={30} />

        <Styled.IncentivesInnerBox>
          <Styled.IncentivesInnerBoxCols>
            <StyledText fontSize={10} fontWeight="bold" width={140}>
              포인트 / 회원권 상태 / 수당 분류
            </StyledText>
            <Span />
            {/* <SizedBoxW width={90} />
            <StyledText fontSize={10} fontWeight="bold" width={50} textAlign="center">
              회원상태
            </StyledText>
            <SizedBoxW width={10} /> */}
            <StyledText fontSize={10} fontWeight="bold" width={50} textAlign="center">
              수당 지급일
            </StyledText>
          </Styled.IncentivesInnerBoxCols>

          {rewards.slice(page * 10, Math.min((page + 1) * 10, rewards.length)).map((e) => (
            <Incentive info={e} />
          ))}

          <SizedBoxH height={20} />
          <Pages curPage={page} pageCount={Math.ceil(rewards.length / 10)} onPage={(page) => setPage(page)} />
          <SizedBoxH height={20} />
        </Styled.IncentivesInnerBox>

        <SizedBoxH height={40} />
        <Span />
        <FlexColumn alignItems="center">
          <ButtonGoldOutlined width={180} height={40} onClick={() => props.history.goBack()}>
            이전 화면으로 돌아가기
          </ButtonGoldOutlined>
        </FlexColumn>
        <SizedBoxH height={40} />
      </Styled.IncentivesContainer>
      {menuOn && <RightSideMenu onClose={() => setMenuOn(false)} />}
    </Styled.Container>
  );
});

const Incentive = (props: { info: IncentiveDto }) => {
  return (
    <Styled.IncentivesInnerBoxRow key={props.info.id}>
      <FlexColumn>
        <FlexRow>
          <StyledText fontSize={12} color={Colors.gold}>
            {props.info.point == 'pointA'
              ? '데모 포인트'
              : props.info.point == 'pointB'
              ? '토너먼트 포인트'
              : props.info.point == 'pointC'
              ? '라이브 포인트'
              : props.info.point == 'pointD'
              ? '이더리움 포인트'
              : '알 수 없는 포인트'}
          </StyledText>
          <StyledText fontSize={12}>{props.info.amount}p 지급</StyledText>
        </FlexRow>

        <SizedBoxH height={4} />
        <StyledText fontSize={10} opacity={0.6}>
          {props.info.reason}
        </StyledText>
      </FlexColumn>
      <Span />
      <StyledText width={50} fontSize={8} textAlign="center" opacity={0.6}>
        {dayjs(props.info.createdAt).format('YYYY.MM.DD')}
      </StyledText>
    </Styled.IncentivesInnerBoxRow>
  );
};
