import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from '../Common/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: pre-line;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: ${vw(90)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: ${vw(10)};
`;

export const NoticeContainer = styled.div`
  background-color: ${Colors.dark};
  min-height: 100vh;
  padding-top: ${vw(60)};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const NoticeInnerBox = styled.div`
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.5);
  background-color: ${Colors.gray};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const NoticeButton = styled.button`
  width: 100%;
  padding: ${vw(10)};
  box-shadow: inset 0 ${vw(-0.5)} 0 0 rgba(255, 255, 255, 0.15);
  border: none;
  background: none;
`;

export const NoticeContent = styled.div`
  width: 100%;
  padding: ${vw(20)} ${vw(10)} ${vw(40)};
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.5);
  background-color: ${Colors.gray};
  display: flex;
  flex-direction: column;
  align-items: stretch;

  font-family: NotoSansKR;
  font-size: ${vw(12)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: justify;
  color: #fff;
`;
