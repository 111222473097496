import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from '../Common/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: pre-line;
`;

export const Background = styled.div`
  height: ${vw(660)};
  position: relative;
`;

export const BackgroundImg = styled.img`
  width: ${vw(360)};
  height: ${vw(660)};
  position: absolute;
  top: 0;
`;

export const MainContainer = styled.div`
  height: ${vw(600)};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: ${vw(60)};
`;

export const MainLogo = styled.img`
  width: ${vw(120)};
  height: ${vw(120)};
  object-fit: contain;
`;

export const IntroduceContainer1 = styled.div`
  background-color: #161514;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${vw(60)} ${vw(30)};
`;

export const IntroduceCircle = styled.div`
  width: ${vw(60)};
  height: ${vw(60)};
  border-radius: ${vw(30)};
  background-color: #e0be7a;
`;

export const IntroduceContainer2 = styled.div`
  background-image: linear-gradient(to bottom, #1c1b1a, #161514);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${vw(60)} ${vw(30)};
`;

export const IntroduceContainer2InnerBox = styled.div`
  width: ${vw(300)};
  padding: ${vw(30)} ${vw(20)} ${vw(20)};
  border-radius: ${vw(8)};
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IntroduceContainer3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${vw(60)} ${vw(30)};
  background-color: #1c1b1a;
`;

export const IntroduceContainer3Screenshot = styled.img`
  width: ${vw(300)};
  height: ${vw(170)};
`;

export const IntroduceContainer4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${vw(60)} ${vw(30)};
  background-color: #161514;
`;

export const Footer = styled.div`
  padding: ${vw(40)} ${vw(30)} ${vw(60)};
  box-shadow: inset 0 1px 0 0 #c2a76c;
  background-color: #1c1b1a;
  display: flex;
  flex-direction: column;
`;
