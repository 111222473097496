import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from '../Common/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: pre-line;
`;

export const InnerContainer = styled.div`
  background-color: ${Colors.dark};
  min-height: 100vh;
  padding-top: ${vw(60)};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const InnerBox = styled.div`
  width: 100%;
  padding: ${vw(20)} ${vw(10)};
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.5);
  background-color: ${Colors.gray};
`;
