import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from '../Common/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${vw(40)} ${vw(10)};
  align-items: center;
  height: ${vw(760)};
  background-color: ${Colors.dark};
`;

export const MembershipButton = styled.button`
  width: ${vw(340)};
  height: ${vw(80)};
  padding: ${vw(10)};
  border-radius: ${vw(8)};
  box-shadow: 0 ${vw(4)} ${vw(8)} 0 rgba(0, 0, 0, 0.35);
  border: none;

  background-color: ${Colors.gray};
  :disabled {
    background-color: ${Colors.gold};
  }
`;

export const MembershipIcon = styled.img`
  width: ${vw(95)};
  height: ${vw(60)};
  object-fit: contain;
  background-color: transparent;
`;

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${vw(40)} 0;
  background-color: ${Colors.dark};
`;

export const Logo = styled.img`
  width: ${vw(60)};
  height: ${vw(60)};
  object-fit: contain;
`;

export const ConfirmInnerContainer = styled.div`
  width: 100%;
  height: ${vw(540)};
  padding: ${vw(20)} ${vw(10)};
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.35);
  background-color: ${Colors.gray};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ConfirmInnerContainerInnerBox = styled.div`
  padding: ${vw(10)};
  border-radius: ${vw(8)};
  background-color: ${Colors.dark};
  display: flex;
  flex-direction: column;
  white-space: pre-line;
`;
