import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';
import * as Styled from './Introduce.style';

import { OTF_B_16, OTF_R_12, StyledText } from '../Common/Typography';
import { FlexColumn, FlexRow, SizedBoxH, SizedBoxW, SizedImage, Span, TabButton } from '../Common/CommonStyle';

// images
import HeaderLogo from 'img/Main/header_logo.png';
import Colors from '../Common/Colors';
import ContinuousGameIcon from 'img/Game/game_continuous_gold.png';
import FixedGameIcon from 'img/Game/game_fixed_gold.png';
import LongIcon from 'img/Game/long_icon.png';
import ShortIcon from 'img/Game/short_icon.png';
import PointAIcon from 'img/Game/point_a.png';
import PointBIcon from 'img/Game/point_b.png';
import PointCIcon from 'img/Game/point_c.png';
import PointDIcon from 'img/Game/point_d.png';
import MenuIcon from 'img/Main/menu.png';

import { vw } from '../../Utils/Size';
import RightSideMenu from '../RightSideMenu/RightSideMenu';
import { signout } from '../../api';
import Header from '../Header/Header';

export default withRouter(function Introduce(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  const [tab, setTab] = useState<'game' | 'withdraw'>('game');
  const [menuOn, setMenuOn] = useState(false);

  return (
    <Styled.Container>
      <Header />
      <SizedBoxH height={30} />
      <StyledText fontSize={24} fontWeight={'bold'} color={' #e0be7a'} textAlign={'center'}>
        챌린지 이용방법
      </StyledText>
      <SizedBoxH height={30} />
      <FlexRow>
        <TabButton width={180} height={50} disabled={tab == 'game'} color={Colors.dark} onClick={() => setTab('game')}>
          교육시스템 이용방법
        </TabButton>
        <TabButton width={180} height={50} disabled={tab == 'withdraw'} color={Colors.dark} onClick={() => setTab('withdraw')}>
          입/출금 방법
        </TabButton>
      </FlexRow>

      {tab == 'game' && (
        <Styled.GameIntroduceContainer>
          <StyledText fontSize={12} lineHeight={1.67}>
            트레이딩 실력을 객관적으로 평가 받을 수 있도록 개발된 MAX4 플랫폼은 데모 챌린지, 토너먼트 챌린지, 라이브챌린지가 있으며 챌린지
            방식에는 연승 모드와 다승 모드가 있습니다. MAX4 챌린지는 1시간의 제한 시간 동안 트레이딩을 하게 되고 1회는 1분 동안
            진행되어지는데 처음 30초 동안은 Long, Short 둘 중에 하나를 선택하여(시간초과시 선택 불가능) 남은 30초동안 자신이 선택한
            BTC시세의 시점에 비하여 Long(가격이 높을 경우), Short(가격이 낮을 경우)을 예측하는 디지털 트레이딩 교육 프로그램입니다.
          </StyledText>

          <SizedBoxH height={38} />
          <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
            데모 챌린지
          </StyledText>
          <SizedBoxH height={10} />
          <StyledText fontSize={12} lineHeight={1.67}>
            무료 또는 회원 가입 시 지급받은 $10의 데모 포인트로 트레이딩 할 수 있으며 토너먼트 챌린지에서 사용할 수 있는 토너먼트 포인트를
            획득하기 하기 위한 챌린지입니다.
          </StyledText>
          <SizedBoxH height={10} />
          <StyledText fontSize={12} lineHeight={1.67}>
            참가 방법 : 회원 가입이나 회원 소개 시 $10에 해당하는 데모챌린지에 사용할 수 있는 데모 포인트를 받습니다.
          </StyledText>
          <SizedBoxH height={30} />
          <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
            토너먼트 챌린지
          </StyledText>
          <SizedBoxH height={10} />
          <StyledText fontSize={12} lineHeight={1.67}>
            $10의 토너먼트 포인트로 트레이딩 할 수 있으며 라이브 챌린지에 사용할 수 있는 라이브 포인트를 획득하기 위한 챌린지입니다.{' '}
          </StyledText>
          <SizedBoxH height={10} />
          <StyledText fontSize={12} lineHeight={1.67}>
            참가 방법 : $10의 토너먼트 포인트를 지불하거나 이더리움으로 토너먼트 포인트를 충전하여 참여가 가능합니다
          </StyledText>
          <SizedBoxH height={30} />

          <Styled.GameIntroduceContainerInnerConainer>
            <FlexColumn alignItems="center">
              <img
                src={ContinuousGameIcon}
                style={{
                  width: `${vw(208)}`,
                  height: `${vw(130)}`,
                }}
              />
            </FlexColumn>

            <SizedBoxH height={20} />

            <StyledText color={Colors.gold} fontSize={16} fontWeight="bold" textAlign="center">
              연승모드
            </StyledText>
            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67} textAlign="center">
              정확한 진입 시점을 연습하고 싶다면 연승 모드에 도전하시길 바랍니다.
            </StyledText>
            <SizedBoxH height={20} />
            <Styled.GameIntroduceContainerInnerConainerInnerBox>
              <OTF_R_12>챌린지 시간</OTF_R_12>
              <StyledText color={Colors.gold} fontSize={12} fontWeight="bold">
                1시간
              </StyledText>
              <OTF_R_12>챌린지 방법</OTF_R_12>
              <StyledText color={Colors.gold} fontSize={12} fontWeight="bold">
                정해진 시간 동안 손실 없이 연속적으로 몇 번의 수익을 달성하는지를 평가 받는 챌린지
              </StyledText>
            </Styled.GameIntroduceContainerInnerConainerInnerBox>

            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67}>
              BTC 시세 예측을 최대 20라운드까지 참가할 수 있습니다.
            </StyledText>
            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67}>
              20라운드 이내에 다음 라운드 진행을 포기하면 해당 라운드에 정해져있는 보상을 받을 수 있는 모드입니다.
            </StyledText>
            <SizedBoxH height={10} />
            <StyledText fontSize={10} opacity={0.5}>
              (*예측 실패시 전에 있던 모든 라운드에서 받을 수 있는 보상은 사라집니다.) (*10$ 참가비로 1회 참여 가능합니다.)
            </StyledText>
          </Styled.GameIntroduceContainerInnerConainer>
          <SizedBoxH height={10} />

          <Styled.GameIntroduceContainerInnerConainer>
            <FlexColumn alignItems="center">
              <img
                src={FixedGameIcon}
                style={{
                  width: `${vw(208)}`,
                  height: `${vw(130)}`,
                }}
              />
            </FlexColumn>

            <SizedBoxH height={20} />

            <StyledText color={Colors.gold} fontSize={16} fontWeight="bold" textAlign="center">
              다승모드
            </StyledText>
            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67} textAlign="center">
              승률을 높이는 연습을 하고 싶다면 다승 모드에 도전하시길 바랍니다.{' '}
            </StyledText>
            <SizedBoxH height={20} />
            <Styled.GameIntroduceContainerInnerConainerInnerBox>
              <OTF_R_12>챌린지 시간</OTF_R_12>
              <StyledText color={Colors.gold} fontSize={12} fontWeight="bold">
                1시간
              </StyledText>
              <OTF_R_12>챌린지 방법</OTF_R_12>
              <StyledText color={Colors.gold} fontSize={12} fontWeight="bold">
                정해진 시간 동안 몇 번의 수익을 달성하는지 평가받는 챌린지{' '}
              </StyledText>
            </Styled.GameIntroduceContainerInnerConainerInnerBox>

            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67}>
              BTC 시세 예측을 최대 20라운드까지 참가할 수 있으며, 중간에 예측이 틀려도 계속 참가할 수 있는 모드입니다.{' '}
            </StyledText>
            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67}>
              20라운드 중에 몇번이나 예측을 성공했는지에 따라서 보상이 주어집니다.{' '}
            </StyledText>
            <SizedBoxH height={10} />
            <StyledText fontSize={10} opacity={0.5}>
              (*예측 실패시 전에 있던 모든 라운드에서 받을 수 있는 보상은 사라집니다.) (*10$ 참가비로 1회 참여 가능합니다.){' '}
            </StyledText>
          </Styled.GameIntroduceContainerInnerConainer>

          <SizedBoxH height={40} />

          <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
            라이브 챌린지
          </StyledText>
          <SizedBoxH height={10} />
          <StyledText fontSize={12} lineHeight={1.67}>
            라이브 트레이딩은 토너먼트 챌린지를 참여하여 얻은 라이브 포인트로만 참여할 수 있습니다.
          </StyledText>
          <SizedBoxH height={10} />
          <StyledText fontSize={12} lineHeight={1.67}>
            트레이딩 방법 : 라이브 포인트 10P 사용하여 트레이딩 진행할 수 있으며, 해당 챌린지에서 얻은 보상은 이더리움 포인트로 지급되며
            이더리움 포인트는 USD $ 와 1:1 대응하여 회원님의 외부 이더리움 지갑으로 출금이 가능합니다. 출금 시 70% 수수료 공제 후 출금
            가능(100p단위로 출금 가능) 합니다.
          </StyledText>
          <SizedBoxH height={20} />
          <FlexRow>
            <img
              src={LongIcon}
              style={{
                width: `${vw(60)}`,
                height: `${vw(60)}`,
              }}
            />
            <SizedBoxW width={10} />
            <FlexColumn>
              <StyledText fontSize={16} fontWeight="bold" color={Colors.green}>
                Long
              </StyledText>
              <OTF_R_12>자신이 선택한 BTC 시세보다 라운드 종료시점에 시세가 높을 것 같으면 선택</OTF_R_12>
            </FlexColumn>
          </FlexRow>
          <SizedBoxH height={10} />
          <FlexRow>
            <img
              src={ShortIcon}
              style={{
                width: `${vw(60)}`,
                height: `${vw(60)}`,
              }}
            />
            <SizedBoxW width={10} />
            <FlexColumn>
              <StyledText fontSize={16} fontWeight="bold" color={Colors.red}>
                Short
              </StyledText>
              <OTF_R_12>자신이 선택한 BTC 시세보다 라운드 종료시점에 시세가 낮을 것 같으면 선택 </OTF_R_12>
            </FlexColumn>
          </FlexRow>
          <SizedBoxH height={20} />

          <Styled.PointInfoContainer>
            <FlexRow>
              <img
                src={PointAIcon}
                style={{
                  width: `${vw(30)}`,
                  height: `${vw(30)}`,
                }}
              />
              <SizedBoxW width={10} />
              <StyledText color={Colors.gold} fontWeight="bold" fontSize={16}>
                데모 포인트
              </StyledText>
            </FlexRow>
            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67}>
              회원가입 혹은 회원을 추천하였을때 주어지는 포인트 입니다. 해당 포인트로 데모챌린지를 참여할 수 있습니다.
            </StyledText>
          </Styled.PointInfoContainer>
          <SizedBoxH height={10} />
          <Styled.PointInfoContainer>
            <FlexRow>
              <img
                src={PointBIcon}
                style={{
                  width: `${vw(30)}`,
                  height: `${vw(30)}`,
                }}
              />
              <SizedBoxW width={10} />
              <StyledText color={Colors.gold} fontWeight="bold" fontSize={16}>
                토너먼트 포인트{' '}
              </StyledText>
            </FlexRow>
            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67}>
              데모챌린지에서 승리하였을때 주어지는 보상이며, 이더리움으로 충전이 가능하며, 해당 포인트로 토너먼트 챌린지 참여가 가능합니다.{' '}
            </StyledText>
          </Styled.PointInfoContainer>
          <SizedBoxH height={10} />
          <Styled.PointInfoContainer>
            <FlexRow>
              <img
                src={PointCIcon}
                style={{
                  width: `${vw(30)}`,
                  height: `${vw(30)}`,
                }}
              />
              <SizedBoxW width={10} />
              <StyledText color={Colors.gold} fontWeight="bold" fontSize={16}>
                라이브 포인트{' '}
              </StyledText>
            </FlexRow>
            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67}>
              토너먼트 챌린지에서 승리하였을때 주어지는 보상이며, 해당 포인트로 라이브 챌린지 참여가 가능합니다.{' '}
            </StyledText>
          </Styled.PointInfoContainer>
          <SizedBoxH height={10} />
          <Styled.PointInfoContainer>
            <FlexRow>
              <img
                src={PointAIcon}
                style={{
                  width: `${vw(30)}`,
                  height: `${vw(30)}`,
                }}
              />
              <SizedBoxW width={10} />
              <StyledText color={Colors.gold} fontWeight="bold" fontSize={16}>
                이더리움 포인트{' '}
              </StyledText>
            </FlexRow>
            <SizedBoxH height={10} />
            <StyledText fontSize={12} lineHeight={1.67}>
              라이브 챌린지에서 승리하였을때 주어지는 보상이며 해당 포인트는 달러와 1:1 대응되어 이더리움으로 출금이 가능한 포인트 입니다.{' '}
            </StyledText>
            <SizedBoxH height={5} />
            <StyledText opacity={0.5} fontSize={10}>
              (* 출금 수수료 50% 적용)
            </StyledText>
          </Styled.PointInfoContainer>

          <SizedBoxH height={40} />

          <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
            데모 챌린지 보상 포인트 지급제도
          </StyledText>
          <SizedBoxH height={20} />
          <OTF_B_16>연승모드</OTF_B_16>
          <SizedBoxH height={10} />
          <Styled.GameRewardsContainer>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
          </Styled.GameRewardsContainer>
          <SizedBoxH height={30} />
          <OTF_B_16>다승모드</OTF_B_16>
          <SizedBoxH height={10} />
          <Styled.GameRewardsContainer>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell>
              <OTF_B_16>1</OTF_B_16>
            </Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
            <Styled.GameRewardsCell></Styled.GameRewardsCell>
          </Styled.GameRewardsContainer>

          <SizedBoxH height={20} />

          <FlexRow>
            <Span />
            <Styled.TopButton>
              <FlexColumn justifyContent="center">
                <StyledText color={Colors.gold} fontSize={12} textAlign="center">
                  TOP
                </StyledText>
              </FlexColumn>
            </Styled.TopButton>
          </FlexRow>
        </Styled.GameIntroduceContainer>
      )}

      {tab == 'withdraw' && (
        <Styled.WithdrawMenualContainer>
          <StyledText fontSize={16} color={Colors.gold} fontWeight="bold">
            MAX4 교육시스템 입/출금 방법
          </StyledText>
          <SizedBoxH height={20} />

          <Styled.WithdrawMenualContainerInnerBox>
            <StyledText fontSize={12} color={Colors.gold} fontWeight="bold">
              입금
            </StyledText>
            <SizedBoxH height={5} />
            <StyledText fontSize={10} lineHeight={2}>
              Max4 교육시스템 연승 및 다승모드에서 2단계 챌린지모드에서 사용 가능한 포인트는 이더리움으로 충전이 가능합니다. 다른 단계에서는
              포인트 충전을 할 수 없으며, 오직 2단계 챌린지모드에서만 사용이 가능한 포인트로써 충전이 가능합니다.
            </StyledText>
          </Styled.WithdrawMenualContainerInnerBox>

          <SizedBoxH height={10} />

          <Styled.WithdrawMenualContainerInnerBox>
            <StyledText fontSize={12} color={Colors.gold} fontWeight="bold">
              출금{' '}
            </StyledText>
            <SizedBoxH height={5} />
            <StyledText fontSize={10} lineHeight={2}>
              Max4 교육시스템에서 받은 보상금은 오직 3단계 라이브모드 참여를 통해 얻은 보상만이 외부로 출금이 가능하며, 보상을 이더리움으로
              변환하여 회원지갑으로 출금이 가능합니다. 출금시 회원: 30% Max4: 70%의 비율로 수수료를 제외하고 출금이 됩니다.{' '}
            </StyledText>
          </Styled.WithdrawMenualContainerInnerBox>

          <SizedBoxH height={40} />
          <Span />
          <FlexRow>
            <Span />
            <Styled.TopButton>
              <FlexColumn justifyContent="center">
                <StyledText color={Colors.gold} fontSize={12} textAlign="center">
                  TOP
                </StyledText>
              </FlexColumn>
            </Styled.TopButton>
          </FlexRow>
        </Styled.WithdrawMenualContainer>
      )}

      {menuOn && <RightSideMenu onClose={() => setMenuOn(false)} />}
    </Styled.Container>
  );
});
