import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from '../Common/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: pre-line;
`;

export const IncentivesContainer = styled.div`
  background-color: ${Colors.dark};
  min-height: 100vh;
  padding-top: ${vw(60)};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const IncentivesInnerBox = styled.div`
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.5);
  background-color: ${Colors.gray};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const IncentivesInnerBoxCols = styled.div`
  width: 100%;
  height: ${vw(30)};
  box-shadow: inset 0 ${vw(-0.5)} 0 0 rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  padding: 0 ${vw(10)};
`;

export const IncentivesInnerBoxRow = styled.div`
  width: 100%;
  box-shadow: inset 0 ${vw(-0.5)} 0 0 rgba(255, 255, 255, 0.15);
  padding: ${vw(10)};
  display: flex;
  align-items: center;
`;

export const IncentiveMembershipApproved = styled.div<{ color: string }>`
  width: ${vw(50)};
  height: ${vw(30)};
  border-radius: ${vw(4)};
  border: solid ${vw(1)} ${(props) => props.color};
`;
