import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import TokenContext from '../../token-context';
import * as Styled from './MyPage.style';

import { OTF_B_10, OTF_B_12, OTF_B_16, OTF_B_24, OTF_R_10, OTF_R_12, StyledText } from '../Common/Typography';

import {
  ButtonGoldFilled,
  ButtonGoldOutlined,
  Circle,
  FlexColumn,
  FlexRow,
  Input,
  SizedBoxH,
  SizedBoxW,
  SizedImage,
  Span,
  ToggleButton,
  UnderlineTextButton,
} from '../Common/CommonStyle';

// images
import Logo from 'img/Common/small_logo.png';
import NormalAccount from 'img/Signup/signup_normal.png';
import BusinessAccount from 'img/Signup/signup_business.png';
import Colors from '../Common/Colors';
import { vw } from '../../Utils/Size';

import dayjs from 'dayjs';

export default withRouter(function MyPage(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  const [accountType, setAccountType] = useState<AccountType | null>();
  const [user, setUser] = useState<UserDto | BusinessUserDto | null>();

  const [pw, setPw] = useState<string>('');
  const [newPw, setNewPw] = useState<string>('');
  const [newPwConfirm, setNewPwConfirm] = useState<string>('');

  const [enquiryOn, setEnquiryOn] = useState<boolean>(false);
  const [enquiryTitle, setEnquiryTitle] = useState<string>('');
  const [enquiryContent, setEnquiryContent] = useState<string>('');
  const [enquiryIsPrivate, setEnquiryIsPrivate] = useState(false);

  const [exchanges, setExchanges] = useState<ExchangeDto[]>([]);
  // 현재 출금 신청할 포인트
  const [exchangePoint, setExchangePoint] = useState(0);
  const [exchangeFee, setExchangeFee] = useState(0);
  const [exchangeTab, setExchangeTab] = useState<'pending' | 'complete'>('pending');
  const [exchangeMaxCount, setExchangeMaxCount] = useState(5);

  const [exchangeLivePoint, setExchangeLivePoint] = useState(0);

  const [centralWallet, setCentralWallet] = useState('');

  const [editWalletOn, setEditWalletOn] = useState<boolean>(false);
  const [newWallet, setNewWallet] = useState<string>('');

  const [recommendUrl, setRecommendUrl] = useState('');

  const [showMembershipTerm, setShowMembershipTerm] = useState(false);
  const [agreeTermToggle, setAgreeTermToggle] = useState<boolean>(false);
  const [agreeTerm, setAgreeTerm] = useState<boolean>(false);

  const [membershipAgressTerm1, setMembershipAgreeTerm1] = useState(false);
  const [membershipAgressTerm2, setMembershipAgreeTerm2] = useState(false);

  const [showEmailSent, setShowEmailSent] = useState(false);

  const [recommendeesOn, setRecommendeesOn] = useState<boolean>(false);
  const [recommendeesPage, setRecommendeesPage] = useState<number>(0);
  const [recommendees, setRecommendees] = useState<string[] | null>(null);

  const [sales, setSales] = useState<{ level: number; count: number; sales: number }[]>([]);

  const [totalReward, setTotalReward] = useState<{
    pointA: number;
    pointB: number;
    pointC: number;
    pointD: number;
  } | null>(null);

  const [showExchangeSuccess, setShowExchangeSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      const accountType = localStorage.getItem('accountType');
      if (accountType) {
        setAccountType(accountType as AccountType);
        if (accountType == 'normal') {
          const user = await getAccount();
          if (!user) return;
          setRecommendUrl(`${window.location.origin}/signup?recommender=${user.username}&&accountType=business`);
          setUser(user);
        }
        if (accountType == 'business') {
          const user = await getBusinessAccount();
          if (!user) return;
          setRecommendUrl(`${window.location.origin}/signup?recommender=${user.username}&&accountType=business`);
          setUser(user);
        }
      } else {
        // 인증 정보가 없음
        alert(t('challenge-login-first'));
        props.history.push('/signin');
        return;
      }
    })();
    (async () => {
      const members = await getBusinessTree();
      if (!members) {
        console.log('tree 가 없음');
        return;
      }

      const me = members[0];
      const sales = getSales(me);
      for (let index = 0; index < 7; index++) {
        const sale = sales.find((e) => e.level == index + 1);
        if (!sale) sales.push({ level: index + 1, count: 0, sales: 0 });
      }
      setSales(sales);

      const recommendees = me.children
        .map((e, i) => {
          return e.username;
        })
        .filter((r) => r != '');
      setRecommendees(recommendees);
    })();
    (async () => {
      const fee = await getExchangeFee();

      if (fee === null) {
        props.history.push('/signin');
        return;
      }

      setExchangeFee(fee);
    })();
    (async () => {
      const exchanges = await listExchanges();
      if (exchanges) setExchanges(exchanges);
    })();
    (async () => {
      const centralWallet = await getCentralWallet();
      if (!centralWallet) {
        return;
      }
      setCentralWallet(centralWallet);
    })();

    (async () => {
      const reward = await getBusinessTotalReward();
      if (reward) setTotalReward(reward);
    })();
  }, []);

  async function changePw() {
    const pwValue = pw ?? '';
    const newPwValue = newPw ?? '';
    const newPwConfirmValue = newPwConfirm ?? '';
    if (!pwValue) {
      alert(t('signup-alert-pw-required'));
      return;
    }

    if (!newPwValue) {
      alert(t('signup-alert-pw-required'));
      return;
    }

    if (!newPwConfirmValue) {
      alert(t('signup-alert-pw-required'));
      return;
    }

    if (newPwValue.length < 6) {
      alert(t('signup-alert-pw-too-short'));
      return;
    }

    if (72 < new TextEncoder().encode(newPwValue).length) {
      alert(t('signup-alert-pw-too-long'));
      return;
    }

    if (newPwValue !== newPwConfirmValue) {
      alert(t('signup-alert-pw-not-match'));
      return;
    }

    const response = await changePassword(pwValue, newPwValue);

    if (!response) return;
    if (!response.success) {
      alert(t('mypage-check-password'));
      return;
    }

    alert(t('mypage-change-password-successful'));
  }

  async function handleInquiry() {
    if (!(enquiryTitle ?? '').trim()) {
      alert(t('mypage-enter-title'));
      return;
    }

    if (!(enquiryContent ?? '').trim()) {
      alert(t('mypage-enter-content'));
      return;
    }

    await newInquiry(t('mypage-enquiry-etc'), enquiryTitle, enquiryContent, enquiryIsPrivate);

    window.location.reload();
  }

  async function handleExchange() {
    if (exchangePoint < 100) {
      alert(t('mypage-alert-insufficient-expoint'));
      return;
    }

    if ((user?.pointD ?? 0) < exchangePoint) {
      alert(t('mypage-alert-insufficient-pointd'));
      return;
    }

    if (await newExchangeRequest(exchangePoint)) setShowExchangeSuccess(true);
  }

  async function handleExchangeLivePoint() {
    if (exchangeLivePoint < 10) {
      alert(t('mypage-alert-insufficient-expoint'));
      return;
    }

    if ((user?.pointC ?? 0) < exchangeLivePoint) {
      alert(t('mypage-alert-insufficient-pointc'));
      return;
    }

    if (await newExchangeLivePointRequest(exchangeLivePoint)) setShowExchangeSuccess(true);
  }

  async function onEditWallet() {
    const newWalletValue = newWallet ?? '';

    if (!newWalletValue) {
      alert(t('signup-alert-new-wallet-required'));
      return;
    }

    (async () => {
      const response = await editWallet(newWalletValue);

      if (!response) return;
      if (!response.success) {
        alert(t('mypage-check-new-wallet'));
        return;
      }

      alert('지갑주소 변경 신청이 완료되었습니다. 등록된 이메일을 확인해주세요.');
      location.reload();
    })();
  }

  function copyText(text: string) {
    if (!document.queryCommandSupported('copy')) {
      return alert(t('cannot-copy'));
    }

    return alert(t('copy-success'));
  }

  return (
    <>
      <Styled.Container>
        <Header />

        <Styled.MyPageContainer>
          <Styled.MyPageInnerBox>
            <FlexRow alignItems="center">
              <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
                비밀번호 변경
              </StyledText>
            </FlexRow>

            <SizedBoxH height={20} />

            <OTF_B_12>비밀번호</OTF_B_12>
            <SizedBoxH height={10} />
            <Input
              height={30}
              placeholder={t('mypage-input-password')}
              type="password"
              value={pw}
              onChange={(e) => setPw(e.target.value)}
            />

            <SizedBoxH height={10} />

            <OTF_B_12>새 비밀번호</OTF_B_12>
            <SizedBoxH height={10} />
            <Input
              height={30}
              placeholder={t('mypage-input-new-password')}
              type="password"
              value={newPw}
              onChange={(e) => setNewPw(e.target.value)}
            />

            <SizedBoxH height={10} />

            <OTF_B_12>비밀번호 확인</OTF_B_12>
            <SizedBoxH height={10} />
            <Input
              height={30}
              placeholder={t('mypage-input-new-password')}
              type="password"
              value={newPwConfirm}
              onChange={(e) => setNewPwConfirm(e.target.value)}
            />

            <SizedBoxH height={20} />
            <FlexColumn alignItems="center">
              <ButtonGoldFilled width={120} height={30} fontSize={12} onClick={changePw}>
                변경하기
              </ButtonGoldFilled>
            </FlexColumn>

            <SizedBoxH height={20} />
            <FlexRow alignItems="flex-end">
              <StyledText fontSize={10} opacity={0.65}>
                {`아이디: ${user?.username}\n이메일: ${user?.email}`}
              </StyledText>
              <Span />
              <UnderlineTextButton color={Colors.gold} weight="bold" fontSize={12} opacity={0.65}>
                문의하기
              </UnderlineTextButton>
            </FlexRow>
          </Styled.MyPageInnerBox>

          {accountType == 'business' && user && (
            <>
              <SizedBoxH height={10} />

              <Styled.MyPageInnerBox>
                <FlexRow alignItems="center">
                  <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
                    내 회원권
                  </StyledText>
                  {(user as BusinessUserDto).wasMembershipApproved && (
                    <>
                      <SizedBoxW width={10} />
                      <StyledText fontSize={10} color={Colors.green}>
                        정회원 승인 완료
                      </StyledText>
                    </>
                  )}
                </FlexRow>
                <SizedBoxH height={20} />
                {(user as BusinessUserDto).membership ? (
                  <FlexRow alignItems="center">
                    <SizedBoxW width={30} />
                    <SizedImage width={160} height={100} src={MembershipIcons[(user as BusinessUserDto).membership!]} />
                    <Span />
                    <StyledText fontSize={24} color={Colors.gold} fontWeight="bold">
                      {(user as BusinessUserDto).membership}
                    </StyledText>
                    <SizedBoxW width={30} />
                  </FlexRow>
                ) : (
                  <Styled.NoMembership>
                    <OTF_B_16>회원권이 없습니다</OTF_B_16>
                    <StyledText fontSize={11}>회원권을 통해 더 많은 혜택을 제공받으세요!</StyledText>
                  </Styled.NoMembership>
                )}
                <SizedBoxH height={20} />
                <Link to="/purchase-membership">
                  <ButtonGoldOutlined height={40} fontSize={12}>
                    회원권 변경하기
                  </ButtonGoldOutlined>
                </Link>
              </Styled.MyPageInnerBox>
            </>
          )}

          <SizedBoxH height={10} />

          <Styled.MyPageInnerBox>
            <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
              추천인
            </StyledText>
            <SizedBoxH height={20} />
            <FlexRow>
              <div style={{ width: `${vw(100)}` }}>
                <OTF_B_12>내가 추천한 유저</OTF_B_12>
              </div>
              <UnderlineTextButton fontSize={12} color={Colors.gold} weight="bold">
                {recommendees?.length}명
              </UnderlineTextButton>
            </FlexRow>
            <SizedBoxH height={10} />
            <OTF_B_12>내 추천링크</OTF_B_12>
            <UnderlineTextButton fontSize={10} color={Colors.gold}>
              {recommendUrl}
            </UnderlineTextButton>
            <SizedBoxH height={10} />
            <FlexRow>
              <ButtonGoldOutlined width={80} height={30} fontSize={12} onClick={() => copyText(recommendUrl)}>
                복사하기
              </ButtonGoldOutlined>
            </FlexRow>
          </Styled.MyPageInnerBox>

          {accountType == 'business' && user && (
            <>
              <SizedBoxH height={10} />

              <Styled.MyPageInnerBox>
                <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
                  인센티브 정산 개요
                </StyledText>
                <SizedBoxH height={20} />
                <OTF_B_12>지급받은 …</OTF_B_12>
                <SizedBoxH height={10} />
                <Styled.BusinessIncentivePoints>
                  <StyledText fontSize={12} fontWeight="bold" color={Colors.gold}>
                    총 데모 포인트 수당
                  </StyledText>
                  <OTF_B_12>{totalReward?.pointA}p</OTF_B_12>
                  <StyledText fontSize={12} fontWeight="bold" color={Colors.gold}>
                    총 토너먼트 포인트 수당
                  </StyledText>
                  <OTF_B_12>{totalReward?.pointB}p</OTF_B_12>
                  <StyledText fontSize={12} fontWeight="bold" color={Colors.gold}>
                    총 라이브 포인트 수당
                  </StyledText>
                  <OTF_B_12>{totalReward?.pointC}p</OTF_B_12>
                  <StyledText fontSize={12} fontWeight="bold" color={Colors.gold}>
                    총 이더리움 포인트 수당
                  </StyledText>
                  <OTF_B_12>{totalReward?.pointD}p</OTF_B_12>
                </Styled.BusinessIncentivePoints>
                <SizedBoxH height={10} />
                <Link to="incentives">
                  <ButtonGoldOutlined width={160} height={30} fontSize={12}>
                    인센티브 정산 내역
                  </ButtonGoldOutlined>
                </Link>
                {(user as BusinessUserDto).membership != null && !(user as BusinessUserDto).wasMembershipApproved && (
                  <>
                    <SizedBoxH height={20} />
                    <OTF_B_12>정회원 즉시 승인</OTF_B_12>
                    <SizedBoxH height={10} />
                    <Styled.BusinessApproveMembershipBox>
                      <StyledText fontSize={12} fontWeight="bold" color={Colors.gold}>
                        정회원, 준회원 시스템
                      </StyledText>
                      <SizedBoxH height={10} />
                      <OTF_R_10>
                        회원권 구매를 하면 ‘준회원’으로 되며, ‘준회원’은 회원권 입금이 확인되고 15일 이후 ‘정회원’으로 자동 승격됩니다.
                      </OTF_R_10>
                      <SizedBoxH height={10} />
                      <OTF_R_10>
                        준회원은 회원권 구매로 인한 각종 수당을 지급받으나 정회원으로 승격이 된 이후에 출금하거나 사용할 수 있습니다.
                      </OTF_R_10>
                      <SizedBoxH height={10} />
                      <OTF_R_10>MAX4 정회원 즉시 승인 약관에 동의하면 15일을 기다리지 않고 즉시 ‘정회원’으로 승격이 가능합니다.</OTF_R_10>
                      <SizedBoxH height={10} />
                      <ButtonGoldOutlined width={160} height={30} fontSize={12}>
                        정회원 즉시 승인요청
                      </ButtonGoldOutlined>
                    </Styled.BusinessApproveMembershipBox>
                  </>
                )}
              </Styled.MyPageInnerBox>
            </>
          )}

          <SizedBoxH height={10} />

          <Styled.MyPageInnerBox>
            <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
              출금 내역
            </StyledText>
            <SizedBoxH height={20} />
            <FlexRow>
              <Styled.WithdrawTabButton disabled={exchangeTab == 'pending'} onClick={() => setExchangeTab('pending')}>
                출금 진행중
              </Styled.WithdrawTabButton>
              <Styled.WithdrawTabButton disabled={exchangeTab == 'complete'} onClick={() => setExchangeTab('complete')}>
                출금 완료
              </Styled.WithdrawTabButton>
            </FlexRow>
            <SizedBoxH height={5} />
            {exchanges
              .filter((e) => e.status == exchangeTab)
              .slice(0, exchangeMaxCount)
              .map((e, i) => {
                return (
                  <>
                    <SizedBoxH height={5} />
                    <WithdrawInfo info={e} tab={exchangeTab} />
                  </>
                );
              })}
          </Styled.MyPageInnerBox>

          <SizedBoxH height={10} />

          <Styled.MyPageInnerBox>
            <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
              내 지갑 관리
            </StyledText>
            <SizedBoxH height={20} />
            <OTF_B_12>내 포인트</OTF_B_12>
            <SizedBoxH height={10} />
            <Styled.MyWalletInnerBox>
              <FlexRow alignItems="center">
                <Circle size={6} color={Colors.gold} />
                <SizedBoxW width={12} />
                <StyledText width={130} fontSize={12} fontWeight="bold" color={Colors.gold}>
                  데모 포인트
                </StyledText>
                <OTF_B_12>0p</OTF_B_12>
              </FlexRow>
              <SizedBoxH height={20} />
              <FlexRow alignItems="center">
                <Circle size={6} color={Colors.gold} />
                <SizedBoxW width={12} />
                <StyledText width={130} fontSize={12} fontWeight="bold" color={Colors.gold}>
                  토너먼트 포인트{' '}
                </StyledText>
                <OTF_B_12>0p</OTF_B_12>
              </FlexRow>
              <SizedBoxH height={20} />
              <FlexRow alignItems="center">
                <Circle size={6} color={Colors.gold} />
                <SizedBoxW width={12} />
                <StyledText width={130} fontSize={12} fontWeight="bold" color={Colors.gold}>
                  라이브 포인트{' '}
                </StyledText>
                <OTF_B_12>0p</OTF_B_12>
              </FlexRow>
              <SizedBoxH height={20} />
              <FlexRow alignItems="center">
                <Circle size={6} color={Colors.gold} />
                <SizedBoxW width={12} />
                <StyledText width={130} fontSize={12} fontWeight="bold" color={Colors.gold}>
                  이더리움 포인트{' '}
                </StyledText>
                <OTF_B_12>0p</OTF_B_12>
              </FlexRow>
            </Styled.MyWalletInnerBox>

            <SizedBoxH height={20} />
            <OTF_B_12>라이브 포인트 환전하기</OTF_B_12>
            <SizedBoxH height={10} />
            <Styled.MyWalletInnerBox>
              <FlexRow>
                <StyledText fontSize={12} fontWeight="bold" color={Colors.gold} width={140}>
                  환전가능 라이브 포인트
                </StyledText>
                <SizedBoxW width={10} />
                <OTF_B_12>0p</OTF_B_12>
              </FlexRow>
              <SizedBoxH height={10} />
              <Input width={150} height={30} />
              <SizedBoxH height={10} />
              <StyledText color={Colors.red} fontSize={10}>
                *최소 10p부터 환전 가능합니다.
              </StyledText>
              <StyledText fontSize={10} color="#aeaeae">
                *환전 시 50%의 수수료가 발생합니다.
              </StyledText>
              <SizedBoxH height={10} />
              <ButtonGoldFilled width={300} height={40}>
                환전 신청
              </ButtonGoldFilled>
            </Styled.MyWalletInnerBox>

            <SizedBoxH height={20} />
            <OTF_B_12>출금하기</OTF_B_12>
            <SizedBoxH height={10} />
            <Styled.MyWalletInnerBox>
              <FlexRow>
                <StyledText fontSize={12} fontWeight="bold" color={Colors.gold} width={140}>
                  출금 가능 이더리움 포인트
                </StyledText>
                <SizedBoxW width={10} />
                <OTF_B_12>0p</OTF_B_12>
              </FlexRow>
              <SizedBoxH height={10} />
              <FlexRow>
                <Input width={150} height={30} />
                <SizedBoxW width={10} />
                <OTF_B_16>=</OTF_B_16>
                <SizedBoxW width={10} />
                <StyledText color={Colors.red} fontSize={16} fontWeight="bold">
                  0$
                </StyledText>
              </FlexRow>
              <SizedBoxH height={10} />
              <StyledText color={Colors.red} fontSize={10}>
                *최소 100$부터 환전 가능합니다.
              </StyledText>
              <StyledText fontSize={10} color="#aeaeae">
                *출금시 5%의 수수료가 발생합니다.
              </StyledText>
              <SizedBoxH height={20} />
              <FlexRow>
                <StyledText color="#aeaeae" fontSize={10}>
                  수수료
                </StyledText>
                <Span />
                <StyledText color="#aeaeae" fontSize={10}>
                  00.00 $
                </StyledText>
              </FlexRow>
              <SizedBoxH height={10} />
              <FlexRow>
                <StyledText color="#aeaeae" fontSize={10}>
                  수수료
                </StyledText>
                <Span />
                <StyledText color="#aeaeae" fontSize={10}>
                  00.00 $
                </StyledText>
              </FlexRow>
              <SizedBoxH height={20} />
              <StyledText color={Colors.red} fontSize={12} fontWeight="bold">
                * 출금시 참고사항
              </StyledText>
              <OTF_R_10>
                - 이더리움 포인트 출금시 USD 달러와 1:1 대응되어 수수료를 제외한 금액만큼 관리자의 승인 후 이더리움으로 환전되어 내 지갑
                주소로 입금됩니다.
              </OTF_R_10>
              <SizedBoxH height={10} />
              <ButtonGoldFilled width={300} height={40}>
                출금 신청
              </ButtonGoldFilled>
            </Styled.MyWalletInnerBox>

            <SizedBoxH height={20} />
            <OTF_B_12>내 입출금 지갑 관리</OTF_B_12>
            <SizedBoxH height={10} />
            <Styled.MyWalletInnerBox>
              <StyledText fontSize={12} fontWeight="bold" color={Colors.gold} width={140}>
                지갑 주소{' '}
              </StyledText>
              <OTF_R_12>0xAb5801a7D398351b8bE11C439e05C5B3259aeC9B</OTF_R_12>
              <SizedBoxH height={10} />
              <FlexRow>
                <ButtonGoldOutlined width={100} height={30} fontSize={12}>
                  복사하기
                </ButtonGoldOutlined>
                <SizedBoxW width={10} />
                <ButtonGoldOutlined width={190} height={30} fontSize={12}>
                  지갑 주소 변경
                </ButtonGoldOutlined>
              </FlexRow>
              <SizedBoxH height={10} />
              <StyledText color={Colors.gold} fontSize={12} fontWeight="bold">
                누적 총 출금 금액
              </StyledText>
              <OTF_R_12>300,000 $</OTF_R_12>
            </Styled.MyWalletInnerBox>

            <SizedBoxH height={20} />
            <OTF_B_12>토너먼트 포인트 충전용 이더리움 입금 주소</OTF_B_12>
            <SizedBoxH height={10} />
            <Styled.MyWalletInnerBox>
              <StyledText fontSize={12} fontWeight="bold" color={Colors.gold} width={140}>
                지갑 주소{' '}
              </StyledText>
              <OTF_R_12>0xAb5801a7D398351b8bE11C439e05C5B3259aeC9B</OTF_R_12>
              <SizedBoxH height={10} />
              <ButtonGoldOutlined width={100} height={30} fontSize={12}>
                복사하기
              </ButtonGoldOutlined>
              <SizedBoxH height={10} />
              <StyledText color={Colors.red} fontSize={10}>
                * 입금 주소를 잘못 입력하여 전송한 경우 되찾을 수 없습니다!
              </StyledText>
              <SizedBoxH height={10} />
              <FlexRow>
                <StyledText width={50} color={Colors.gold} fontSize={12} fontWeight="bold">
                  교환비
                </StyledText>
                <OTF_R_12>10$ ⇢ 10p</OTF_R_12>
              </FlexRow>
              <StyledText color="#aeaeae" fontSize={10}>
                *이더리움 가격은 거래소 기준으로 실시간으로 반영되며 포인트 교환은 전송 된 시점의 이더리움 가격으로 결정됩니다.
              </StyledText>
            </Styled.MyWalletInnerBox>
          </Styled.MyPageInnerBox>

          {accountType == 'business' && (
            <>
              <SizedBoxH height={10} />

              <Styled.MyPageInnerBox>
                <StyledText color={Colors.gold} fontSize={16} fontWeight="bold">
                  Total Group Info
                </StyledText>

                <SizedBoxH height={20} />
                <FlexRow>
                  <SizedBoxW width={10} />
                  <StyledText width={50} fontSize={12} fontWeight="bold">
                    Level
                  </StyledText>
                  <SizedBoxW width={20} />
                  <StyledText width={70} fontSize={12} fontWeight="bold" textAlign="center">
                    Partners
                  </StyledText>
                  <Span />
                  <StyledText fontSize={12} fontWeight="bold" textAlign="right">
                    Volume
                  </StyledText>
                  <SizedBoxW width={10} />
                </FlexRow>
                <FlexColumn>
                  {sales.map((e, i) => {
                    if (e.level == 0) return <></>;
                    return (
                      <Styled.IncentiveBox>
                        <FlexRow>
                          <StyledText width={50} fontSize={12} fontWeight="bold" color={Colors.gold}>
                            Lv. {e.level}
                          </StyledText>
                          <SizedBoxW width={20} />
                          <StyledText width={70} fontSize={12} fontWeight="bold" textAlign="center">
                            {e.count}명
                          </StyledText>
                          <Span />
                          <StyledText fontSize={12} fontWeight="bold" textAlign="right">
                            {e.sales}$
                          </StyledText>
                        </FlexRow>
                      </Styled.IncentiveBox>
                    );
                  })}
                </FlexColumn>
              </Styled.MyPageInnerBox>
            </>
          )}
        </Styled.MyPageContainer>
      </Styled.Container>
    </>
  );
});

import Arrow from 'img/MyPage/withdraw_arrow.png';
import PointDIcon from 'img/Game/point_d.png';
import {
  changePassword,
  editWallet,
  getAccount,
  getBusinessAccount,
  getBusinessTotalReward,
  getBusinessTree,
  getCentralWallet,
  getExchangeFee,
  listExchanges,
  newExchangeLivePointRequest,
  newExchangeRequest,
  newInquiry,
  resetPassword,
} from '../../api';
import { MembershipIcons } from '../Common/Imgaes';
import Header from '../Header/Header';

const WithdrawInfo = (props: { info: ExchangeDto; tab: 'pending' | 'complete' }) => {
  return (
    <Styled.StyledWithdrawInfo>
      <FlexRow alignItems="center">
        <FlexColumn>
          <FlexRow alignItems="center">
            <img src={PointDIcon} style={{ width: `${vw(20)}`, height: `${vw(20)}` }} />
            <SizedBoxW width={4} />
            <OTF_B_12>{props.info.point}</OTF_B_12>
            <SizedBoxW width={8} />
            <img src={Arrow} style={{ width: `${vw(20)}`, height: `${vw(10)}` }} />
            <SizedBoxW width={8} />
            <StyledText fontSize={12} fontWeight="bold" color={Colors.gold}>
              {props.info.point * (1 - props.info.fee / 100)}$
            </StyledText>
          </FlexRow>
          <SizedBoxH height={10} />
          <StyledText opacity={0.65} fontSize={10}>
            {dayjs(props.info.requestedAt).format('YYYY년 M월 D일 A hh:mm')}
          </StyledText>
        </FlexColumn>
        <Span />
        <StyledText color={props.tab == 'pending' ? Colors.gold : Colors.green} fontSize={12} fontWeight="bold">
          {props.tab == 'pending' ? '출금 신청됨' : '출금 완료'}
        </StyledText>
        <SizedBoxW width={10} />
        <Circle size={10} color={props.tab == 'pending' ? Colors.gold : Colors.green} />
      </FlexRow>
    </Styled.StyledWithdrawInfo>
  );
};

function getSales(member: StructedUserTree): { level: number; count: number; sales: number }[] {
  const price =
    member.membership == 'MAX1'
      ? 100
      : member.membership == 'MAX2'
      ? 500
      : member.membership == 'MAX3'
      ? 1000
      : member.membership == 'MAX4'
      ? 5000
      : member.membership == 'MAX5'
      ? 10000
      : 0;
  const sales: { level: number; count: number; sales: number }[] = [{ level: 0, count: 1, sales: price }];

  if (member.children.length > 0) {
    member.children.forEach((child) => {
      getSales(child).forEach((childSale) => {
        const sale = sales.find((e) => e.level == childSale.level + 1);
        if (sale) {
          sale.count += childSale.count;
          sale.sales += childSale.sales;
        } else {
          sales.push({
            level: childSale.level + 1,
            count: childSale.count,
            sales: childSale.sales,
          });
        }
      });
    });
  }
  return sales;
}
