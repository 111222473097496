import styled from 'styled-components';
import { vw } from '../../Utils/Size';

export const Container = styled.div`
  width: 100%;
  height: ${vw(760)};
  padding: ${vw(40)} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  background-color: #1c1b1a;
`;

export const Logo = styled.img`
  width: ${vw(60)};
  height: ${vw(60)};
  object-fit: contain;
`;

export const InnerBox = styled.div`
  width: ${vw(340)};
  height: ${vw(160)};
  padding: ${vw(20)} ${vw(10)} ${vw(10)} ${vw(10)};
  border-radius: ${vw(8)};
  box-shadow: 0 ${vw(4)} ${vw(8)} 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
`;

export const InnerBoxIcon = styled.img<{ width: number; height: number }>`
  width: ${(props) => vw(props.width)};
  height: ${(props) => vw(props.height)};
  object-fit: contain;
  background-color: transparent;
`;

export const TermsContainer = styled.div`
  width: 100%;
  height: ${vw(780)};
  padding: 0 0 ${vw(40)} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  background-color: #1c1b1a;
`;

export const TermsInnerBox = styled.div`
  width: 100%;
  height: ${vw(660)};
  padding: ${vw(20)} ${vw(10)};
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
`;

export const TermsScroll = styled.div`
  width: 100%;
  height: ${vw(500)};
  padding: ${vw(10)};
  border-radius: ${vw(8)};
  background-color: #1c1b1a;
  overflow: auto;
`;

export const SignupContainer = styled.div`
  width: 100%;
  padding: ${vw(40)} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  background-color: #1c1b1a;
`;

export const SignupInnerBox = styled.div`
  width: 100%;
  padding: ${vw(20)} ${vw(10)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
`;
