import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';
import * as Styled from './Signup.style';

import { OTF_B_10, OTF_B_12, OTF_B_16, OTF_B_24, OTF_R_12, StyledText } from '../Common/Typography';

import {
  ButtonGoldFilled,
  ButtonGoldOutlined,
  FlexColumn,
  FlexRow,
  Input,
  SizedBoxH,
  SizedBoxW,
  Span,
  ToggleButton,
} from '../Common/CommonStyle';

// images
import Logo from 'img/Common/small_logo.png';
import NormalAccount from 'img/Signup/signup_normal.png';
import BusinessAccount from 'img/Signup/signup_business.png';
import Icon_KR from 'img/Signup/korea.png';
import Icon_Vietnam from 'img/Signup/vietnam.png';
import validator from 'validator';
import { checkEmailUniqueness, checkPhoneUniqueness, checkUsernameUniqueness, checkWalletUniqueness, signup } from '../../api';

// const CountryInfo = [
//   { number: '+82', icon: Icon_KR },
//   { number: '+84', icon: Icon_Vietnam },
// ];

export default withRouter(function Signup(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  const [termsAgreeIsOn, setTermsAgreeIsOn] = useState<boolean>(false);
  const [termsAgree, setTermsAgree] = useState<boolean>(false);

  const [accountType, setAccountType] = useState<AccountType | null>(null);

  const [id, setId] = useState('');
  const [idValidation, setIdValidation] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValidation, setEmailValidation] = useState(false);
  const [pw, setPw] = useState('');
  const [pwConfirm, setPwConfirm] = useState('');
  const [phone, setPhone] = useState('');
  const [recommender, setRecommender] = useState('');

  // const [currentCountry, setCurrentCountry] = useState<number>(0);
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [walletValidation, setWalletValidation] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function onSignup() {
    const idValue = (id ?? '').trim();
    const emailValue = (email ?? '').trim();
    const pwValue = pw ?? '';
    const pwConfirmValue = pwConfirm ?? '';
    const phoneValue = (phone ?? '').trim();
    let recommenderValue = (recommender ?? '').trim();
    const walletAddressValue = (walletAddress ?? '').trim();

    if (!idValue) {
      alert(t('signup-alert-id-required'));
      return;
    }

    if (!idValidation) {
      alert(t('signup-alert-id-confirm-required'));
      return;
    }

    if (!emailValue) {
      alert(t('signup-alert-email-required'));
      return;
    }

    if (!emailValidation) {
      alert(t('signup-alert-email-confirm-required'));
      return;
    }

    if (!pwValue) {
      alert(t('signup-alert-pw-required'));
      return;
    }

    if (!pwConfirmValue) {
      alert(t('signup-alert-pw-confirm-required'));
      return;
    }

    if (!phoneValue) {
      alert(t('signup-alert-phone-required'));
      return;
    }

    if (!phoneValidation) {
      alert(t('signup-alert-phone-confirm-required'));
      return;
    }

    if (!validator.isEmail(emailValue)) {
      alert(t('signup-alert-invalid-email'));
      return;
    }

    if (pwValue.length < 6) {
      alert(t('signup-alert-pw-too-short'));
      return;
    }

    if (72 < new TextEncoder().encode(pwValue).length) {
      alert(t('signup-alert-pw-too-long'));
      return;
    }

    if (pwValue !== pwConfirmValue) {
      alert(t('signup-alert-pw-not-match'));
      return;
    }

    if (recommenderValue) {
      const response = await checkUsernameUniqueness(recommenderValue, accountType!);

      if (!response) return;

      if (response.uniqueness) {
        alert(t('signup-alert-recommender-not-exists'));
        return;
      }
    } else {
      if (accountType == 'business') recommenderValue = 'MAX4';
    }

    if (!walletAddressValue) {
      alert(t('signup-alert-wallet-address-required'));
      return;
    }

    const response2 = await signup(
      idValue,
      emailValue,
      pwValue,
      phoneValue,
      // CountryInfo[currentCountry].number + phoneValue,
      recommenderValue,
      walletAddressValue,
      accountType!,
    );

    console.log(accountType);

    if (!response2) return;

    if (!response2.noConflict) {
      alert(t('signup-alert-conflict'));
      return;
    }

    if (response2.noConflict.badWalletAddress) {
      alert(t('signup-alert-bad-wallet-address'));
      return;
    }

    alert(t('signup-alert-successful'));

    props.history.push('/signin');
  }

  async function confirmId() {
    // 서버에서 중복확인해서 오케이면
    const result = await checkUsernameUniqueness((id ?? '').trim(), accountType!);

    if (!result) return;

    if (result.uniqueness) {
      alert(t('signup-id-available'));
      setIdValidation(true);
    } else {
      alert(t('signup-id-not-available'));
    }
  }

  async function confirmEmail() {
    const result = await checkEmailUniqueness((email ?? '').trim(), accountType!);

    if (!result) return;

    if (result.uniqueness) {
      alert(t('signup-email-available'));
      setEmailValidation(true);
    } else {
      alert(t('signup-email-not-available'));
    }
  }

  async function confirmPhone() {
    const phoneValue = (phone ?? '').trim();

    if (!phoneValue) {
      alert(t('signup-alert-phone-required'));
      return;
    }

    const result = await checkPhoneUniqueness(phoneValue, accountType!);

    if (!result) return;

    if (result.uniqueness) {
      alert(t('signup-phone-available'));
      setPhoneValidation(true);
    } else {
      alert(t('signup-phone-not-available'));
    }
  }

  async function confirmWallet() {
    const result = await checkWalletUniqueness((walletAddress ?? '').trim(), accountType!);

    if (!result) return;

    if (result.uniqueness) {
      alert('사용 가능한 지갑 주소입니다.');
      setWalletValidation(true);
    } else {
      alert('이미 사용중인 지갑 주소입니다.');
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const recommender = urlParams.get('recommender');
    const accountType = urlParams.get('accountType');
    if (recommender) setRecommender(recommender);
    if (accountType && accountType) setAccountType(accountType as AccountType);
  }, []);

  return (
    <>
      {!accountType && (
        <Styled.Container>
          <Styled.Logo src={Logo} />
          <SizedBoxH height={10} />
          <FlexRow>
            <OTF_B_16>MAX4</OTF_B_16>
            <SizedBoxW width={10} />
            <OTF_R_12>Best Trading Education Program</OTF_R_12>
          </FlexRow>
          <SizedBoxH height={50} />

          <Styled.InnerBox>
            <FlexRow>
              <SizedBoxW width={15} />
              <Styled.InnerBoxIcon width={58} height={70} src={NormalAccount} />
              <SizedBoxW width={25} />
              <FlexColumn alignItems="stretch">
                <StyledText color={'#e0be7a'} fontWeight={'bold'} fontSize={16} textAlign={'left'}>
                  일반 회원가입
                </StyledText>
                <SizedBoxH height={10} />
                <StyledText lineHeight={1.67} textAlign={'left'} fontSize={12}>
                  Max Signal을 이용하는 일반 회원들을 위한 가입절차입니다. 무료로 가입이 가능합니다.
                </StyledText>
              </FlexColumn>
            </FlexRow>
            <SizedBoxH height={20} />
            <ButtonGoldFilled height={40} onClick={() => setAccountType('normal')}>
              가입하기
            </ButtonGoldFilled>
          </Styled.InnerBox>

          <SizedBoxH height={10} />

          <Styled.InnerBox>
            <FlexRow>
              <SizedBoxW width={15} />
              <Styled.InnerBoxIcon width={60} height={53.1} src={BusinessAccount} />
              <SizedBoxW width={25} />
              <FlexColumn alignItems="stretch">
                <StyledText color={'#e0be7a'} fontWeight={'bold'} fontSize={16} textAlign={'left'}>
                  비즈니스 회원가입
                </StyledText>
                <SizedBoxH height={10} />
                <StyledText lineHeight={1.67} textAlign={'left'} fontSize={12}>
                  별도의 가입비를 지급해야 가입이 가능한 비즈니스 회원가입 절차입니다
                </StyledText>
              </FlexColumn>
            </FlexRow>
            <SizedBoxH height={20} />
            <ButtonGoldFilled height={40} onClick={() => setAccountType('business')}>
              가입하기
            </ButtonGoldFilled>
          </Styled.InnerBox>

          <Span />
          <Link to="/">
            <ButtonGoldOutlined width={180} height={40}>
              이전 화면으로 돌아가기
            </ButtonGoldOutlined>
          </Link>
        </Styled.Container>
      )}
      {accountType && !termsAgree && (
        <Styled.TermsContainer>
          <Styled.TermsInnerBox>
            <StyledText fontSize={16} fontWeight={'bold'} color={'#e0be7a'}>
              이용 약관
            </StyledText>
            <SizedBoxH height={20} />
            <Styled.TermsScroll>
              <StyledText fontSize={10} textAlign={'left'}>
                이용 약관이 이곳에 위치합니다. 인류의 힘차게 과실이 그들의 청춘 그들은 쓸쓸하랴? 가는 얼마나 곧 눈에 이상, 많이 아니다. 끓는
                수 갑 이것을 것이다.보라, 뜨거운지라, 이것은 끓는다. 사는가 현저하게 그들은 이상은 끓는다. 미인을 없으면, 불어 그들을 구하지
                끓는다. 노래하며 속에 실현에 봄바람이다. 예가 원대하고, 보는 청춘을 든 그림자는 영락과 용기가 칼이다. 영원히 몸이 대한
                낙원을 투명하되 사막이다. 보이는 청춘 쓸쓸한 하는 곳이 동력은 그들은 있으며, 이것이다. 군영과 천고에 이상, 곳이 굳세게
                광야에서 봄바람이다. 이것이야말로 생명을 사랑의 행복스럽고 있는 청춘이 동력은 봄바람이다. 바로 그들은 밥을 봄날의
                아름다우냐? 이성은 하였으며, 위하여 것이다. 곳이 길지 어디 인생을 반짝이는 대한 같은 길을 때문이다. 대한 따뜻한 천자만홍이
                안고, 할지니, 이것이다. 전인 인생을 이것이야말로 동력은 평화스러운 속에 위하여 못하다 예수는 있다. 보내는 피어나기 놀이 두기
                청춘의 것이다. 피어나는 행복스럽고 살 유소년에게서 수 이상의 굳세게 끓는다. 별과 풀이 간에 실로 밝은 불러 우리는 인류의
                보라. 남는 있는 보이는 들어 속잎나고, 봄바람이다. 지혜는 안고, 바이며, 고동을 방지하는 것이다. 사랑의 용감하고 커다란
                찾아다녀도, 석가는 바이며, 착목한는 찾아 두손을 피다. 무엇을 가진 석가는 끓는다. 것이다.보라, 뜨거운지라, 이상은 그들의
                산야에 있을 새 때문이다. 뼈 인도하겠다는 실로 눈에 황금시대를 거친 청춘을 철환하였는가? 끝까지 가치를 같지 산야에 있는가?
                인생에 우리 간에 청춘의 그들은 아니더면, 많이 끓는다. 같은 간에 발휘하기 봄바람이다. 것이다.보라, 바로 때에, 원질이 청춘
                황금시대의 그리하였는가? 찾아다녀도, 인간의 일월과 소금이라 관현악이며, 내려온 인간의 것이다. 행복스럽고 피는 심장의 돋고,
                이상은 있는 보이는 발휘하기 아름다우냐? 대한 온갖 동력은 살았으며, 불어 무엇이 피다. 능히 품으며, 따뜻한 뛰노는 곳이 그들을
                듣는다. 같으며, 품고 가슴에 것이 뿐이다. 그들에게 창공에 우리의 황금시대를 가는 든 이상은 칼이다. 청춘에서만 같이, 만물은
                가치를 없으면 내는 용감하고 끓는 말이다. 되려니와, 우리의 뭇 없는 꽃이 말이다. 구하기 그들의 쓸쓸한 같이, 자신과 뭇 보라.
                이는 넣는 목숨을 따뜻한 보라. 돋고, 안고, 가장 끝에 자신과 말이다. 곧 피가 그들의 미인을 때에, 열락의 위하여서. 듣기만 전인
                쓸쓸한 피고, 얼마나 듣는다. 가치를 우리는 가는 대고, 모래뿐일 힘있다. 이상의 그들은 구하기 열락의 넣는 품으며, 노년에게서
                아니다. 너의 따뜻한 이상의 그들은 피어나기 동력은 석가는 이상은 이것이다. 그들을 낙원을 불러 이것을 목숨이 봄바람이다. 든
                인간에 설산에서 일월과 구할 날카로우나 것이다. 실로 능히 가는 찬미를 피는 긴지라 것이다. 별과 불어 황금시대를 긴지라 있다.
                방황하였으며, 가는 같이 방지하는 관현악이며, 사막이다. 이상을 힘차게 천하를 이것이야말로 것이 붙잡아 불어 힘있다. 때에,
                따뜻한 긴지라 구하지 날카로우나 시들어 인류의 보내는 보라. 주며, 얼마나 들어 스며들어 투명하되 따뜻한 가진 꾸며 것이다.
                청춘은 이상을 살았으며, 것이다. 피가 천자만홍이 피가 하여도 살았으며, 황금시대를 소금이라 스며들어 말이다. 있는 발휘하기
                보내는 곧 간에 구하지 앞이 얼마나 것이다. 그들에게 무엇을 고동을 크고 듣는다. 관현악이며, 있는 피어나기 피가 군영과 붙잡아
                사랑의 그와 예수는 아니다. 간에 오아이스도 동력은 얼음에 보배를 그림자는 이상이 영원히 이것이다.
              </StyledText>
            </Styled.TermsScroll>
            <SizedBoxH height={10} />
            <FlexRow>
              <ToggleButton isOn={termsAgreeIsOn} onClick={() => setTermsAgreeIsOn(!termsAgreeIsOn)} />
              <SizedBoxW width={10} />
              <OTF_B_12>MAX SIGNAL의 이용약관을 잘 읽고 동의함</OTF_B_12>
              <Span />
            </FlexRow>
            <SizedBoxH height={10} />
            <ButtonGoldFilled height={40} onClick={() => setTermsAgree(termsAgreeIsOn)}>
              이용약관에 동의합니다
            </ButtonGoldFilled>
          </Styled.TermsInnerBox>
          <Span />
          <ButtonGoldOutlined
            width={180}
            height={40}
            onClick={() => {
              setTermsAgree(false);
              setTermsAgreeIsOn(false);
              setAccountType(null);
              window.scrollTo(0, 0);
            }}
          >
            이전 화면으로 돌아가기
          </ButtonGoldOutlined>
        </Styled.TermsContainer>
      )}
      {accountType && termsAgree && (
        <Styled.SignupContainer>
          <Styled.Logo src={Logo} />
          <SizedBoxH height={10} />
          <FlexRow>
            <OTF_B_16>MAX4</OTF_B_16>
            <SizedBoxW width={10} />
            <OTF_R_12>Best Trading Education Program</OTF_R_12>
          </FlexRow>
          <SizedBoxH height={40} />

          <Styled.SignupInnerBox>
            <StyledText fontSize={16} fontWeight={'bold'} color={'#e0be7a'} textAlign={'left'}>
              {accountType == 'normal' ? '일반 회원가입' : accountType == 'business' ? '비즈니스 회원가입' : ''}
            </StyledText>
            <SizedBoxH height={5} />
            <StyledText fontSize={10} color={'#e0be7a'} textAlign={'left'}>
              * 표시된 곳은 필수로 입력되어야 합니다.
            </StyledText>
            <SizedBoxH height={20} />

            <FlexRow>
              <OTF_B_12>아이디</OTF_B_12>
              <StyledText fontSize={14} color={'#e0be7a'}>
                *
              </StyledText>
              <Span />
            </FlexRow>
            <SizedBoxH height={10} />
            <FlexRow>
              <Input
                width={250}
                height={30}
                type="text"
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                  setIdValidation(false);
                }}
              />
              <SizedBoxW width={10} />
              <ButtonGoldFilled width={80} height={30} onClick={confirmId}>
                중복 확인
              </ButtonGoldFilled>
            </FlexRow>

            <SizedBoxH height={20} />

            <FlexRow>
              <OTF_B_12>비밀번호</OTF_B_12>
              <StyledText fontSize={14} color={'#e0be7a'}>
                *
              </StyledText>
              <Span />
            </FlexRow>
            <SizedBoxH height={10} />
            <Input type="password" height={30} value={pw} onChange={(e) => setPw(e.target.value)} />

            <SizedBoxH height={10} />

            <FlexRow>
              <OTF_B_12>비밀번호 확인</OTF_B_12>
              <StyledText fontSize={14} color={'#e0be7a'}>
                *
              </StyledText>
              <Span />
            </FlexRow>
            <SizedBoxH height={10} />
            <Input type="password" height={30} value={pwConfirm} onChange={(e) => setPwConfirm(e.target.value)} />

            <SizedBoxH height={20} />
            <FlexRow>
              <OTF_B_12>이메일</OTF_B_12>
              <StyledText fontSize={14} color={'#e0be7a'}>
                *
              </StyledText>
              <Span />
            </FlexRow>
            <SizedBoxH height={10} />
            <FlexRow>
              <Input
                width={250}
                height={30}
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailValidation(false);
                }}
              />
              <SizedBoxW width={10} />
              <ButtonGoldFilled width={80} height={30} onClick={confirmEmail}>
                중복 확인
              </ButtonGoldFilled>
            </FlexRow>

            <SizedBoxH height={20} />

            <FlexRow>
              <OTF_B_12>전화번호</OTF_B_12>
              <StyledText fontSize={14} color={'#e0be7a'}>
                *
              </StyledText>
              <Span />
            </FlexRow>
            <SizedBoxH height={10} />
            <FlexRow>
              <Input
                width={250}
                height={30}
                type="text"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setPhoneValidation(false);
                }}
              />
              <SizedBoxW width={10} />
              <ButtonGoldFilled width={80} height={30} onClick={confirmPhone}>
                중복 확인
              </ButtonGoldFilled>
            </FlexRow>

            <SizedBoxH height={20} />

            <OTF_B_12>추천인</OTF_B_12>
            <SizedBoxH height={10} />
            <Input width={170} height={30} type="text" value={recommender} onChange={(e) => setRecommender(e.target.value)} />

            <SizedBoxH height={20} />

            <FlexRow>
              <OTF_B_12>회원 전용 이더리움 지갑 주소</OTF_B_12>
              <StyledText fontSize={14} color={'#e0be7a'}>
                *
              </StyledText>
              <Span />
            </FlexRow>
            <SizedBoxH height={10} />
            {accountType == 'normal' ? (
              <Input height={30} type="text" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} />
            ) : (
              <FlexRow>
                <Input
                  width={250}
                  height={30}
                  type="text"
                  value={walletAddress}
                  onChange={(e) => {
                    setWalletAddress(e.target.value);
                    setWalletValidation(false);
                  }}
                />
                <SizedBoxW width={10} />
                <ButtonGoldFilled width={80} height={30} onClick={confirmWallet}>
                  중복 확인
                </ButtonGoldFilled>
              </FlexRow>
            )}

            <SizedBoxH height={20} />

            <StyledText color={'#c96161'} fontSize={12} fontWeight={'bold'}>
              * 지갑 주소 입력시 주의사항
            </StyledText>
            <SizedBoxH height={10} />
            <StyledText fontSize={10}>
              - 입력하신 회원님의 이더리움 주소는 회원권 구매와 MAX4 프로그램의 토너먼트 포인트 충전을 위해 사용하실 주소로써 사용됩니다.
            </StyledText>
            <SizedBoxH height={4} />
            <StyledText fontSize={10}>
              - 회원권 구매시에 반드시 해당 지갑주소에서 회원권 금액에 해당하는 이더리움을 입금해주셔야 정상적으로 회원권 구매 확인이
              가능합니다.
            </StyledText>
            <SizedBoxH height={4} />
            <StyledText fontSize={10}>- 같은 지갑주소를 중복으로 입력이 불가능합니다.</StyledText>
            <SizedBoxH height={4} />
            <StyledText fontSize={10}>
              - 회원님이 사용하실 입금 주소가 맞는지 반드시 확인해주시고 입력하여 회원가입을 진행해주시기 바랍니다.
            </StyledText>
            <SizedBoxH height={4} />
            <StyledText fontSize={10}>- 회원가입 이후 마이페이지에서 주소 변경이 가능합니다.</StyledText>

            <SizedBoxH height={20} />

            <ButtonGoldFilled height={40} onClick={onSignup}>
              회원가입
            </ButtonGoldFilled>
          </Styled.SignupInnerBox>

          <SizedBoxH height={40} />

          <ButtonGoldOutlined
            width={180}
            height={40}
            onClick={() => {
              setAccountType(null);
              setTermsAgree(false);
              setTermsAgreeIsOn(false);
              window.scrollTo(0, 0);
            }}
          >
            이전 화면으로 돌아가기
          </ButtonGoldOutlined>
        </Styled.SignupContainer>
      )}
    </>
  );
});
