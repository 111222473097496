import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';
import * as Styled from './SendInquiry.style';

import { OTF_B_12, OTF_B_16, OTF_R_10, OTF_R_12, StyledText } from '../Common/Typography';
import { ButtonRedOutlined, FlexColumn, FlexRow, Input, SizedBoxH, SizedBoxW, SizedImage, Span, TabButton } from '../Common/CommonStyle';

// images
import HeaderLogo from 'img/Main/header_logo.png';
import Colors from '../Common/Colors';
import ContinuousGameIcon from 'img/Game/game_continuous_gold.png';
import FixedGameIcon from 'img/Game/game_fixed_gold.png';
import LongIcon from 'img/Game/long_icon.png';
import ShortIcon from 'img/Game/short_icon.png';
import PointAIcon from 'img/Game/point_a.png';
import PointBIcon from 'img/Game/point_b.png';
import PointCIcon from 'img/Game/point_c.png';
import PointDIcon from 'img/Game/point_d.png';
import MenuIcon from 'img/Main/menu.png';

import { vw } from '../../Utils/Size';
import RightSideMenu from '../RightSideMenu/RightSideMenu';
import { getNotice, listNotices } from '../../api';
import Header from '../Header/Header';
import Pages from '../Common/Pages';
import dayjs from 'dayjs';

export default withRouter(function SendInquiry(props: RouteComponentProps): ReactElement {
  return (
    <Styled.Container>
      <Header />
      <Styled.InnerContainer>
        <SizedBoxH height={30} />
        <FlexRow alignItems="center">
          <SizedBoxW width={10} />
          <StyledText fontSize={24} fontWeight="bold" color={Colors.gold}>
            1대1 문의 작성
          </StyledText>
          <Span />
          <ButtonRedOutlined width={54} height={30} fontSize={12}>
            취소
          </ButtonRedOutlined>
          <SizedBoxW width={10} />
        </FlexRow>
        <SizedBoxH height={30} />

        <Styled.InnerBox>
          <OTF_B_12>제목</OTF_B_12>
          <SizedBoxH height={10} />
          <Input height={30} />
          <SizedBoxH height={20} />
          <OTF_B_12>내용</OTF_B_12>
          <SizedBoxH height={10} />
          
        </Styled.InnerBox>
      </Styled.InnerContainer>
    </Styled.Container>
  );
});
