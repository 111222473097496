const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const Ticks: { tick: number; initial: string; label: string }[] = [
  { tick: 5 * SECOND, initial: '5s', label: '5초' },
  { tick: 10 * SECOND, initial: '10s', label: '10초' },
  { tick: 15 * SECOND, initial: '15s', label: '15초' },
  { tick: 30 * SECOND, initial: '30s', label: '30초' },
  { tick: 1 * MINUTE, initial: '1m', label: '1분' },
  { tick: 2 * MINUTE, initial: '2m', label: '2분' },
  { tick: 5 * MINUTE, initial: '5m', label: '5분' },
  { tick: 10 * MINUTE, initial: '10m', label: '10분' },
  { tick: 15 * MINUTE, initial: '15m', label: '15분' },
  { tick: 30 * MINUTE, initial: '30m', label: '30분' },
  { tick: 1 * HOUR, initial: '1h', label: '1시간' },
  { tick: 2 * HOUR, initial: '2h', label: '2시간' },
  { tick: 4 * HOUR, initial: '4h', label: '4시간' },
  { tick: 8 * HOUR, initial: '8h', label: '8시간' },
  { tick: 12 * HOUR, initial: '12h', label: '12시간' },
  { tick: 1 * DAY, initial: '1d', label: '1일' },
  { tick: 7 * DAY, initial: '7d', label: '1주' },
  { tick: 30 * DAY, initial: '30d', label: '1달' },
];

export const Periods: { period: number; initial: string; label: string }[] = [
  { period: 2 * MINUTE, initial: '2m', label: '2분' },
  { period: 5 * MINUTE, initial: '5m', label: '5분' },
  { period: 15 * MINUTE, initial: '15m', label: '15분' },
  { period: 30 * MINUTE, initial: '30m', label: '30분' },
  { period: 3 * HOUR, initial: '3h', label: '3시간' },
  { period: 1 * DAY, initial: '1d', label: '1일' },
  { period: 30 * DAY, initial: '30d', label: '30일' },
];
