import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';

import PrevIcon from 'img/Common/arrow_prev.png';
import NextIcon from 'img/Common/arrow_next.png';
import styled from 'styled-components';
import { vw } from '../../Utils/Size';
import Colors from './Colors';
import { FlexColumn, FlexRow, SizedImage } from './CommonStyle';
import { OTF_R_12, StyledText } from './Typography';

const Button = styled.button`
  border: none;
  width: ${vw(30)};
  height: ${vw(30)};
  background-color: ${Colors.dark};
  border-radius: ${vw(15)};
  padding: 0;
`;

const PageButton = styled.button`
  font-family: NotoSansKR;
  font-size: ${vw(12)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: 'center';
  color: #fff;
  border: none;
  padding: 0;
  background: none;
  margin: ${vw(5)};

  :disabled {
    color: ${Colors.gold};
  }
`;

export default function Pages(props: { pageCount: number; curPage: number; onPage: (page: number) => void; maxPageCount?: number }) {
  // const maxItemCount = props.maxItemCount ? props.maxItemCount : 10;
  const maxPageCount = props.maxPageCount ? props.maxPageCount : 10;

  return (
    <FlexColumn alignItems="center">
      <FlexRow alignItems="center" justifyContent="center">
        <Button
          className="admin-page"
          onClick={() => {
            props.onPage(Math.max(0, props.curPage - 1));
          }}
        >
          <SizedImage width={7} height={12} src={PrevIcon} />
        </Button>
        {props.pageCount > maxPageCount ? (
          <>
            <PageButton onClick={() => props.onPage(0)} disabled={props.curPage == 0}>
              1
            </PageButton>
            {props.curPage > maxPageCount / 2 && <OTF_R_12>…</OTF_R_12>}
            {Array.from({ length: maxPageCount - 2 }, (v, i) => {
              const pageNum =
                Math.min(Math.max(Math.floor(maxPageCount / 2), props.curPage), props.pageCount - Math.ceil(maxPageCount / 2)) +
                i -
                Math.floor((maxPageCount - 2) / 2);
              return (
                <PageButton onClick={() => props.onPage(pageNum)} disabled={props.curPage == pageNum}>
                  {pageNum + 1}
                </PageButton>
              );
            })}
            {props.curPage < props.pageCount - Math.ceil(maxPageCount / 2) && <OTF_R_12>…</OTF_R_12>}
            <PageButton onClick={() => props.onPage(props.pageCount - 1)} disabled={props.curPage == props.pageCount - 1}>
              {props.pageCount}
            </PageButton>
          </>
        ) : (
          <>
            {Array.from({ length: props.pageCount }, (v, i) => {
              return (
                <PageButton onClick={() => props.onPage(i)} disabled={props.curPage == i}>
                  {i + 1}
                </PageButton>
              );
            })}
          </>
        )}

        <Button
          onClick={() => {
            props.onPage(Math.min(props.curPage + 1, props.pageCount - 1));
          }}
        >
          <SizedImage width={7} height={12} src={NextIcon} />
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
