import styled from 'styled-components';
import { vw } from '../../Utils/Size';

export const Container = styled.div`
  width: 100%;
  height: ${vw(760)};
  padding: ${vw(40)} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  background-color: #1c1b1a;
`;

export const Logo = styled.img`
  width: ${vw(60)};
  height: ${vw(60)};
  object-fit: contain;
`;

export const InnerBox = styled.div`
  width: 100%;
  padding: ${vw(20)} ${vw(10)};
  box-shadow: 0 ${vw(4)} ${vw(4)} 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
`;
