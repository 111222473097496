import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ToastContainer, toast } from 'react-toastify';

import TokenContext from '../../token-context';
import * as Styled from './Signin.style';

import { OTF_B_12, OTF_B_16, OTF_R_12, StyledText } from '../Common/Typography';

import { ButtonGoldFilled, ButtonGoldOutlined, FlexColumn, FlexRow, Input, SizedBoxH, SizedBoxW, Span } from '../Common/CommonStyle';

// images
import Logo from 'img/Common/small_logo.png';
import NormalAccount from 'img/Signup/signup_normal.png';
import BusinessAccount from 'img/Signup/signup_business.png';
import { checkEmailUniqueness, signin } from '../../api';

export default withRouter(function Signin(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  const [accountType, setAccountType] = useState<AccountType | null>(null);

  const [id, setId] = useState<string>('');
  const [pw, setPw] = useState<string>('');

  const [emailModalOn, setEmailModalOn] = useState<boolean>(false);
  const [editEmail, setEditEmail] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState('');

  async function onSignin() {
    const idValue = (id ?? '').trim();
    const pwValue = pw ?? '';

    if (!idValue || !pwValue) {
      alert(t('signin-alert-email-pw-required'));
      return;
    }

    const response = await signin(idValue, pwValue, accountType!);

    if (!response) return;

    if (!response.authenticated) {
      alert(t('signin-alert-failed'));
      return;
    }

    if (!response.authenticated.wasEmailVerified) {
      setEmailModalOn(true);
      return;
    }

    tokenContext.setToken(response.authenticated.wasEmailVerified.token);

    props.history.push('/');
  }

  async function changeEmail() {
    // 여기서 이메일 중복확인 할 것 없이
    // 변경하는 api 함수 안에서 이미 있는 이메일이면 그냥 에러 던지게
    // 해당 api 에서는 이메일 변경 이후 즉시 이메일 발송 할 수 있도록 하면 됨

    const result = await checkEmailUniqueness((newEmail ?? '').trim(), accountType!);

    if (!result) return;

    if (!result.uniqueness) {
      alert(t('signin-email-conflict'));
      return;
    }

    // 성공적으로 변경 완료
    setEditEmail(false);
    toast.dark(t('signin-edit-email-success'), {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      closeButton: false,
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!accountType && (
        <Styled.Container>
          <Styled.Logo src={Logo} />
          <SizedBoxH height={10} />
          <FlexRow>
            <OTF_B_16>MAX4</OTF_B_16>
            <SizedBoxW width={10} />
            <OTF_R_12>Best Trading Education Program</OTF_R_12>
          </FlexRow>
          <SizedBoxH height={50} />

          <Styled.InnerBox>
            <FlexRow>
              <SizedBoxW width={15} />
              <Styled.InnerBoxIcon width={58} height={70} src={NormalAccount} />
              <SizedBoxW width={25} />
              <FlexColumn alignItems="stretch">
                <StyledText color={'#e0be7a'} fontWeight={'bold'} fontSize={16} textAlign={'left'}>
                  일반 회원
                </StyledText>
                <SizedBoxH height={10} />
                <StyledText lineHeight={1.67} textAlign={'left'} fontSize={12}>
                  Max Signal을 이용하는 일반 회원들을 위한 가입절차입니다. 무료로 가입이 가능합니다.
                </StyledText>
              </FlexColumn>
            </FlexRow>
            <SizedBoxH height={20} />
            <ButtonGoldFilled height={40} onClick={() => setAccountType('normal')}>
              로그인
            </ButtonGoldFilled>
          </Styled.InnerBox>

          <SizedBoxH height={10} />

          <Styled.InnerBox>
            <FlexRow>
              <SizedBoxW width={15} />
              <Styled.InnerBoxIcon width={60} height={53.1} src={BusinessAccount} />
              <SizedBoxW width={25} />
              <FlexColumn alignItems="stretch">
                <StyledText color={'#e0be7a'} fontWeight={'bold'} fontSize={16} textAlign={'left'}>
                  비즈니스 회원
                </StyledText>
                <SizedBoxH height={10} />
                <StyledText lineHeight={1.67} textAlign={'left'} fontSize={12}>
                  별도의 가입비를 지급해야 가입이 가능한 비즈니스 회원가입 절차입니다
                </StyledText>
              </FlexColumn>
            </FlexRow>
            <SizedBoxH height={20} />
            <ButtonGoldFilled height={40} onClick={() => setAccountType('business')}>
              로그인
            </ButtonGoldFilled>
          </Styled.InnerBox>

          <Span />
          <Link to="/">
            <ButtonGoldOutlined width={180} height={40}>
              이전 화면으로 돌아가기
            </ButtonGoldOutlined>
          </Link>
        </Styled.Container>
      )}
      {accountType && (
        <Styled.Container>
          <Styled.Logo src={Logo} />
          <SizedBoxH height={10} />
          <FlexRow>
            <OTF_B_16>MAX4</OTF_B_16>
            <SizedBoxW width={10} />
            <OTF_R_12>Best Trading Education Program</OTF_R_12>
          </FlexRow>
          <SizedBoxH height={40} />

          <Styled.SigninContainer>
            <OTF_B_12>아이디</OTF_B_12>
            <SizedBoxH height={10} />
            <Input type="text" height={30} value={id} onChange={(e) => setId(e.target.value)} />

            <SizedBoxH height={20} />

            <OTF_B_12>비밀번호</OTF_B_12>
            <SizedBoxH height={10} />
            <Input type="password" height={30} value={pw} onChange={(e) => setPw(e.target.value)} />

            <SizedBoxH height={20} />

            <FlexRow>
              <OTF_R_12>회원이 아니신가요?</OTF_R_12>
              <Span />
              <Link to="/signup">
                <Styled.TextButton>회원가입</Styled.TextButton>
              </Link>
            </FlexRow>

            <SizedBoxH height={10} />

            <FlexRow>
              <OTF_R_12>아이디 혹은 비밀번호를 잊으셨나요?</OTF_R_12>
              <Span />
              <Link to="/find-account">
                <Styled.TextButton>아이디/비밀번호 찾기</Styled.TextButton>
              </Link>
            </FlexRow>

            <SizedBoxH height={20} />

            <ButtonGoldFilled height={40} onClick={onSignin}>
              로그인
            </ButtonGoldFilled>
          </Styled.SigninContainer>

          <Span />
          <Link to="/">
            <ButtonGoldOutlined width={180} height={40}>
              이전 화면으로 돌아가기
            </ButtonGoldOutlined>
          </Link>
        </Styled.Container>
      )}
    </>
  );
});
