import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';

import TokenContext from '../../token-context';
import * as Styled from './RightSideMenu.style';

import { OTF_B_10, OTF_B_12, OTF_B_16, OTF_B_24, OTF_R_12, StyledText } from '../Common/Typography';

import Background from 'img/Main/background.png';
import HeaderLogo from 'img/Main/header_logo.png';

import { ButtonGoldFilled, SizedBoxH, SizedBoxW, SizedImage, Span } from '../Common/CommonStyle';
import { Motion, spring } from 'react-motion';
import { getAccount, getBusinessAccount, signout } from '../../api';
import { MembershipSmallIcons } from '../Common/Imgaes';

import GlobalIcon from 'img/SideMenu/global_icon.png';
import Colors from '../Common/Colors';

export default function RideSideMenu(props: { onClose: () => void }): ReactElement {
  const tokenContext = useContext(TokenContext);

  const [accountType, setAccountType] = useState<AccountType | null>();
  const [user, setUser] = useState<UserDto | BusinessUserDto | null>();

  useEffect(() => {
    (async () => {
      let accountType = localStorage.getItem('accountType');
      if (accountType) {
        setAccountType(accountType as AccountType);
      } else {
        return;
      }

      if (accountType === 'normal') {
        const user = await getAccount();
        if (user) setUser(user);
      } else if (accountType == 'business') {
        const user = await getBusinessAccount();
        if (user) setUser(user);
      }
    })();
  }, []);

  return (
    <Styled.Container>
      <Motion defaultStyle={{ x: 0 }} style={{ x: spring(270) }}>
        {(value) => (
          <OutsideClickHandler onOutsideClick={props.onClose}>
            <Styled.SideMenu width={270} right={value.x}>
              <Styled.SideMenuTop>
                <SizedImage width={40} height={40} src={HeaderLogo} />
                <SizedBoxW width={10} />
                {user ? (
                  <OTF_B_16>{user.username}</OTF_B_16>
                ) : (
                  <StyledText opacity={0.5} fontWeight="bold" fontSize={16}>
                    오프라인
                  </StyledText>
                )}
                {user && isBusinessUser(user) && user.membership && (
                  <>
                    <SizedBoxW width={10} />
                    <SizedImage width={36} height={18} src={MembershipSmallIcons[user.membership]} />
                  </>
                )}
              </Styled.SideMenuTop>
              <Styled.SideMenuLink>
                <Link to="introduce">
                  <StyledText fontSize={14} fontWeight="bold">
                    챌린지 소개
                  </StyledText>
                </Link>
              </Styled.SideMenuLink>
              <Styled.SideMenuLink>
                <Link to="pro-challenge">
                  <StyledText fontSize={14} fontWeight="bold">
                    프로 챌린지
                  </StyledText>
                </Link>
              </Styled.SideMenuLink>
              <Styled.SideMenuLink>
                <Link to="customercare">
                  <StyledText fontSize={14} fontWeight="bold">
                    고객센터
                  </StyledText>
                </Link>
              </Styled.SideMenuLink>
              <Styled.SideMenuLink>
                <Link to="notice">
                  <StyledText fontSize={14} fontWeight="bold">
                    공지사항
                  </StyledText>
                </Link>
              </Styled.SideMenuLink>
              <Styled.SideMenuLink>
                <Link to="mypage">
                  <StyledText fontSize={14} fontWeight="bold">
                    마이페이지
                  </StyledText>
                </Link>
              </Styled.SideMenuLink>
              <Span />
              <Styled.SideMenuBottom>
                {user ? (
                  <Styled.LogoutButton
                    onClick={async () => {
                      await signout();
                      window.location.replace('/');
                      tokenContext.setToken(null);
                    }}
                  >
                    <StyledText height={30} fontSize={12} fontWeight="bold">
                      로그아웃
                    </StyledText>
                  </Styled.LogoutButton>
                ) : (
                  <>
                    <Styled.LogoutButton>
                      <Link to="signin">
                        <StyledText height={30} fontSize={12} fontWeight="bold" color={Colors.gold}>
                          로그인
                        </StyledText>
                      </Link>
                    </Styled.LogoutButton>
                    <Styled.LogoutButton>
                      <Link to="signup">
                        <StyledText height={30} fontSize={12} fontWeight="bold">
                          회원가입
                        </StyledText>
                      </Link>
                    </Styled.LogoutButton>
                  </>
                )}

                <Span />
                <SizedImage src={GlobalIcon} width={20} height={20} />
                <SizedBoxW width={10} />
                <OTF_B_12>KR</OTF_B_12>
              </Styled.SideMenuBottom>
            </Styled.SideMenu>
          </OutsideClickHandler>
        )}
      </Motion>
    </Styled.Container>
  );
}

const isBusinessUser = (user: UserDto | BusinessUserDto): user is BusinessUserDto => {
  return (user as BusinessUserDto).wasMembershipApproved !== undefined;
};
